import { createSelector } from 'reselect';

import type { RootState } from '../store';

import type { ImageFiles } from './images.type';

const selectImagesState = (state: RootState) => state.images;

const selectImageFiles = createSelector([selectImagesState], (images) => images.imageFiles);

const getImageIdParam = (_: RootState, imageId: string) => imageId;

const selectImageFile = createSelector(
  selectImageFiles,
  getImageIdParam,
  (imageFiles: ImageFiles, imageId: string) => imageFiles?.[imageId],
);

export {
  selectImageFile,
};
