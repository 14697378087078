import { useMemo } from 'react';
import styled from 'styled-components';

import { formatDateTime } from 'utils';
import { Flex } from 'components/layouts';
import { AnchorThumbnail } from 'components/shared/image-thumbnail/image-thumbnail';
import { LabellingMode, QueryType } from 'redux/annotations/annotations.type';
import { AssignPhotoSeriesButton } from 'components/pages/annotations/components/assign-photo-series-button';
import { ElapsedIndicator } from 'components/pages/annotations/components/elapsed-indicator';
import { AnnotationInAnalysis } from '../../../components/annotation-in-analysis';

const Wrapper = styled.div``;

const WrapperRow1 = styled(Flex)`
`;

const WrapperRow2 = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
  font-weight: ${(props) => props.theme.font.fontWeights[4]};
  padding: 0.25rem 0;
  align-items: center;
`;

const WrapperRow2Col1 = styled.div`
  display: flex;
  align-items: center;

  & > span:nth-child(1) {
    margin-right: 0.5rem;
  }

  & > span:nth-child(2) {
    margin-right: 0.5rem;
  }
`;

const WrapperRow2Col2 = styled.div`
  display: flex;
  align-items: center;

  & .label {
    margin-right: 0.5rem;
  }
`;

const WrapperRow3 = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
`;

const ImageThumbnailWrapper = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.75rem;
`;

type AnnotationProps = {
  queryType: QueryType;
  photoSeriesId: string;
  vin: string;
  completedAt: string;
  analysisConfirmedAt: string | null;
  analysisAssignedAt: string | null;
  analysisAssignedToUser: string | null;
  registrationNo: string;
  images: {
    id: string;
    imageType: number;
    imageSubType: number | null;
    fileName: string;
    filePath: string;
    analysedAt?: string;
  }[];
};

function Annotation(props: AnnotationProps) {
  const {
    queryType, photoSeriesId, vin, completedAt, images,
    analysisConfirmedAt, registrationNo, analysisAssignedAt, analysisAssignedToUser
  } = props;

  const renderImages = useMemo(() => {
    const node = (
      <>
        {images.map((image) => {
          const { id, filePath, fileName } = image;

          return (
            <ImageThumbnailWrapper key={id}>
              {
                image.analysedAt
                  ? (
                    <AnchorThumbnail
                      filePath={filePath}
                      fileName={fileName}
                      width="small"
                      href={`/labelling/photoSeries/${photoSeriesId}/image/${id}/mode/${LabellingMode.DamageDetection}`}
                      target="_blank"
                    />
                  )
                  : <AnnotationInAnalysis />
              }
            </ImageThumbnailWrapper>
          );
        })}
      </>
    );

    return node;
  }, [images, photoSeriesId]);

  return (
    <Wrapper>
      {
        queryType === QueryType.ReviewNeeded || queryType === QueryType.InReview
          ? (
            <WrapperRow1>
              <ElapsedIndicator date={completedAt} />
            </WrapperRow1>
          )
          : <></>
      }
      <WrapperRow2>
        <WrapperRow2Col1>
          <span className="vin">{vin}</span>
          <span className="reg-nr">{registrationNo}</span>
        </WrapperRow2Col1>
        <WrapperRow2Col2>
          <div className="label">
            {
              queryType === QueryType.InReview ? (
                analysisAssignedToUser
              ) : (
                <TimeLabel
                  queryType={queryType}
                  completedAt={completedAt}
                  analysisAssignedAt={analysisAssignedAt}
                  analysisConfirmedAt={analysisConfirmedAt}
                />
              )
            }
          </div>
          {
            queryType === QueryType.ReviewNeeded && (
              <AssignPhotoSeriesButton
                photoSeriesId={photoSeriesId}
                imageId={images[0].id}
              />
            )
          }
        </WrapperRow2Col2>
      </WrapperRow2>
      <WrapperRow3>{renderImages}</WrapperRow3>
    </Wrapper>
  );
}

type TimeLabelProps = {
  queryType: QueryType,
  completedAt: string;
  analysisConfirmedAt: string | null;
  analysisAssignedAt: string | null
}

const TimeLabel = (props: TimeLabelProps) => {
  const { queryType, completedAt, analysisConfirmedAt, analysisAssignedAt } = props;

  const label = (): string => {
    switch (queryType) {
      case QueryType.ReviewNeeded:
        return 'Photos captured at';
      case QueryType.Reviewed:
        return 'Reviewed at';
      case QueryType.InReview:
        return 'Assigned at';
      default:
        return '-';
    }
  };

  const time = (): string => {
    switch (queryType) {
      case QueryType.ReviewNeeded:
        return formatDateTime(completedAt);
      case QueryType.Reviewed:
        return analysisConfirmedAt !== null ? formatDateTime(analysisConfirmedAt) : '-';
      case QueryType.InReview:
        return analysisAssignedAt !== null ? formatDateTime(analysisAssignedAt) : '-';
      default:
        return '-';
    }
  };

  return (
    <>
      <span>{label()}: </span>
      <time>{time()}</time>
    </>
  );
};

export { Annotation };
