import { LabelStudioRegion } from 'redux/annotations/annotations.type';

const isLabelStudioDirty = (labelStudioRef: any): boolean => labelStudioRef.current.dirty;

const getLabelStudioAreas = (labelStudioRef: any) => {
  if (!labelStudioRef) return [];
  return labelStudioRef.current.store.annotationStore.selected.areas;
};

const getSelectedAreas = (labelStudioRef: any): LabelStudioRegion[] => {
  if (!labelStudioRef.current) return [];
  const annotationStore = labelStudioRef.current.store.annotationStore;
  const areas: LabelStudioRegion[] = Object.values(annotationStore.selected.areas.toPOJO());
  return areas.filter((area: LabelStudioRegion) => area.inSelection);
};

const isAreaDamageRegion = (region: LabelStudioRegion | null) : boolean =>
  region !== null && region.tag && region.tag.name === 'damages';

export {
  isLabelStudioDirty,
  getLabelStudioAreas,
  getSelectedAreas,
  isAreaDamageRegion
};
