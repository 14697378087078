import { _cookies } from 'utils';
import { Session } from 'redux/session/session.type';

const checkIsPageAccessAuthorized = (pagePermissions: number[], userPermissions: number[]) => {
  let isPermitted = false;

  if (!pagePermissions.length || !userPermissions.length) return isPermitted;

  for (let i = 0, size = pagePermissions.length; i < size; i++) {
    if (userPermissions.indexOf(pagePermissions[i]) >= 0) {
      isPermitted = true;
      break;
    }
  }

  return isPermitted;
};

const shouldRenewToken = (expiresAt: string) => {
  const nowSec = new Date().getTime() / 1000;
  const expiresAtSec = new Date(expiresAt).getTime() / 1000;
  const diff = expiresAtSec - nowSec;

  // refresh token when it's going to expire in less than 1 minute
  return diff < 60;
};

const storeUserData = (session: Session) => {
  // store user details without jwt tokens in local storage
  delete session.token;
  delete session.refreshToken;

  _cookies.setCookie({ name: 'session', value: session, expDays: 3 });

  return session;
};

const retrieveUserData = () => _cookies.getCookie<Session>('session');

const deleteUserData = () => _cookies.deleteCookie('session');

export { checkIsPageAccessAuthorized, shouldRenewToken, storeUserData, retrieveUserData, deleteUserData };
