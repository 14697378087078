import { LabellingMode } from '../../../../../../redux/annotations/annotations.type';
import { useParams } from 'react-router-dom';

const useParseLabellingModeFromParams = () => {
  const { labellingModeId } = useParams();

  if (labellingModeId === LabellingMode.LabelReview) {
    return LabellingMode.LabelReview;
  }

  if (labellingModeId === LabellingMode.ModelTraining) {
    return LabellingMode.ModelTraining;
  }

  return LabellingMode.DamageDetection;
};

export { useParseLabellingModeFromParams };
