import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { __prod__ } from 'utils/const';
import sessionReducer from './session/session.slice';
import annotationsReducer from './annotations/annotations.slice';
import imagesReducer from './images/images.slice';
import reviewsReducer from './reviews/reviews.slice';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    annotations: annotationsReducer,
    images: imagesReducer,
    reviews: reviewsReducer
  },
  devTools: !__prod__,
});

type RootState = ReturnType<typeof store.getState>;
type RootDispatch = typeof store.dispatch;
type RootThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
type GenericAjaxType = {
  onSuccess?: Function;
  onError?: Function;
  onFinish?: Function;
};

export type { RootState, RootDispatch, RootThunk, GenericAjaxType };

export { store };
