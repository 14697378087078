import React, { useState } from 'react';
import { useTitle } from 'hooks';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { transition } from 'components/shared/skeleton-ui/skeleton-ui.component';
import { useLoadImage, useLabelReview, useSuggestions, useNavigation, useSettings, getSuggestionColor } from './hooks';
import { LabellingReviewSuggestion } from './hooks/use-suggestions';
import { Alert } from 'react-bootstrap';
import { BlueButton } from 'components/shared';

import { PageWrapper } from 'components/containers';
import { ChangeType } from 'redux/reviews/reviews.type';

import { IconCheck } from 'assets';

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`;

const Navigation = styled(BlueButton)`
  min-width: 200px;
  max-width: 200px;
`;

const Finish = styled(BlueButton)`
  background: #23a942;
  min-width: 200px;
  max-width: 200px;
`;

const Skeleton = styled.div`
  min-height: 80vh;
  animation: ${transition} 1.5s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuggestionsSvg = styled.svg<{ height: number, width: number}>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const LegendItem = styled.div<{ borderColor: string, backgroundColor: string}>`
  padding: 0.4rem;
  margin-right: 0.2rem;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background-color: ${(props) => props.backgroundColor};
`;

type SuggestionItemProps = {
  suggestion: LabellingReviewSuggestion;
  showActions: boolean;
  onAccept: (suggestionId: string, accepted: boolean) => void;
}

const SuggestionCard = (props: SuggestionItemProps) => {
  const { suggestion, onAccept, showActions } = props;
  const color = getSuggestionColor(suggestion.active, suggestion.changeType);

  return (
    <div style={{
      border: `1px solid ${color.stroke}`,
      padding: '0.4rem',
      fontSize: '0.938rem',
      marginBottom: '0.2rem',
      opacity: `${suggestion.active ? '1' : '.5'}`,
      backgroundColor: color.fill
    }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <span>{ suggestion.name }</span> &nbsp;
        </div>
        <div>
          {
            suggestion.suggestionAccepted !== null && (
              <>
                {
                  suggestion.suggestionAccepted === true
                    ? <span style={{ color: 'green' }}>Accepted</span>
                    : <span style={{ color: 'red' }}>Declined</span>
                }
              </>
            )
          }
          {
            suggestion.suggestionAccepted === null && showActions && suggestion.active && (
              <div>
                <button
                  style={{ marginRight: '0.2rem' }}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onAccept(suggestion.id, true)}
                >
                  <IconCheck fill="white" />
                </button>
                <button type="button" className="btn btn-danger" onClick={() => onAccept(suggestion.id, false)}>X</button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const LabellingReviewDetailView = () => {
  useTitle('Labelling review detail view');

  const { photoSeriesId, imageId } = useParams();
  const [imageSizeOnScreen, setImageSizeOnScreen] = useState<{ height: number, width: number}>({ height: 0, width: 0 });
  const image = useLoadImage(imageId!);
  const { labelReview, handleAcceptSuggestion, handleConfirmSuggestions } = useLabelReview(photoSeriesId!);
  const { suggestions, suggestionsReviewed } = useSuggestions(labelReview, imageId!);
  const { totalNrOfImages, currentImageNr, isLastImage, isFirstImage, navigateNext, navigatePrevious } = useNavigation(photoSeriesId!, imageId!);
  const isLoading = !image || !labelReview;
  const { isReadOnlyMode, isOriginalAuthor } = useSettings(labelReview);

  if (isLoading) {
    return (
      <PageWrapper pageTitle="Labelling review detail view">
        <div className="border">
          <Skeleton />
        </div>
      </PageWrapper>
    );
  }

  const createdChangeTypeColor = getSuggestionColor(true, ChangeType.Created);
  const deletedChangeTypeColor = getSuggestionColor(true, ChangeType.Deleted);
  const modifiedChangeTypeColor = getSuggestionColor(true, ChangeType.Modified);

  // NOTE: Currently we do not account for image resizing on screen!
  const pointXMultiplier = imageSizeOnScreen.width / image.width;
  const pointYMultiplier = imageSizeOnScreen.height / image.height;

  const getAlertMessage = () => isOriginalAuthor
    ? 'These are suggestions left to you by the reviewer. If you do not see any labels on the canvas, then no suggestions were made for this photo!'
    : `These review suggestions are waiting to be confirmed by ${labelReview.originalAnalysisUser}`;

  return (
    <PageWrapper pageTitle={`Review detail view (${currentImageNr} of ${totalNrOfImages})`}>
      {
        labelReview.suggestionsConfirmedAt === null && (
          <div>
            <Alert variant="warning">
              { getAlertMessage() }
            </Alert>
          </div>
        )
      }
      <div style={{ display: 'flex' }}>
        <div style={{ width: '75%', position: 'relative' }}>
          <img
            style={{ maxWidth: '100%', position: 'absolute' }}
            src={image.localUrl}
            alt={imageId}
            onLoad={(event: any) => {
              const { height, width } = event.target;
              setImageSizeOnScreen({ height, width });
            }}
          />
          <SuggestionsSvg width={imageSizeOnScreen.width} height={imageSizeOnScreen.height}>
            {
              suggestions
                .map((suggestion) => {
                  const highlight = suggestion.active;
                  const color = getSuggestionColor(highlight, suggestion.changeType);
                  const points = suggestion.mask
                    .map((point) => `${point.x * pointXMultiplier},${point.y * pointYMultiplier}`)
                    .join(' ');

                  return (
                    <polygon
                      key={`polygon-${suggestion.id}`}
                      points={points}
                      fill={color.stroke}
                      stroke={color.stroke}
                      fillOpacity="30%"
                      strokeOpacity={suggestion.active ? '100%' : '70%'}
                    />
                  );
                })
            }
          </SuggestionsSvg>
        </div>
        <div style={{ width: '25%', paddingLeft: '1rem', paddingRight: '1rem' }}>
          <h4>Legend</h4>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <LegendItem borderColor={createdChangeTypeColor.stroke} backgroundColor={createdChangeTypeColor.fill}>Created</LegendItem>
            <LegendItem borderColor={deletedChangeTypeColor.stroke} backgroundColor={deletedChangeTypeColor.fill}>Deleted</LegendItem>
            <LegendItem borderColor={modifiedChangeTypeColor.stroke} backgroundColor={modifiedChangeTypeColor.fill}>Modified</LegendItem>
          </div>
          <h4>Suggestions</h4>
          {
            suggestions
              .map((suggestion) => <SuggestionCard
                key={`card-${suggestion.id}`}
                suggestion={suggestion}
                onAccept={handleAcceptSuggestion}
                showActions={!isReadOnlyMode}
              />)
          }
          {
            suggestions.length === 0 && (<span>No suggestions</span>)
          }
        </div>
      </div>
      <Buttons>
        { !isFirstImage && <Navigation onClick={navigatePrevious}>Previous</Navigation> }
        { !isLastImage && <Navigation disabled={!suggestionsReviewed && !isReadOnlyMode} onClick={navigateNext}>Next</Navigation> }
        { isLastImage && !isReadOnlyMode && <Finish disabled={!suggestionsReviewed} onClick={handleConfirmSuggestions}>Finish</Finish> }
      </Buttons>
    </PageWrapper>
  );
};

export default LabellingReviewDetailView;
