import { useEffect } from 'react';
import { useRedux, useReduxDispatch, useReduxSelector } from '../../../../../../redux/store.hooks';

const useLoadImage = (imageId: string) => {
  const dispatch = useReduxDispatch();

  const {
    images: { actions: imagesActions, selectors: imagesSelector },
  } = useRedux();

  const image = useReduxSelector((state) => imagesSelector.selectImageFile(state, imageId));

  useEffect(() => {
    dispatch(imagesActions.downloadImageFile({ imageId: imageId as string }));
  }, [imageId, dispatch, imagesActions]);

  return image;
};

export { useLoadImage };
