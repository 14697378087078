import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &.size--large {
    & svg {
      width: 9.375rem;
      height: 9.375rem;
    }
  }

  &.size--medium {
    & svg {
      width: 3.125rem !important;
      height: 3.125rem !important;
    }
  }

  &.size--small {
    & svg {
      width: 1.8rem !important;
      height: 1.4rem !important;
    }
  }
`;

type SvgSizes = 'large' | 'medium' | 'small';

interface ComposeSvgProps extends React.ComponentPropsWithoutRef<'div'> {
  size: SvgSizes;
  children: React.ReactElement;
}

function ComposeSvg(props: ComposeSvgProps) {
  const child = React.Children.only(props.children);

  return <Container className={`size--${props.size}`}>{React.cloneElement(child)}</Container>;
}

export { ComposeSvg };
