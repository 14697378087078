import styled from 'styled-components';
import { transition } from 'components/shared/skeleton-ui/skeleton-ui.component';

const thumbsApiUrl = process.env.REACT_APP_THUMBNAIL_API_URL;
const thumbsApiCode = process.env.REACT_APP_THUMBNAIL_API_CODE;

export const THUMBNAIL_DEAFULT_SIZE = '100px';

const ThumbnailWrapper = styled.div`
  animation: ${transition} 1.5s infinite;
  width: ${THUMBNAIL_DEAFULT_SIZE};
  height: ${THUMBNAIL_DEAFULT_SIZE};
  cursor: pointer;
`;

const Button = styled.button`
  border: 0;
  background-color: transparent;
`;

const Anchor = styled.a`
  border: 0;
  background-color: transparent;
`;

const Image = styled.img`
  width: ${THUMBNAIL_DEAFULT_SIZE};
  height: ${THUMBNAIL_DEAFULT_SIZE};
  object-fit: cover;
`;

function composeImgSrc(filePath: string, width: string, damageId: string | undefined) {
  if (filePath.length === 0) return '';
  let source = `${thumbsApiUrl}?code=${thumbsApiCode}&f=${filePath}&w=${width}`;

  if (damageId !== undefined) {
    source += `&d=${damageId}`;
  }

  return source;
}

interface ThumbnailProps {
  filePath: string,
  fileName: string,
  width: string,
  damageId?: string | undefined,
}

interface ButtonThumbnailProps extends ThumbnailProps {
  id: string,
  onClick: (id: string) => void
}

const ButtonThumbnail = (props: ButtonThumbnailProps) => {
  const { id, onClick } = props;

  return (
    <ThumbnailWrapper>
      <Button onClick={() => onClick(id)}>
        <Thumbnail {...props} />
      </Button>
    </ThumbnailWrapper>
  );
};

interface AnchorThumbnailProps extends ThumbnailProps {
  href: string,
  target: string
}

const AnchorThumbnail = (props: AnchorThumbnailProps) => {
  const { href, target } = props;

  return (
    <ThumbnailWrapper>
      <Anchor href={href} target={target}>
        <Thumbnail {...props} />
      </Anchor>
    </ThumbnailWrapper>
  );
};

const Thumbnail = (props: ThumbnailProps) => {
  const { filePath, width, damageId, fileName } = props;
  if (filePath.length === 0) return null;

  return (
    <Image src={composeImgSrc(filePath, width, damageId)} alt={fileName} title={fileName} />
  );
};

export { ButtonThumbnail, AnchorThumbnail };
