import { LabelStudioBoundingBox } from '../redux/annotations/annotations.type';

const bboxIntersects = (a: LabelStudioBoundingBox, b: LabelStudioBoundingBox) => {
  if (!a && !b) return false;

  const aCenterX = (a.left + a.right) / 2;
  const aCenterY = (a.top + a.bottom) / 2;
  const aWidth = a.right - a.left;
  const aHeight = a.bottom - a.top;

  const bCenterX = (b.left + b.right) / 2;
  const bCenterY = (b.top + b.bottom) / 2;
  const bWidth = b.right - b.left;
  const bHeight = b.bottom - b.top;

  return (Math.abs(aCenterX - bCenterX) * 2 < (aWidth + bWidth)) &&
         (Math.abs(aCenterY - bCenterY) * 2 < (aHeight + bHeight));
};

export {
  bboxIntersects
};
