import React from 'react';
import styled from 'styled-components';

import { __dev__ } from 'utils';
import { Flex } from 'components/layouts';
import { IconAnnotationCard } from 'assets';

const DISPLAY_NAME = 'AnnotationCard';

const Container = styled(Flex)`
  justify-content: space-between;
`;

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ElementAnnotationCard = React.ElementRef<'div'>;

interface AnnotationCardProps extends PrimitiveDivProps {
  quantity?: number;
}

const AnnotationCard = React.forwardRef<ElementAnnotationCard, AnnotationCardProps>(({ quantity, ...restProps }, forwardedRef) => (
  <Container {...restProps} ref={forwardedRef}>
    {[...new Array(quantity ?? 1)].map((v, i) => (
      <IconAnnotationCard key={i.toString()} width="100px" height="100px" />
    ))}
  </Container>
));

if (__dev__) {
  AnnotationCard.displayName = DISPLAY_NAME;
}

export { AnnotationCard };
