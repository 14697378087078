import { createSlice } from '@reduxjs/toolkit';
import { QueryError } from '../store.type';
import { getLabelReviewCounters, getPagedLabelReviews, updateLabelReview } from './reviews.actions';
import { LabelReview, LabelReviewCounters, LabelReviewsState, PagedLabelReviews } from './reviews.type';

const initialState: LabelReviewsState = {
  isLoading: false,
  error: null,
  pagedLabelReviews: Object.create(null),
  counters: null
};

const labelReviewsSlice = createSlice({
  name: 'labelReviews',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getPagedLabelReviews.fulfilled, (state: LabelReviewsState, { payload }: { payload: PagedLabelReviews }) => {
      const { results, page, pageCount, pageSize, rowCount, sortColumn, sortDirection } = payload;
      const shouldAppend = sortColumn !== null && state.pagedLabelReviews?.sortColumn === sortColumn && page != 1;

      const newResults = state.pagedLabelReviews?.results?.length > 0 && shouldAppend ? [...state.pagedLabelReviews.results, ...results] : results;

      state.isLoading = false;
      state.pagedLabelReviews = {
        results: newResults,
        page,
        pageCount,
        pageSize,
        rowCount,
        sortColumn,
        sortDirection,
      };
    });
    builder.addCase(getPagedLabelReviews.rejected, (state: LabelReviewsState, { payload }: { payload: QueryError }) => {
      state.isLoading = false;
      if (payload) state.error = payload.message;
    });
    builder.addCase(updateLabelReview.fulfilled, (state: LabelReviewsState, { payload }: { payload: LabelReview }) => {
      if (!state.pagedLabelReviews) return;

      const oldResults = state.pagedLabelReviews.results !== undefined
        ? state.pagedLabelReviews.results?.filter((labelReview) => labelReview.photoSeriesId !== payload.photoSeriesId)
        : [];

      state.pagedLabelReviews = {
        ...state.pagedLabelReviews,
        results: [
          ...oldResults,
          ...[payload]
        ]
      };
    });
    builder.addCase(getLabelReviewCounters.fulfilled, (state: LabelReviewsState, { payload } : { payload: LabelReviewCounters }) => {
      state.counters = {
        ...state.counters,
        ...payload
      };
    });
  },
});

export default labelReviewsSlice.reducer;
