/* eslint-disable max-len */
import React from 'react';

import { __dev__ } from 'utils';

const DISPLAY_NAME = 'IconLogout';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'svg'>;
type ElementIconLogout = React.ElementRef<'svg'>;

interface IconLogoutProps extends PrimitiveSvgProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const IconLogout = React.forwardRef<ElementIconLogout, IconLogoutProps>(({ width, height, fill, ...restProps }, forwardedRef) => (
  <svg {...restProps} ref={forwardedRef} width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.32422 1.92578V10.0742H6V11.25H1.32422C1.01432 11.25 0.740885 11.1315 0.503906 10.8945C0.285156 10.6576 0.175781 10.3841 0.175781 10.0742V1.92578C0.175781 1.61589 0.285156 1.34245 0.503906 1.10547C0.740885 0.86849 1.01432 0.75 1.32422 0.75H6V1.92578H1.32422ZM8.92578 3.07422L11.8242 6L8.92578 8.92578L8.10547 8.07812L9.60938 6.57422H3.67578V5.42578H9.60938L8.10547 3.89453L8.92578 3.07422Z"
      fill={fill}
    />
  </svg>
));

IconLogout.defaultProps = {
  width: '12',
  height: '12',
  fill: '#007BFF',
};

if (__dev__) {
  IconLogout.displayName = DISPLAY_NAME;
}

export { IconLogout };
