function deriveFormatStandard(locale?: string) {
  const dateTimeFormat = new Intl.DateTimeFormat(locale ?? 'et', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  });

  return dateTimeFormat;
}

function formatDateTime(date: string | number | Date, dateOnly: boolean = false) {
  const formattedDateTime = deriveFormatStandard().format(new Date(date));
  if (dateOnly) return formattedDateTime.split(' ')[0];
  return formattedDateTime;
}

export { deriveFormatStandard, formatDateTime };
