import { useEffect, useState } from 'react';
import { useRedux, useReduxSelector } from '../../../../../../redux/store.hooks';
import { useNavigate } from 'react-router-dom';

const useNavigation = (photoSeriesId: string, imageId: string) => {
  const [imageIds, setImageIds] = useState<string[]>([]);
  const {
    reviews: { selectors: reviewsSelector }
  } = useRedux();

  const labelReview = useReduxSelector((state) => reviewsSelector.selectLabelReviewByPhotoSeriesId(state, photoSeriesId));
  const navigate = useNavigate();

  useEffect(() => {
    if (!labelReview) return;
    setImageIds(labelReview.images.map((image) => image.id));
  }, [labelReview]);

  const currentImagePosition = imageIds.indexOf(imageId);
  const navigationSettings = {
    totalNrOfImages: imageIds.length,
    currentImageNr: currentImagePosition + 1,
    isFirstImage: currentImagePosition === 0,
    isLastImage: currentImagePosition + 1 === imageIds.length
  };

  const navigateNext = () => {
    if (navigationSettings.isLastImage) return;
    navigate(`/reviewDetail/photoSeries/${photoSeriesId}/image/${imageIds[currentImagePosition + 1]}`);
  };

  const navigatePrevious = () => {
    if (navigationSettings.isFirstImage) return;
    navigate(`/reviewDetail/photoSeries/${photoSeriesId}/image/${imageIds[currentImagePosition - 1]}`);
  };

  return {
    ...navigationSettings,
    navigateNext,
    navigatePrevious
  };
};

export { useNavigation };
