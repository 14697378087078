import { createSelector } from 'reselect';

import {
  LabellingMode, NormalizedAnnotations, Image, NormalizedPhotoSeries,
  NormalizedImages, ANNOTATIONS_PROPERTY_NAME
} from './annotations.type';

import type { RootState } from '../store';

const selectAnnotationsState = (state: RootState) => state.annotations;

const selectAnnotationsIsLoading = createSelector(selectAnnotationsState, (annotations) => annotations.isLoading);
const selectAnnotationsError = createSelector(selectAnnotationsState, (annotations) => annotations.error);
const selectPagedPhotoSeries = createSelector(selectAnnotationsState, (annotations) => annotations.pagedPhotoSeries);
const selectAnnotations = createSelector(selectAnnotationsState, (annotations) => annotations.annotations);
const selectImages = createSelector(selectAnnotationsState, (annotations) => annotations.images);
const selectPhotoSeries = createSelector(selectAnnotationsState, (annotations) => annotations.photoSeries);

const getPhotoSeriesIdParam = (_: RootState, photoSeriesId: string) => photoSeriesId;
const getImageIdParam = (_: RootState, __: string, imageId: string) => imageId;
const getAnnotationsPropNameParam = (_: RootState, __: string, ___: string, labellingMode: LabellingMode) =>
  ANNOTATIONS_PROPERTY_NAME[labellingMode];

const selectAnnotationsByImageId = createSelector(
  selectAnnotations,
  getPhotoSeriesIdParam,
  getImageIdParam,
  getAnnotationsPropNameParam,
  (annotations: NormalizedAnnotations, photoSeriesId: string, imageId: string, annotationsPropName: string) => annotations
    ?.byPhotoSeriesId
    ?.[photoSeriesId]
    ?.[annotationsPropName]
    ?.[imageId],
);

const selectImagesByPhotoSeriesId = createSelector(
  selectImages,
  getPhotoSeriesIdParam,
  (images: NormalizedImages, photoSeriesId: string) => images.byPhotoSeriesId[photoSeriesId]
);

const selectAnnotationsByPhotoSeriesId = createSelector(
  selectAnnotations,
  getPhotoSeriesIdParam,
  (annotations: NormalizedAnnotations, photoSeriesId: string) => annotations
    ?.byPhotoSeriesId
    ?.[photoSeriesId]
);

// TODO use separate photoSeries state
const selectPhotoSeriesByPhotoSeriesId = createSelector(
  selectPhotoSeries,
  getPhotoSeriesIdParam,
  (photoSeries: NormalizedPhotoSeries, photoSeriesId: string) => photoSeries.byPhotoSeriesId[photoSeriesId]
);

const selectImageByImageIdAndPhotoSeriesId = createSelector(
  selectImages,
  getPhotoSeriesIdParam,
  getImageIdParam,
  (images: NormalizedImages, photoSeriesId: string, imageId: string) => images
    .byPhotoSeriesId[photoSeriesId]?.find((image: Image) => image.id === imageId)
);

export {
  selectAnnotationsIsLoading,
  selectAnnotationsError,
  selectPagedPhotoSeries,
  selectAnnotations,
  selectAnnotationsByImageId,
  selectImagesByPhotoSeriesId,
  selectAnnotationsByPhotoSeriesId,
  selectPhotoSeriesByPhotoSeriesId,
  selectImageByImageIdAndPhotoSeriesId
};
