import { useEffect, useState, useCallback, useMemo, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { PATHS } from 'routes';
import { useRedux, useReduxDispatch } from 'redux/store.hooks';
import { toast } from 'react-toastify';

import { _localstorage } from 'utils';

const ERROR_KEY = 'error';
const DEFAULT_MESSAGE = 'Oops! Something went wrong.';

interface CustomError {
  message: string | undefined;
  statusCode: number | undefined;
}

type ErrorHandlerProps = {
  children: ReactElement[];
};

type Actions = {
  onGoToLogin(): void;
  onGoToHomePage(): void;
  onGoToFallbackPage(): void;
};

function errorFactory(error: ErrorEvent, actions: Actions) {
  const { response } = error.error;
  const { status, data } = response;

  let message = error.message;
  let action = null;

  switch (status) {
    case 400: {
      message = data.error;
      break;
    }
    case 401: {
      action = actions.onGoToLogin;
      break;
    }
    case 403: {
      action = actions.onGoToLogin;
      break;
    }
    case 404: {
      action = actions.onGoToHomePage;
      break;
    }
    case 500: {
      action = actions.onGoToFallbackPage;
      break;
    }
    default: {
      action = actions.onGoToLogin;
    }
  }
  return {
    error: {
      statusCode: status,
      message
    } as CustomError,
    action,
  };
}

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;

  hr {
    margin: 0.5rem 0;
  }
`;

const StatusCode = styled.span`
  font-size: 0.613rem;
`;

const Message = styled.span`
  font-size: 0.875rem;
`;

const ErrorMessage = ({ error } : { error: CustomError }) => (
  <ErrorMessageWrapper>
    { error.statusCode !== 400 ? <h6>{DEFAULT_MESSAGE}</h6> : null }
    <Message>{error.message}</Message>
    <hr />
    <StatusCode>Status code: {error.statusCode}</StatusCode>
  </ErrorMessageWrapper>
);

function ErrorHandler(props: ErrorHandlerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [error, setError] = useState<CustomError | null>(null);

  const {
    session: { actions: sessionActions },
  } = useRedux();
  const dispatch = useReduxDispatch();

  /* ---------------------------------------------------------------*\
     Gets errors from localStorage and sets it to component state
 \* ---------------------------------------------------------------- */
  const onStorageErrors = useCallback(() => {
    const localStorageError = _localstorage.getItem<CustomError>(ERROR_KEY);
    if (localStorageError) setError(localStorageError);
  }, []);

  useEffect(() => onStorageErrors(), [onStorageErrors]);

  const actions = useMemo(
    () => ({
      onGoToLogin() {
        const navigateToLogin = () => {
          if (!pathname.endsWith(PATHS.login)) navigate(PATHS.login, { replace: true });
        };

        dispatch(
          sessionActions.logout({
            onSuccess() {
              navigateToLogin();
            },
            onError() {
              navigateToLogin();
            }
          })
        );
      },
      onGoToHomePage() {
        navigate(PATHS.home, { replace: true });
      },
      onGoToFallbackPage() {
        navigate(PATHS.fallback, {
          state: {
            from: { pathname },
            to: { pathname: PATHS.fallback },
            title: 'Error occurred',
            message: 'Sorry we just encountered an error, everything will be fine!',
          },
        });
      },
    }),
    [navigate, pathname, dispatch, sessionActions]
  );

  /* ---------------------------------------------------------------*\
     Handling errors by setting them to localstorage
 \* ---------------------------------------------------------------- */
  const errorHandler = useCallback(
    (errorObj: ErrorEvent) => {
      const { error, action } = errorFactory(errorObj, actions);
      _localstorage.setItem(ERROR_KEY, error);
      if (action) action();
    },
    [actions]
  );

  useEffect(() => {
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, [errorHandler]);

  const handleClose = () => {
    setError(null);
    _localstorage.removeItem(ERROR_KEY);
  };

  useEffect(() => {
    if (!error) return;
    toast.error(<ErrorMessage error={error} />, {
      autoClose: false,
      onClose: handleClose
    });
  }, [error]);

  return (
    <>
      {props.children}
    </>
  );
}

export { ErrorHandler };
