import React from 'react';

function useForceUpdate() {
  const [, setState] = React.useState<{}>(Object.create(null));

  return React.useCallback(() => {
    setState(Object.create(null));
  }, []);
}

export { useForceUpdate };
