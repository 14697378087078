import styled from 'styled-components';

const SHAPES = {
  block: '0',
  radius: '0.3125rem',
  round: '50%',
};

type ComposedButtonProps = {
  level?: 'dark' | 'semi' | 'light';
  shape?: 'radius' | 'round' | 'block';
};

const Button = styled.button<ComposedButtonProps>`
  font-size: minmax(1rem, 1.5rem);
  font-weight: ${(props) => props.theme.font.fontWeights[3]};
  padding: 0.6875rem 1.5rem;
  height: ${(props) => (props.shape === 'round' ? '3.125rem' : 'auto')};
  min-height: 2.5625rem;
  cursor: pointer;
  border: none;
  border-radius: ${(props) => SHAPES[props.shape ?? 'block']};
  transition: all 0.5s ease-out;
`;

Button.defaultProps = {
  level: 'semi',
  shape: 'radius'
};

const BLUE_COLOR_KEYS = {
  dark: 'blueDark',
  semi: 'blueSemi',
  light: 'blueLight',
};

const BlueButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.blue[props.level ?? 'semi']};
  color: ${(props) => props.theme.colors.textColorsFor[BLUE_COLOR_KEYS[props.level ?? 'semi']]};

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.blue.light};
    color: ${(props) => props.theme.colors.textColorsFor.light};
  }
  &:disabled {
    opacity: 0.3;
  }
`;

export { Button, BlueButton };
