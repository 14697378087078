import { SkeletonUI } from 'components/shared/skeleton-ui/skeleton-ui.component';

const Skeleton = () => (
  <>
    <SkeletonUI height="30px" />
    <SkeletonUI height="350px" />
    <SkeletonUI height="100px" />
    <SkeletonUI height="350px" />
    <SkeletonUI height="100px" />
  </>
);

export {
  Skeleton
};
