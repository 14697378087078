import { ThemeProvider } from 'styled-components';

import { theme } from 'utils';
import { Routes } from 'routes';
import { GlobalStyles } from './global.css';
import { ToastContainer } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
      />
      <a href="#main" className="skip-content">
        Skip to main content
      </a>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
