import React from 'react';

import { __dev__ } from 'utils';
import { useVisuallyHidden } from 'hooks';

const DISPLAY_NAME = 'VisuallyHidden';

type PrimitiveSpanProps = React.ComponentPropsWithoutRef<'span'>;
type VisuallyHiddenElement = React.ElementRef<'span'>;

interface VisuallyHiddenProps extends PrimitiveSpanProps {}

const VisuallyHidden = React.forwardRef<VisuallyHiddenElement, VisuallyHiddenProps>((props, forwardedRef) => (
  <span {...props} ref={forwardedRef} {...useVisuallyHidden(props.style)} />
));

if (__dev__) {
  VisuallyHidden.displayName = DISPLAY_NAME;
}

export { VisuallyHidden };
