import { Route } from 'react-router-dom';

import { Router } from 'components/containers';
import { PrivateRoute } from 'components/shared';
import { Login } from 'components/pages';
import Labelling from 'components/pages/annotations/pages/labelling';
import ImageDetail from 'components/pages/annotations/pages/image-detail';
import Dashboard from 'components/pages/dashboard';
import LabellingReviewListView from 'components/pages/reviews/pages/labelling-review-list-view';
import LabellingReviewDetailView from 'components/pages/reviews/pages/labelling-review-detail-view';

import { Permissions } from 'redux/session/session.type';

const PATHS = {
  dashboard: '/dashboard',
  home: '/',
  login: '/login',
  manuals: '/manuals',
  fallback: '/fallback',
  forgotPassword: '/forgotPassword',
  imageDetail: '/labelling/photoSeries/:photoSeriesId/image/:imageId/mode/:labellingModeId',
  reviews: '/reviews',
  reviewDetail: '/reviewDetail/photoSeries/:photoSeriesId/image/:imageId'
} as const;

const PAGE_PERMISSIONS = Object.freeze({
  home: [Permissions.ViewAnnotations],
  imageDetail: [Permissions.ViewAnnotations],
  dashboard: [Permissions.ViewDailyLabellingStatistics],
  reviews: [Permissions.ViewLabelReviews],
  reviewDetail: [Permissions.ViewLabelReviews]
});

function Routes() {
  return (
    <Router>
      <Route path={PATHS.login} element={<Login />} />
      <Route
        path={PATHS.home}
        element={
          <PrivateRoute pagePermissions={PAGE_PERMISSIONS.home}>
            <Labelling.Root />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.imageDetail}
        element={
          <PrivateRoute pagePermissions={PAGE_PERMISSIONS.imageDetail}>
            <ImageDetail.Root />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.dashboard}
        element={
          <PrivateRoute pagePermissions={PAGE_PERMISSIONS.dashboard}>
            <Dashboard.Root />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.reviews}
        element={
          <PrivateRoute pagePermissions={PAGE_PERMISSIONS.reviews}>
            <LabellingReviewListView.Root />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.reviewDetail}
        element={
          <PrivateRoute pagePermissions={PAGE_PERMISSIONS.reviewDetail}>
            <LabellingReviewDetailView.Root />
          </PrivateRoute>
        }
      />
    </Router>
  );
}

export { PATHS, Routes };
