import React from 'react';
import styled, { keyframes, css } from 'styled-components';

type BadgeProps = {
  icon?: React.ReactNode,
  variant: 'green' | 'red' | 'yellow',
  txt: string | number,
  pulse?: boolean
}
const badgeBorder = (variant: 'green' | 'red' | 'yellow'): string => {
  switch (variant) {
    case 'green': return '#C3E6CB';
    case 'red': return '#F5C6CB';
    case 'yellow': return '#FFEEBA';
    default: return '';
  }
};

const badgeBackgroundColor = (variant: 'green' | 'red' | 'yellow'): string => {
  switch (variant) {
    case 'green': return '#D4EDDA';
    case 'red': return '#F8D7DA';
    case 'yellow': return '#FFF3CD';
    default: return '';
  }
};

const badgeColor = (variant: 'green' | 'red' | 'yellow'): string => {
  switch (variant) {
    case 'green': return '#155724';
    case 'red': return '#721C24';
    case 'yellow': return '#856404';
    default: return '';
  }
};

const pulse = (variant: 'green' | 'red' | 'yellow') => keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${variant};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const StyledBadge = styled.div<{ variant: 'green' | 'red' | 'yellow', pulse: boolean | undefined }>`
  display: flex;
  align-items: center;
  border: 0.1rem solid ${(props) => badgeBorder(props.variant)};
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background-color: ${(props) => badgeBackgroundColor(props.variant)};

  ${(props) => props.pulse && 'transform: scale(1);'}
  ${(props) => props.pulse && css`animation: ${pulse(props.variant)} 1.5s infinite;`}

  span {
    font-size: 0.8rem;
    color: ${(props) => badgeColor(props.variant)};
  }
`;

const StyledIcon = styled.div<{ variant: 'green' | 'red' | 'yellow' }>`
  display: flex;
  align-items: center;

  svg {
    width: 0.8rem;
    margin-right: 0.25rem;
    fill: ${(props) => badgeColor(props.variant)} !important;
  }
`;

const Badge = ({ icon, variant, txt, pulse }: BadgeProps) => (
  <StyledBadge variant={variant} pulse={pulse}>
    {
      icon && (
        <StyledIcon variant={variant}>
          {icon}
        </StyledIcon>
      )
    }
    <span>{txt}</span>
  </StyledBadge>
);

export { Badge };
