import React from 'react';

import { throwError } from './error';

type CreateContextReturnType<T> = [React.Provider<T>, () => T, React.Consumer<T>];

/* -------------------------------------------------------------------------------------------------
 * createContext - The util use for creating Context based Components
 * -----------------------------------------------------------------------------------------------*/
function createContext<T>(displayName: string) {
  const Context = React.createContext<T>(null!);
  Context.displayName = displayName;

  function useContext() {
    const context = React.useContext(Context);

    if (!context) {
      throwError('CreateContextError', 'To use context, the Consumer Component needs to be in the Context Provider Scope as containment', useContext);
    }

    return context;
  }

  return [Context.Provider, useContext, Context.Consumer] as CreateContextReturnType<T>;
}

export { createContext };
