/* eslint-disable no-unused-vars */
import { GenericAjaxType } from '../store';

export type Image = {
  id: string;
  imageType: ImageType;
  imageSubType: ImageSubType | null;
  fileName: string;
  filePath: string;
  annotations: Annotation[];
  analysedAt?: string;
  relatedImageId: string | null;
  relatedDamageId: string | null;
};

export enum ImageType {
  Exterior = 1,
  Interior = 2,
  Vin = 3,
  Windshield = 4,
  Additional = 5,
  Odometer = 6,
  VehicleKeys = 7,
}

export enum ImageSubType {
  Front = 1,
  FrontLeft = 2,
  Left = 3,
  BackLeft = 4,
  Back = 5,
  BackRight = 6,
  Right = 7,
  FrontRight = 8,
  Dashboard = 10,
  FromOutside = 20,
  FromInside = 21,
  OnVehicle = 30,
  InspectionSticker = 31,
}

export type Result = {
  photoSeriesId: string;
  analysedAt: string;
  analysisConfirmedAt: string;
  analysisAssignedAt: string;
  analysisAssignedToUser: string;
  completedAt: string;
  vehicle: {
    id: string;
    registrationNo: string;
    vin: string;
  };
  images: Image[];
  windshieldRepairReplaceDecisionEnabled: boolean;
};

export interface PagedPhotoseries {
  results: Result[];
  page: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  sortColumn: string;
  sortDirection: string;
}

export interface AnnotationsError {
  message: string;
}

export type AnnotationsState = {
  isLoading: boolean;
  error: string | null;
  pagedPhotoSeries: PagedPhotoseries;
  annotations: NormalizedAnnotations;
  images: NormalizedImages;
  photoSeries: NormalizedPhotoSeries;
};

export type GetPhotoSeriesAnnotationsArg = {
  photoSeriesId: string;
  labellingMode: LabellingMode;
  imageId?: string | null;
} & GenericAjaxType;

export type GetPhotoSeriesArg = {
  queryType?: QueryType;
  photoSeriesIds?: string[] | null;
  imageId?: string | null;
  detailed?: boolean | null;
  assignedToCurrentUser?: boolean | null;
  pagedQuery: PagedQuery;
  searchPhrase?: string | null;
  notConfirmedForRetraining?: boolean | null;
} & GenericAjaxType;

export type NormalizedAnnotations = {
  byPhotoSeriesId: { [id: string]: PhotoSeriesAnnotations };
};

export type NormalizedImages = {
  byPhotoSeriesId: { [id: string]: Image[] };
};

export type NormalizedPhotoSeries = {
  byPhotoSeriesId: { [id: string]: PhotoSeries }
};

export type PhotoSeries = {
  analysisAssignedToUser: string;
  windshieldRepairReplaceDecisionEnabled: boolean;
  analysisAssignedAt: string;
};

export type PhotoSeriesAnnotations = {
  // Photo series annotations are stored separately based on labelling mode
  // Refer to ANNOTATIONS_PROPERTY_NAME for key
  [byImageId: string]: { [id: string]: Annotation[] };
};

export interface Annotation {
  id: string;
  bodyPart: BodyPart | null;
  mask: Point[];
  damages: DamageInfo[];
  subMasks?: SubMask[];
}

export interface SubMask {
  mask: Point[];
  subMaskType: SubMaskType
}

export interface DamageInfo {
  id: string;
  parentId: string | null;
  damageType: DamageType;
  confidence?: number;
  hasDamage?: boolean;
  location: DamageLocation;
  repairDecision: RepairDecision | null;
}

export interface DamageLocation {
  mask: Point[];
}

export enum BodyPart {
  // Internals = 0,
  Unknown = 1,
  // Tire = 2,
  Windshield = 3,
  TailgateGlass = 4,
  // Wiper = 5,
  Fender = 6,
  BackDoor = 7,
  FrontDoor = 8,
  Mirror = 9,
  DoorGlass = 10,
  // SignalLight = 11,
  Hood = 12,
  Trunk = 13,
  LicensePlate = 14,
  FrontBumper = 15,
  RearBumper = 16,
  Light = 17,
  // FogLight = 18,
  Grille = 19,
  Wheel = 20,
  Body = 21,
  // TowHitch = 22,
  // InnerFender = 23,
  // FenderFlare = 24,
  SideSkirt = 25
}

export enum DamageType {
  Dent = 0,
  LampBroken = 1,
  PaintDamage = 2,
  Rust = 3,
  Scrape = 4,
  Scratch = 5,
  Chip = 6,
  Crack = 7,
  OtherDamage = 8,
  Misaligned = 9,
  MissingPart = 10,
  FalsePositive = 100,
}

export enum SubMaskType {
  RepairableArea = 0,
  NotRepairableArea = 1
}

export interface Point {
  x: number;
  y: number;
}

export type LabelStudioAnnotation = {
  id: string;
  source: string;
  from_name: string;
  to_name: string;
  parentID?: string;
  parentDamageId?: string | null;
  type: string;
  repairDecision: RepairDecision | null;
  value: {
    rotation: 0;
    width: number;
    height: number;
    x?: number;
    y?: number;
    rectanglelabels?: [string];
    brushlabels?: [string];
    points?: number[][];
  };
};

export type LabelStudioRegion = {
  id: string;
  tag: LabelStudioTag;
  annotation: any;
  hidden: boolean;
  bboxCoords: LabelStudioBoundingBox;
  parentID: string | null;
  parentDamageId?: string | null;
  repairDecision: RepairDecision | null;
  labels: string[];
  inSelection: boolean;
  setParentID(labelStudioAnnotationId: string): void;
  deleteRegion(): void;
  toggleHidden(): void;
  labelName: string;
};

export type LabelStudioArea = {
  id: string;
  labelName: string;
  tag: LabelStudioTag;
  bboxCoords: LabelStudioBoundingBox;
};

export type LabelStudioBoundingBox = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export type LabelStudioTag = {
  name: string;
};

export type SaveAnnotationsArg = {
  photoSeriesId: string;
  imageId: string;
  annotations: Annotation[];
  labellingMode: LabellingMode;
} & GenericAjaxType;

export type ConfirmAnnotationsArg = {
  photoSeriesId: string;
} & GenericAjaxType;

export type AssignPhotoSeriesArg = {
  photoSeriesId: string;
} & GenericAjaxType;

export type AssignedPhotoSeriesAnalysis = {
  photoSeriesId: string;
  userId: string;
  user: string;
  assignedAt: string;
};

export type UpdateDamageRepairDecisionArg = {
  damageId: string | null;
  parentDamageId: string | null;
  repairDecision: RepairDecision | null;
  photoSeriesId: string;
};

export type PagedQuery = {
  page?: number;
  pageSize?: number;
  sortColumn?: SortColumn;
  sortDirection?: SortDirection;
};

export enum SortColumn {
  CompletedAt = 'CompletedAt',
  AnalysisConfirmedAt = 'AnalysisConfirmedAt',
  AnalysisAssignedAt = 'AnalysisAssignedAt',
}

export enum SortDirection {
  Asc = 0,
  Desc = 1,
}

export enum QueryType {
  ReviewNeeded,
  InReview,
  Reviewed,
}

export enum LabellingMode {
  // Labelling damage and body parts with the purpose of displaying it for the customer
  DamageDetection = '0',
  // Labelling damage and body parts with the purpose of training neural networks for automatic damage detection
  ModelTraining = '1',
  // Labelling damage and body parts with the purpose of reviewing created labels by data ops
  LabelReview = '2'
}

export enum RepairDecision {
  Repair = 0,
  Replace = 1,
}

export const MODEL_TRAINING_ANNOTATIONS = 'MODEL_TRAINING_ANNOTATIONS';
export const DAMAGE_DETECTION_ANNOTATIONS = 'DAMAGE_DETECTION_ANNOTATIONS';
export const LABEL_REVIEW_ANNOTATIONS = 'LABEL_REVIEW_ANNOTATIONS';

export const ANNOTATIONS_PROPERTY_NAME: { [key in LabellingMode]: string } = {
  [LabellingMode.ModelTraining]: MODEL_TRAINING_ANNOTATIONS,
  [LabellingMode.DamageDetection]: DAMAGE_DETECTION_ANNOTATIONS,
  [LabellingMode.LabelReview]: LABEL_REVIEW_ANNOTATIONS
};
