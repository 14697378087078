import { createSlice } from '@reduxjs/toolkit';

import { downloadImageFile } from './images.actions';
import type { ImagesState } from './images.type';

const initialState: ImagesState = {
  imageFiles: Object.create(null),
  isLoading: false,
};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadImageFile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadImageFile.fulfilled, (state, { payload }) => {
      const { imageId, fileLocalUrl, width, height } = payload;

      state.isLoading = false;
      state.imageFiles = {
        ...state.imageFiles,
        [imageId]: {
          localUrl: fileLocalUrl,
          width,
          height,
        },
      };
    });
    builder.addCase(downloadImageFile.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default imagesSlice.reducer;
