import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { PATHS } from 'routes';
import { useAuth } from 'hooks';
import { Permissions } from 'redux/session/session.type';
import { MobileHeader } from './header.mobile';
import { DesktopHeader } from './header.desktop';

import { useLabelReviewPolling } from './hooks';
import Badge from 'react-bootstrap/Badge';

const HeaderNode = styled.header`
  background-color: ${(props) => props.theme.colors.blue.dark};
  font-weight: ${(props) => props.theme.font.fontWeights[0]};
  height: 3.5625rem;
  width: 100%;

  @media (max-width: 48.25rem) {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

const UnorderedListNode = styled.ul`
  margin-bottom: 0;
`;

const ListItemNode = styled.li`
  display: flex;
  align-items: center;
`;

const Manuals = () => {
  const destination = process.env.REACT_APP_MANUAL_DESTINATION;
  if (!destination || destination.length === 0) return null;
  return (
    <ListItemNode>
      <a href={destination} rel="noreferrer noopener" target="_blank">Manuals</a>
    </ListItemNode>
  );
};

function NavLinks() {
  const { isAuthorized: canViewDashboard } = useAuth([Permissions.ViewDailyLabellingStatistics]);
  const { isAuthorized: canViewLabelReviews } = useAuth([Permissions.ViewLabelReviews]);
  const { labelReviewCounters } = useLabelReviewPolling();
  const totalLabelReviews = labelReviewCounters !== null
    ? labelReviewCounters.currentUserReviewsRequested + labelReviewCounters.currentUserReviewFeedbackRequested
    : 0;

  return (
    <nav>
      <UnorderedListNode>
        <ListItemNode>
          <NavLink to={PATHS.home} className={(props) => (props.isActive ? 'link--active' : '')}>
            Labelling
          </NavLink>
        </ListItemNode>
        {
          canViewLabelReviews && (
            <ListItemNode>
              <NavLink to={PATHS.reviews} className={(props) => (props.isActive ? 'link--active' : '')}>
                Reviews
                {
                  totalLabelReviews > 0 && (<Badge style={{ marginLeft: '0.188rem' }} bg="danger">{totalLabelReviews}</Badge>)
                }
              </NavLink>
            </ListItemNode>
          )
        }
        <Manuals />
        { canViewDashboard && (
          <ListItemNode>
            <NavLink to={PATHS.dashboard} className={(props) => (props.isActive ? 'link--active' : '')}>
              Dashboard
            </NavLink>
          </ListItemNode>
        )}
      </UnorderedListNode>
    </nav>
  );
}

function Header({ full }: { full?: boolean}) {
  return (
    <HeaderNode>
      <MobileHeader>
        <NavLinks />
      </MobileHeader>
      <DesktopHeader full={full}>
        <NavLinks />
      </DesktopHeader>
    </HeaderNode>
  );
}

export { Header };
