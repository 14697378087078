import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PATHS } from 'routes';
import { IconLogo } from 'assets';
import { Container } from 'components/layouts';
import { AccessibleIcon } from 'components/shared';
import { UserAction } from './components/user-action';

const Wrapper = styled.div<{ full?: boolean }>`
  display: none;

  @media (min-width: 48.3125rem) {
    display: block;
    height: 100%;
  }

  & .container {
    color: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: ${(props) => props.full ? '100%' : props.theme.centerWidth};


    & a {
      color: ${(props) => props.theme.colors.white};
    }

    & .container__row-1 {
      display: flex;
      align-items: center;
      height: 100%;
      width: 70%;

      & .link-home {
        margin-right: 2.5rem;
        display: block;
        display: flex;
        align-items: center;

        & svg {
          width: 4.375rem;
          height: inherit;
          display: flex;
          align-items: center;
        }
      }

      & nav ul {
        display: flex;

        & > li {
          padding: 0 1.125rem;

          & a {
            color: hsla(0, 0%, 100%, 0.75);

            &:hover {
              color: #fff;
            }
          }
        }
      }

      & .link--active {
        color: #fff;
      }
    }

    & .container__row-2 {
      width: 30%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

type DesktopHeaderProps = {
  children: React.ReactElement;
  full?: boolean;
};

function DesktopHeader(props: DesktopHeaderProps) {
  return (
    <Wrapper full={props.full}>
      <Container className="container">
        <div className="container__row-1">
          <Link to={PATHS.home} className="link-home">
            <AccessibleIcon label="Click to go to home">
              <IconLogo fill="#fff" />
            </AccessibleIcon>
          </Link>
          {React.cloneElement(React.Children.only(props.children))}
        </div>
        <div className="container__row-2 user-action">
          <UserAction fill="#fff" />
        </div>
      </Container>
    </Wrapper>
  );
}

export { DesktopHeader };
