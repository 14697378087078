import { GenericAjaxType } from '../store';
import { PagedQuery } from '../store.type';
import { Image, Annotation, Point, BodyPart, DamageType } from '../annotations/annotations.type';

export type LabelReview = {
  photoSeriesId: string,
  vehicle: {
    id: string;
    registrationNo: string;
    vin: string;
  };
  images: Image[];
  originalAnalysisUser: string;
  assignedToUser: string;
  suggestionsConfirmedAt: string | null;
  suggestionsSubmittedAt: string | null;
  windshieldRepairReplaceDecisionEnabled: boolean;
  createdAt: string;
  suggestedAnnotations: SuggestedAnnotation[];
};

export type Suggestion = {
  id: string;
  changeType: ChangeType;
  suggestionAccepted?: boolean | null;
  mask: Point[];
}

export type SuggestedAnnotation = {
  bodyPart: BodyPart;
  suggestedDamages: SuggestedDamage[];
  imageId: string;
} & Suggestion;

export type SuggestedDamage = {
  damageType: DamageType;
  mask: Point[];
} & Suggestion;

export enum ChangeType {
  Created = 0,
  Modified = 1,
  Deleted = 2,
  Unchanged = 3
}

export interface PagedLabelReviews {
  page: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  sortColumn: string;
  sortDirection: string;
  results: LabelReview[];
}

export enum LabelReviewQueryType {
  RequestedFromMe = 0,
  MyReviews = 1,
  All = 2
}

export type GetPagedLabelReviewsArg = {
  queryType?: LabelReviewQueryType;
  pagedQuery?: PagedQuery | null;
  photoSeriesId?: string | null
} & GenericAjaxType;

export type GetLabelReviewAnnotationsArg = {
  photoSeriesId: string
}

export type SaveLabelReviewAnnotationsArg = {
  photoSeriesId: string;
  imageId: string;
  annotations: Annotation[];
}

export type SubmitLabelReviewArg = {
  photoSeriesId: string;
}

export type ConfirmLabelReviewArg = {
  photoSeriesId: string;
}

export type LabelReviewsState = {
  isLoading: boolean;
  error: string | null;
  pagedLabelReviews: PagedLabelReviews;
  counters: LabelReviewCounters | null;
}

export type UpdateLabelReviewArg = {
  photoSeriesId: string;
  suggestionId?: string | null;
  suggestionAccepted?: boolean | null;
}

export type GetNeedsReviewCountersArg = {};

export type LabelReviewCounters = {
  currentUserReviewsRequested: number;
  currentUserReviewFeedbackRequested: number;
}
