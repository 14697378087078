import styled from 'styled-components';

const Container = styled.div<{ bg?: string, full?: boolean }>`
  background-color: ${(props) => props.bg ?? 'inherit'};
  max-width: ${(props) => props.full ? '100%' : props.theme.centerWidth};
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.space.sm};
  width: 100%;
`;

export { Container };
