import React from 'react';

import { useUniqueIds } from 'hooks';
import { createContext, __dev__ } from 'utils';

const DISPLAY_NAME = 'TabProvider';

// fixme: This data structure does not allow multiple tabs and panels.
type TabContext = {
  controlId: string;
  panelId: string;
  ariaSelected: boolean;
  setAriaSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

const [TabProvider, useTabContext] = createContext<TabContext>(DISPLAY_NAME);

export { useTabContext };

type PrimitiveContextProviderProps = React.ComponentPropsWithoutRef<'div'>;
type TabElement = React.ElementRef<'div'>;

interface TabProps extends PrimitiveContextProviderProps {}

const Tab = React.forwardRef<TabElement, TabProps>((props, forwardedRef) => {
  const [controlId, panelId] = useUniqueIds(2);
  const [ariaSelected, setAriaSelected] = React.useState(false);
  const tabContextValue = React.useMemo<TabContext>(
    () => ({
      controlId,
      panelId,
      ariaSelected,
      setAriaSelected,
    }),
    [ariaSelected, controlId, panelId]
  );

  return (
    <TabProvider value={tabContextValue}>
      <div {...props} ref={forwardedRef} />
    </TabProvider>
  );
});

if (__dev__) {
  Tab.displayName = DISPLAY_NAME;
}

export default Tab;
