import { useAuth } from 'hooks';
import { useCallback } from 'react';
import { LabellingMode } from 'redux/annotations/annotations.type';
import { useRedux, useReduxSelector } from 'redux/store.hooks';
import { useImageNavigation } from './useImageNavigation';

const useCanEditCurrentImage = (photoSeriesId: string | undefined, labellingMode: LabellingMode) => {
  const { email: currentUserEmail } = useAuth();
  const { annotations: { selectors: annotationsSelectors } } = useRedux();
  const photoSeries = useReduxSelector((state) => annotationsSelectors.selectPhotoSeriesByPhotoSeriesId(state, photoSeriesId as string));
  const { imageIndex } = useImageNavigation();
  const images = useReduxSelector((state) => annotationsSelectors.selectImagesByPhotoSeriesId(state, photoSeriesId as string));

  return useCallback(() => {
    if (photoSeries === undefined) return false;
    if (!images || !images[imageIndex] || !images[imageIndex].analysedAt) return false;
    if (labellingMode === LabellingMode.ModelTraining) return true;

    return photoSeries.analysisAssignedToUser === currentUserEmail;
  }, [photoSeries, currentUserEmail, labellingMode, imageIndex, images]);
};

export { useCanEditCurrentImage };
