import React, { Fragment } from 'react';
import { Field } from 'formik';

import { Error } from '..';
import { __dev__ } from 'utils';

const DISPLAY_NAME = 'Input';

type PrimitiveInputProps = React.ComponentPropsWithoutRef<'textarea' | 'input'>;
type ElementInput = React.ElementRef<'textarea' | 'input'>;

type InputProps = {
  id?: string;
  label?: string;
  type?: string;
  error?: string;
} & PrimitiveInputProps;

const Input = React.forwardRef<ElementInput, InputProps>((props, forwardedRef) => {
  const { id, label, type, error, ...restProps } = props;

  return (
    <Fragment>
      {label && <label htmlFor={id}>{label}</label>}
      {type === 'textarea' ? (
        <Field {...restProps} as="textarea" id={id} ref={forwardedRef} />
      ) : (
        <Field {...restProps} id={id} ref={forwardedRef} type={type} />
      )}
      <Error shown={!!error}>{error}</Error>
    </Fragment>
  );
});

if (__dev__) {
  Input.displayName = DISPLAY_NAME;
}

export { Input };
