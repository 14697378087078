import { useEffect } from 'react';
import { LabelReviewCounters } from '../../../../redux/reviews/reviews.type';
import { useRedux, useReduxDispatch, useReduxSelector } from '../../../../redux/store.hooks';
import { useAuth } from '../../../../hooks';
import { Permissions } from '../../../../redux/session/session.type';

const POLLING_INTERVAL = 1000 * 30;
const POLLING_ENABLED = true;

const useLabelReviewPolling = () => {
  const dispatch = useReduxDispatch();
  const {
    reviews: { actions, selectors },
  } = useRedux();
  const { isAuthorized: canViewLabelReviews } = useAuth([Permissions.ViewLabelReviews]);
  const labelReviewCounters: LabelReviewCounters | null = useReduxSelector(selectors.selectLabelReviewCounters);

  useEffect(() => {
    let id: any;
    if (!canViewLabelReviews) return;
    dispatch(actions.getLabelReviewCounters({}));
    if (POLLING_ENABLED) {
      id = setInterval(() => dispatch(actions.getLabelReviewCounters({})), POLLING_INTERVAL);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [canViewLabelReviews, actions, dispatch]);

  return {
    labelReviewCounters
  };
};

export { useLabelReviewPolling };
