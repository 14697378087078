import React from 'react';
import { Navigate } from 'react-router-dom';

import { PATHS } from 'routes';
import { useAuth } from 'hooks';

interface PrivateRouteProps {
  pagePermissions: number[];
  children: React.ReactElement;
}

function PrivateRoute(props: PrivateRouteProps) {
  const { pagePermissions, children } = props;
  const { isAuthorized, userPermissions } = useAuth(pagePermissions);

  if (!isAuthorized) {
    return <Navigate to={`${userPermissions.length ? PATHS.home : PATHS.login}`} replace />;
  }

  return children;
}

export { PrivateRoute };
