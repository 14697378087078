import { useAuth } from 'hooks';
import { LabelReview } from 'redux/reviews/reviews.type';

const useSettings = (labelReview: LabelReview | undefined) => {
  const { email } = useAuth();

  const isOriginalAuthor = labelReview?.originalAnalysisUser === email;
  const isReadOnlyMode = labelReview?.suggestionsConfirmedAt !== null || !isOriginalAuthor;

  return {
    isReadOnlyMode,
    isOriginalAuthor
  };
};

export { useSettings };
