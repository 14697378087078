import { useRef } from 'react';
import uniqid from 'uniqid';

import { throwError } from 'utils';

function useUniqueIds(count: number) {
  if (count < 1) throwError('useUniqueIdsError', 'Count must be greater than 0', useUniqueIds);

  const ids = useRef([...new Array(Math.floor(count))].map(() => uniqid())).current;

  return ids;
}

export { useUniqueIds };
