import { GenericAjaxType } from 'redux/store';

export interface Session {
  id: string;
  token: string | undefined;
  email: string;
  customerCode: string;
  mustChangePassword: boolean;
  permissions: number[];
  preferredLang: string;
  sessionExpiresAt: string;
  refreshToken: string | undefined;
  askAdditionalInformation: boolean;
}

export type SessionState = {
  isLoading: boolean;
  error: string | null;
  session: Session | {};
};

export interface SessionError {
  message: string;
}

export type LoginFormValues = {
  email: string;
  password: string;
};

export type LoginData = {
  loginData: LoginFormValues;
} & GenericAjaxType;

export enum Permissions
{
  ViewAnnotations = 18,
  EditAnnotations = 19,
  EditTrainingAnnotations = 21,
  ViewDailyLabellingStatistics = 26,
  ViewLabelReviews = 37,
  EditLabelReviewAnnotations = 38,
  EditLabelReview = 39,
  ViewAllLabelReviews = 40
}
