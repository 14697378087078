import React from 'react';
import styled from 'styled-components';

import { Flex } from '../../../components/layouts';
import { AnnotationCard, SkeletonUI } from '../../../components/shared';

const Row = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

const CarDataColumnOne = styled(Flex)`
  width: 70%;
`;

const CarDataColumnTwo = styled.div`
  width: 35%;
`;

const AnnotationCardsWrapper = styled.div``;

export const PhotoSeriesListViewSkeleton = (props: { quantity?: number }) => (
  <>
    {[...new Array(props.quantity ?? 1)].map((v, i) => (
      <Row key={i.toString()}>
        <Wrapper>
          <SkeletonUI width="5%" height="1.5rem" />
        </Wrapper>
        <Wrapper>
          <CarDataColumnOne>
            <SkeletonUI width="22%" height="1.5rem" />
            &nbsp;
            <SkeletonUI width="10%" height="1.5rem" marginBottom="1rem" />
          </CarDataColumnOne>
          <CarDataColumnTwo>
            <SkeletonUI width="100%" height="1.5rem" marginBottom="1rem" />
          </CarDataColumnTwo>
        </Wrapper>

        <AnnotationCardsWrapper>
          <AnnotationCard quantity={11} />
        </AnnotationCardsWrapper>
      </Row>
    ))}
  </>
);
