import React from 'react';

function useVisuallyHidden(props?: React.CSSProperties): { style: React.CSSProperties } {
  return {
    style: {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '0.0625rem',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '0.0625rem',
      ...(props ?? {}),
    },
  };
}

export { useVisuallyHidden };
