import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMsg } from '../store.util';
import { getRequest, promisify, postRequest, patchRequest } from '../../services';
import { toast } from 'react-toastify';

import {
  PagedLabelReviews, GetPagedLabelReviewsArg, GetLabelReviewAnnotationsArg, SaveLabelReviewAnnotationsArg,
  SubmitLabelReviewArg, UpdateLabelReviewArg,
  LabelReview,
  LabelReviewCounters,
  GetNeedsReviewCountersArg
} from './reviews.type';
import { QueryError } from '../store.type';
import { ConfirmAnnotationsArg, LabellingMode } from 'redux/annotations/annotations.type';

const TYPE_PREFIX = '@drivex/reviews';
const INITIAL_PAGE = 1;
const INITIAL_PAGE_SIZE = 5;

const getPagedLabelReviews = createAsyncThunk<PagedLabelReviews, GetPagedLabelReviewsArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/getLabelReviews`,
  async (payload, thunkApi) => {
    const queryParams: string[] = [];

    if (payload.queryType !== undefined && payload.queryType !== null) queryParams.push(`queryType=${payload.queryType}`);
    if (payload.photoSeriesId !== undefined && payload.photoSeriesId !== null) queryParams.push(`photoSeriesId=${payload.photoSeriesId}`);

    queryParams.push(`page=${payload.pagedQuery?.page ?? INITIAL_PAGE}`);
    queryParams.push(`pageSize=${payload.pagedQuery?.pageSize ?? INITIAL_PAGE_SIZE}`);

    const [success, error] = await promisify<any, any>(getRequest(`/labelReviews?${queryParams.join('&')}`));

    if (success) {
      return success.data!;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const getLabelReviewCounters = createAsyncThunk<LabelReviewCounters, GetNeedsReviewCountersArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/getNeedsReviewCounters`,
  async (payload, thunkApi) => {
    const [success, error] = await promisify<any, any>(getRequest('labelReviews/counters'));

    if (success) {
      return success.data!;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const getLabelReviewAnnotations = createAsyncThunk<PagedLabelReviews, GetLabelReviewAnnotationsArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/getLabelReviewAnnotations`,
  async (payload, thunkApi) => {
    const queryParams: string[] = [];

    queryParams.push(`page=${INITIAL_PAGE}`);
    queryParams.push(`pageSize=${INITIAL_PAGE_SIZE}`);
    queryParams.push(`photoSeriesId=${payload.photoSeriesId}`);
    queryParams.push('includeAnnotations=true');

    const [success, error] = await promisify<any, any>(getRequest(`/labelReviews?${queryParams.join('&')}`));

    if (success) {
      return success.data!;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const saveLabelReviewAnnotations = createAsyncThunk<any, SaveLabelReviewAnnotationsArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/saveLabelReviewAnnotations`,
  async (payload, thunkApi) => {
    const { photoSeriesId, imageId, annotations } = payload;

    const [success, error] = await promisify<any, any>(
      toast.promise(postRequest(`/labelReviews/${payload.imageId}`, annotations), {
        pending: 'Saving annotations',
        success: 'Annotations saved',
        error: 'Error saving annotations'
      })
    );

    if (success) {
      return {
        photoSeriesId,
        imageId,
        annotations: success.data,
        labellingMode: LabellingMode.LabelReview
      };
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const submitLabelReview = createAsyncThunk<LabelReview, SubmitLabelReviewArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/submitLabelReview`,
  async (payload, thunkApi) => {
    const { photoSeriesId } = payload;

    const [success, error] = await promisify<any, any>(
      toast.promise(postRequest(`/labelReviews/${photoSeriesId}/submit`, {}), {
        pending: 'Submitting label review',
        success: 'Label review submitted',
        error: 'Error submtting label review'
      })
    );

    if (success) {
      return success.data;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response)
    });
  }
);

const confirmLabelReview = createAsyncThunk<LabelReview, ConfirmAnnotationsArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/confirmLabelReview`,
  async (payload, thunkApi) => {
    const { photoSeriesId } = payload;

    const [success, error] = await promisify<any, any>(
      toast.promise(postRequest(`/labelReviews/${photoSeriesId}/confirm`, {}), {
        pending: 'Confirming label review',
        success: 'Label review confirmed',
        error: 'Error confirming label review'
      })
    );

    if (success) {
      return success.data;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response)
    });
  }
);

const updateLabelReview = createAsyncThunk<LabelReview, UpdateLabelReviewArg, { rejectValue: QueryError }>(
  `${TYPE_PREFIX}/updateLabelReview`,
  async (payload, thunkApi) => {
    const { photoSeriesId } = payload;
    const [success, error] = await promisify<any, any>(
      toast.promise(patchRequest(`/labelReviews/${photoSeriesId}`, payload), {
        pending: 'Updating label review',
        success: 'Label review updated',
        error: 'Error updating label review'
      })
    );

    if (success) {
      return success.data;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response)
    });
  }
);

export {
  getPagedLabelReviews,
  getLabelReviewCounters,
  getLabelReviewAnnotations,
  saveLabelReviewAnnotations,
  submitLabelReview,
  confirmLabelReview,
  updateLabelReview
};
