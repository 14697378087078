import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useForceUpdate } from 'hooks';

type PortalProps = {
  elementType?: string;
  children: React.ReactNode;
};

function Portal(props: PortalProps) {
  const { elementType, children } = props;
  const mountedNodeRef = useRef<HTMLSpanElement | null>(null);
  const portalNodeRef = useRef<HTMLElement | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!mountedNodeRef.current) return;

    const ownerDocument = mountedNodeRef.current.ownerDocument;
    portalNodeRef.current = ownerDocument.createElement(elementType!);
    ownerDocument.body.appendChild(portalNodeRef.current!);
    forceUpdate();

    return () => {
      if (portalNodeRef.current?.ownerDocument?.body) {
        portalNodeRef.current.ownerDocument.body.removeChild(portalNodeRef.current);
      }
    };
  }, [elementType, forceUpdate]);

  return portalNodeRef.current ? createPortal(children, portalNodeRef.current) : <span ref={mountedNodeRef} />;
}

Portal.defaultProps = {
  elementType: 'drivexportal',
};

export { Portal };
