import React from 'react';
import styled from 'styled-components';

const ButtonsGroupNode = styled.div`
  display: flex;
  border: solid 0.0625rem transparent;
  transition: all 0.5s ease-out;

  &:hover {
    border-color: #c7e0fc;
    box-shadow: 0 0 0 0.25rem #e1ecf9;
    border-radius: 0.3125rem;
  }

  & > button {
    border: 0;
    padding: 0.375rem 0.75rem;
    background-color: #e1ecf9;
    color: #007bff;

    &:disabled {
      cursor: not-allowed;
      pointer-events: all !important;
      background-color: #007bff;
      color: #fff;
    }

    &:nth-child(1) {
      border-bottom-left-radius: 0.3125rem;
      border-top-left-radius: 0.3125rem;
      border-right: 1px solid white;
    }

    &:nth-last-child(1) {
      border-bottom-right-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-left: 1px solid white;
    }
  }
`;

type ButtonsGroupProps = {
  children?: React.ReactNode;
};

const ButtonsGroup = ({ children }: ButtonsGroupProps) => (
  <ButtonsGroupNode>
    { children }
  </ButtonsGroupNode>
);

export { ButtonsGroup };
