import { createSlice } from '@reduxjs/toolkit';

import { SessionState } from './session.type';
import { login, logout } from './session.actions';

const initialState: SessionState = {
  isLoading: false,
  error: null,
  session: Object.create(null),
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.session = payload;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) state.error = payload?.message;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.session = Object.create(null);
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) state.error = payload?.message;
    });
  },
});

export default sessionSlice.reducer;
