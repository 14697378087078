/* eslint-disable max-len */
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { __dev__ } from 'utils';
import { LoaderImpl } from './loader.impl';

const DISPLAY_NAME = 'Loader';

const ringFormation = keyframes`
  0%,
  100% {box-shadow: 0em -2.6em 0em 0em #007bff, 1.8em -1.8em 0 0em rgba(0,123,255, 0.2), 2.5em 0em 0 0em rgba(0,123,255, 0.2), 1.75em 1.75em 0 0em rgba(0,123,255, 0.2), 0em 2.5em 0 0em rgba(0,123,255, 0.2), -1.8em 1.8em 0 0em rgba(0,123,255, 0.2), -2.6em 0em 0 0em rgba(0,123,255, 0.5), -1.8em -1.8em 0 0em rgba(0,123,255, 0.7);}
  12.5% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.7), 1.8em -1.8em 0 0em #007bff, 2.5em 0em 0 0em rgba(0,123,255, 0.2), 1.75em 1.75em 0 0em rgba(0,123,255, 0.2), 0em 2.5em 0 0em rgba(0,123,255, 0.2), -1.8em 1.8em 0 0em rgba(0,123,255, 0.2), -2.6em 0em 0 0em rgba(0,123,255, 0.2), -1.8em -1.8em 0 0em rgba(0,123,255, 0.5);}
  25% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.5), 1.8em -1.8em 0 0em rgba(0,123,255, 0.7), 2.5em 0em 0 0em #007bff, 1.75em 1.75em 0 0em rgba(0,123,255, 0.2), 0em 2.5em 0 0em rgba(0,123,255, 0.2), -1.8em 1.8em 0 0em rgba(0,123,255, 0.2), -2.6em 0em 0 0em rgba(0,123,255, 0.2), -1.8em -1.8em 0 0em rgba(0,123,255, 0.2);}
  37.5% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.2), 1.8em -1.8em 0 0em rgba(0,123,255, 0.5), 2.5em 0em 0 0em rgba(0,123,255, 0.7), 1.75em 1.75em 0 0em #007bff, 0em 2.5em 0 0em rgba(0,123,255, 0.2), -1.8em 1.8em 0 0em rgba(0,123,255, 0.2), -2.6em 0em 0 0em rgba(0,123,255, 0.2), -1.8em -1.8em 0 0em rgba(0,123,255, 0.2);}
  50% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.2), 1.8em -1.8em 0 0em rgba(0,123,255, 0.2), 2.5em 0em 0 0em rgba(0,123,255, 0.5), 1.75em 1.75em 0 0em rgba(0,123,255, 0.7), 0em 2.5em 0 0em #007bff, -1.8em 1.8em 0 0em rgba(0,123,255, 0.2), -2.6em 0em 0 0em rgba(0,123,255, 0.2), -1.8em -1.8em 0 0em rgba(0,123,255, 0.2);}
  62.5% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.2), 1.8em -1.8em 0 0em rgba(0,123,255, 0.2), 2.5em 0em 0 0em rgba(0,123,255, 0.2), 1.75em 1.75em 0 0em rgba(0,123,255, 0.5), 0em 2.5em 0 0em rgba(0,123,255, 0.7), -1.8em 1.8em 0 0em #007bff, -2.6em 0em 0 0em rgba(0,123,255, 0.2), -1.8em -1.8em 0 0em rgba(0,123,255, 0.2);}
  75% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.2), 1.8em -1.8em 0 0em rgba(0,123,255, 0.2), 2.5em 0em 0 0em rgba(0,123,255, 0.2), 1.75em 1.75em 0 0em rgba(0,123,255, 0.2), 0em 2.5em 0 0em rgba(0,123,255, 0.5), -1.8em 1.8em 0 0em rgba(0,123,255, 0.7), -2.6em 0em 0 0em #007bff, -1.8em -1.8em 0 0em rgba(0,123,255, 0.2);}
  87.5% {box-shadow: 0em -2.6em 0em 0em rgba(0,123,255, 0.2), 1.8em -1.8em 0 0em rgba(0,123,255, 0.2), 2.5em 0em 0 0em rgba(0,123,255, 0.2), 1.75em 1.75em 0 0em rgba(0,123,255, 0.2), 0em 2.5em 0 0em rgba(0,123,255, 0.2), -1.8em 1.8em 0 0em rgba(0,123,255, 0.5), -2.6em 0em 0 0em rgba(0,123,255, 0.7), -1.8em -1.8em 0 0em #007bff;}
`;

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type LoaderElement = React.ElementRef<'div'>;

interface LoaderProps extends PrimitiveDivProps {
  isLoading: boolean;
  size?: string;
}

const Node = styled.div<Omit<LoaderProps, 'isLoading'>>`
  font-size: ${(props) => props.size ?? '0.5em'};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${ringFormation} 1.1s infinite ease;
  transform: translateZ(0);
  margin: 0 auto;
`;

const Loader = React.forwardRef<LoaderElement, LoaderProps>((props, forwardedRef) => {
  const { isLoading, size, ...restProps } = props;

  return (
    <LoaderImpl isLoading={isLoading}>
      <Node {...restProps} ref={forwardedRef} size={size} />
    </LoaderImpl>
  );
});

if (__dev__) {
  Loader.displayName = DISPLAY_NAME;
}

export { Loader };
