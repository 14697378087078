import {
  LabelStudioRegion,
  BodyPart,
  DamageType,
  ImageType,
  ImageSubType,
  LabellingMode
} from 'redux/annotations/annotations.type';
import { Form } from 'react-bootstrap';
import { ButtonThumbnail } from 'components/shared/image-thumbnail/image-thumbnail';
import styled from 'styled-components';
import { RelatedDamageDamageInfo, useFindRelatedDamages } from '../hooks/useFindRelatedDamages';

type RelatedDamageProps = {
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  bodyPart: BodyPart | undefined,
  damageRegion: LabelStudioRegion | null,
  showRelatedDamages: boolean,
  setShowRelatedDamages: (value: boolean) => void,
  selectedDamageId: string | null,
  setSelectedDamageId: (value: string | null) => void,
  labellingMode: LabellingMode,
};

const RelatedDamage = (props: RelatedDamageProps) => {
  const {
    bodyPart, damageRegion, photoSeriesId, imageId,
    showRelatedDamages, setShowRelatedDamages,
    selectedDamageId, setSelectedDamageId, labellingMode
  } = props;

  const findRelatedDamages = useFindRelatedDamages(photoSeriesId);
  const damages = findRelatedDamages({
    labellingMode, bodyPart, damageRegion, imageId,
  });

  if (damages.length === 0) return null;

  return (
    <>
      <Form>
        <Form.Check
          type="checkbox"
          label="This was visible on other image"
          onChange={() => {
            setShowRelatedDamages(!showRelatedDamages);
            if (!showRelatedDamages) setSelectedDamageId(null);
          }}
          checked={showRelatedDamages}
        />
      </Form>
      {
        showRelatedDamages && (
          <div className="mt-2">
            <RelatedDamageThumbnails
              selectedDamageId={selectedDamageId}
              setSelectedDamageId={setSelectedDamageId}
              damages={damages}
            />
          </div>
        )
      }
    </>
  );
};

const RelatedDamagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageThumbnailWrapper = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.selected {
    box-shadow:inset 0px 0px 0px 0.08rem #007bff;
  }
`;

const DamageTypeName = styled.span`
  font-size: 0.9rem;
  font-weight: 500;

  &.selected {
    color: #007bff;
  }
`;
const ImageTypeName = styled.span`
  font-size: 0.7rem;
`;
const ImageSubTypeName = styled.span`
  font-size: 0.7rem;
`;

type RelatedDamageThumbnailsProps = {
  selectedDamageId: string | null,
  setSelectedDamageId: (value: string | null) => void,
  damages: RelatedDamageDamageInfo[]
};

const RelatedDamageThumbnails = (props: RelatedDamageThumbnailsProps) => {
  const { selectedDamageId, setSelectedDamageId, damages } = props;

  return (
    <>
      <h6>Connect to other damage</h6>
      <RelatedDamagesWrapper>
        {
          damages.map((damage) => {
            const { id } = damage;
            const selectedClassName = selectedDamageId === id ? 'selected' : '';
            return (
              <ImageThumbnailWrapper key={id} className={selectedClassName}>
                <ButtonThumbnail
                  id={id}
                  width="small"
                  damageId={id}
                  fileName={damage.fileName}
                  filePath={damage.filePath}
                  onClick={(id: string) => {
                    if (id === selectedDamageId) setSelectedDamageId(null);
                    else setSelectedDamageId(id);
                  }}
                />
                <DamageTypeName className={selectedClassName}>{DamageType[damage.damageType]}</DamageTypeName>
                <ImageTypeName>{ImageType[damage.imageType]}</ImageTypeName>
                {
                  damage.imageSubType !== null && <ImageSubTypeName>{ImageSubType[damage.imageSubType]}</ImageSubTypeName>
                }
              </ImageThumbnailWrapper>
            );
          })
        }
      </RelatedDamagesWrapper>
    </>
  );
};

export { RelatedDamage, useFindRelatedDamages };
