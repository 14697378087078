import React from 'react';

import { useTabContext } from './tab.component';
import { __dev__, composeEvents, CustomEvent } from 'utils';

const DISPLAY_NAME = 'TabControl';

type PrimitiveButtonProps = React.ComponentPropsWithoutRef<'button'>;
type TabControlElement = React.ElementRef<'button'>;

interface TabControlProps extends PrimitiveButtonProps {
  shouldSelectOnMount?: boolean;
  // eslint-disable-next-line no-unused-vars
  onControl?: (ev: CustomEvent) => any;
}

const TabControl = React.forwardRef<TabControlElement, TabControlProps>((props, forwardedRef) => {
  const { shouldSelectOnMount, onControl, ...restProps } = props;
  const { controlId, ariaSelected, panelId, setAriaSelected } = useTabContext();

  React.useLayoutEffect(() => {
    if (shouldSelectOnMount && !ariaSelected) {
      setAriaSelected(true);
    }
  }, [ariaSelected, shouldSelectOnMount, setAriaSelected]);

  return (
    <button
      {...restProps}
      id={controlId}
      ref={forwardedRef}
      role="tab"
      type="button"
      aria-controls={panelId}
      aria-selected={ariaSelected}
      onClick={React.useCallback(
        (ev) => {
          composeEvents(() => {
            setAriaSelected((prevState) => !prevState);
          }, onControl && onControl(ev as CustomEvent));
        },
        [onControl, setAriaSelected]
      )}
    />
  );
});

if (__dev__) {
  TabControl.displayName = DISPLAY_NAME;
}

export default TabControl;
