import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatDateTime, secondsToString } from 'utils';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
  Bar, BarChart
} from 'recharts';
import { DailyLabellingStatistics } from '../statistics/statistics';
import { Dropdown, DropdownButton } from 'react-bootstrap';

/*
* General Styles
*/
const StatisticsWrapper = styled.div`
  padding: 1em;
  border: 1px solid #e8e8e8;
`;

const ChartHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7em;
`;

const ChartTitle = styled.span`
  font-weight: 500;
`;

/*
* Statistics summary
*/
const StatisticsSummaryWrapper = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;

  div {
    margin-right: 1em;
  }
`;

const StatisticsSummaryInformation = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.textColorsFor.blueLight};
  
  span:nth-child(1) {
    font-size: 2em;
  }
`;

type StatisticsSummaryProps = {
  subTitle: string,
  value: number,
  valueType: StatisticsSummaryValue
}

enum StatisticsSummaryValue {
  Seconds,
  Counter
}

const StatisticsSummary = (props: StatisticsSummaryProps) => {
  const { subTitle, value, valueType } = props;
  let txt = '';

  if (valueType === StatisticsSummaryValue.Seconds) {
    txt = secondsToString(value);
  } else if (valueType === StatisticsSummaryValue.Counter) {
    txt = value.toString();
  }

  return (
    <StatisticsWrapper>
      <StatisticsSummaryInformation>
        <span>{txt}</span>
        <span>{subTitle}</span>
      </StatisticsSummaryInformation>
    </StatisticsWrapper>
  );
};

/*
* Shared type for charts
* Used for functional component prop
*/
type GraphProperties = {
  dailyLabellingStatistics: DailyLabellingStatistics[] | null
}

/*
* Labelling timeline line chart
*/
type LabellingTimeLineChartData = {
  date: string;
  waitingForAnalysis: number;
  inReview: number;
  capturedToAnalysisCompleted: number
}

enum GraphOption {
  Min = 'Min',
  Max = 'Max',
  Mean = 'Mean',
  Median = 'Median',
  TotalPhotoSeries = 'TotalPhotoSeries'
}

const LabellingTimeLineChart = (props: GraphProperties) => {
  const [graphOption, setGraphOption] = useState<GraphOption>(GraphOption.Mean);
  const [chartData, setChartData] = useState<LabellingTimeLineChartData[]>([]);
  const { dailyLabellingStatistics } = props;

  if (graphOption === GraphOption.TotalPhotoSeries) throw Error('Not supported');

  useEffect(() => {
    if (dailyLabellingStatistics === null) return;
    const staticsValue = (statistic: any): number => Math.floor(statistic[graphOption.toLowerCase()] / 60);
    setChartData(dailyLabellingStatistics.map((statistic) => ({
      date: formatDateTime(statistic.date, true),
      waitingForAnalysis: staticsValue(statistic.waitingForAnalysisStatistic),
      inReview: staticsValue(statistic.inReviewStatistic),
      capturedToAnalysisCompleted: staticsValue(statistic.imagesCapturedToAnalysisCompletedStatistic),
    } as LabellingTimeLineChartData)));
  }, [graphOption, dailyLabellingStatistics]);

  if (dailyLabellingStatistics == null) return null;

  return (
    <>
      <StatisticsWrapper>
        <ChartHeaderWrapper>
          <ChartTitle>Labelling time in minutes</ChartTitle>
          <DropdownButton id="dropdown-item-button" title={graphOption} size="sm">
            {
              [GraphOption.Max, GraphOption.Min, GraphOption.Median, GraphOption.Mean].map((graphOption) => (
                <Dropdown.Item key={graphOption} as="button" onClick={() => setGraphOption(graphOption)}>{graphOption}</Dropdown.Item>
              ))
            }
          </DropdownButton>
        </ChartHeaderWrapper>
        <ResponsiveContainer height={270}>
          <LineChart
            data={chartData}
            margin={{
              top: 30,
              right: 5,
              left: 5
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line name="Waiting for analysis" type="monotone" dataKey="waitingForAnalysis" stroke="#00bbff" strokeWidth={3} />
            <Line name="In Review" type="monotone" dataKey="inReview" stroke="#007BFF" strokeWidth={3} />
            <Line name="Analysis completed" type="monotone" dataKey="capturedToAnalysisCompleted" stroke="#1a3aa2" strokeWidth={3} />
          </LineChart>
        </ResponsiveContainer>
      </StatisticsWrapper>
      <StatisticsSummaryWrapper>
        <StatisticsSummary
          subTitle="Total waiting for analysis"
          value={chartData.reduce((sum: number, { waitingForAnalysis }: LabellingTimeLineChartData) => sum + waitingForAnalysis * 60, 0)}
          valueType={StatisticsSummaryValue.Seconds}
        />
        <StatisticsSummary
          subTitle="Total time in review"
          value={chartData.reduce((sum: number, { inReview }: LabellingTimeLineChartData) => sum + inReview * 60, 0)}
          valueType={StatisticsSummaryValue.Seconds}
        />
        <StatisticsSummary
          subTitle="Total captured to analysed time"
          value={
            chartData.reduce((sum: number, { capturedToAnalysisCompleted }: LabellingTimeLineChartData) => sum + capturedToAnalysisCompleted * 60, 0)
          }
          valueType={StatisticsSummaryValue.Seconds}
        />
      </StatisticsSummaryWrapper>
    </>
  );
};

/*
* Analysis completed bar chart
*/
type AnalysisCompletedBarChartData = {
  date: string;
  inReview: number;
  capturedToAnalysisCompleted: number
}

const AnalysisCompletedBarChart = (props: GraphProperties) => {
  const { dailyLabellingStatistics } = props;

  if (dailyLabellingStatistics === null) return null;

  const chartData = dailyLabellingStatistics.map((statistic) => ({
    date: formatDateTime(statistic.date, true),
    inReview: statistic.inReviewStatistic.totalPhotoSeries,
    capturedToAnalysisCompleted: statistic.imagesCapturedToAnalysisCompletedStatistic.totalPhotoSeries
  } as AnalysisCompletedBarChartData));

  return (
    <>
      <StatisticsWrapper>
        <ChartHeaderWrapper>
          <ChartTitle>Analysis completed</ChartTitle>
        </ChartHeaderWrapper>
        <ResponsiveContainer height={270}>
          <BarChart
            data={chartData}
            margin={{
              top: 30,
              right: 5,
              left: 5
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar name="Analysis started" dataKey="inReview" fill="#007BFF" />
            <Bar name="Analysis completed" dataKey="capturedToAnalysisCompleted" fill="#1a3aa2" />
          </BarChart>
        </ResponsiveContainer>
      </StatisticsWrapper>
      <StatisticsSummaryWrapper>
        <StatisticsSummary
          subTitle="Total analysis started"
          value={chartData.reduce((sum: number, { inReview }: AnalysisCompletedBarChartData) => sum + inReview, 0)}
          valueType={StatisticsSummaryValue.Counter}
        />
        <StatisticsSummary
          subTitle="Total analysis completed"
          value={
            chartData.reduce((sum: number, { capturedToAnalysisCompleted }: AnalysisCompletedBarChartData) => sum + capturedToAnalysisCompleted, 0)
          }
          valueType={StatisticsSummaryValue.Counter}
        />
      </StatisticsSummaryWrapper>
    </>
  );
};

export {
  LabellingTimeLineChart,
  AnalysisCompletedBarChart
};
