import {
  LabelStudioRegion,
  BodyPart,
  Annotation,
  DamageInfo,
  DamageType,
  ImageType,
  ImageSubType,
  LabellingMode,
  ANNOTATIONS_PROPERTY_NAME
} from 'redux/annotations/annotations.type';
import { useRedux, useReduxSelector } from 'redux/store.hooks';
import { useCallback } from 'react';

interface RelatedDamageAnnotation extends Annotation {
  imageId: string
}

export interface RelatedDamageDamageInfo extends DamageInfo {
  fileName: string,
  filePath: string,
  imageType: ImageType,
  imageSubType: ImageSubType | null,
  annotationId: string,
  imageId: string,
  photoSeriesId: string
}

interface findRelatedDamagesProps {
  labellingMode: LabellingMode | null,
  bodyPart: BodyPart | undefined,
  damageRegion: LabelStudioRegion | null,
  imageId: string | undefined
}

// NOTE: This hook finds all related damages that share same body part and damage type
const useFindRelatedDamages = (photoSeriesId: string | undefined) => {
  const {
    annotations: { selectors: annotationsSelectors },
  } = useRedux();
  const annotations = useReduxSelector((state) => annotationsSelectors.selectAnnotationsByPhotoSeriesId(state, photoSeriesId as string));
  const photoSeries = useReduxSelector(annotationsSelectors.selectPagedPhotoSeries)
    .results
    .find((photoSeries) => photoSeries.photoSeriesId === photoSeriesId);

  return useCallback((props: findRelatedDamagesProps): RelatedDamageDamageInfo[] => {
    const { labellingMode, bodyPart, damageRegion, imageId } = props;

    if (photoSeries === undefined) return [];
    if (bodyPart === undefined || damageRegion === null || photoSeriesId === null || imageId === undefined || labellingMode === null) return [];

    const annotationsParamName = ANNOTATIONS_PROPERTY_NAME[labellingMode];
    const otherImageIdsWithAnnotations = Object.keys(annotations[annotationsParamName])
      .filter((key: string) => key !== imageId);

    if (otherImageIdsWithAnnotations.length === 0) return [];

    const relatedDamageAnnotations = otherImageIdsWithAnnotations
      .map((key: string) => annotations[annotationsParamName][key].map((annotation: Annotation) => {
        const relatedDamageAnnotation: RelatedDamageAnnotation = {
          ...annotation,
          imageId: key
        };
        return relatedDamageAnnotation;
      }))
      .reduce((a: RelatedDamageAnnotation[], b: RelatedDamageAnnotation[]) => a.concat(b))
      .filter((annotation: RelatedDamageAnnotation) => annotation.bodyPart === bodyPart);

    if (relatedDamageAnnotations.length === 0) return [];

    const relateDamageDamageInfos = relatedDamageAnnotations
      .map((annotation: RelatedDamageAnnotation) => {
        const { imageId, damages } = annotation;
        const image = photoSeries.images.find((image) => image.id === imageId);
        if (image === undefined) return [];

        return damages
          .filter((damage) => damage.parentId === null)
          .map((damage: DamageInfo) => {
            const relatedDamageDamageInfo: RelatedDamageDamageInfo = {
              ...damage,
              fileName: image.fileName,
              filePath: image.filePath,
              imageType: image.imageType,
              imageSubType: image.imageSubType,
              annotationId: annotation.id,
              imageId: image.id,
              photoSeriesId: photoSeries.photoSeriesId
            };
            return relatedDamageDamageInfo;
          });
      })
      .reduce((a: RelatedDamageDamageInfo[], b: RelatedDamageDamageInfo[]) => a.concat(b))
      .filter((a: RelatedDamageDamageInfo) => DamageType[damageRegion.labels[0] as keyof typeof DamageType] == a.damageType);

    if (relateDamageDamageInfos.length === 0) return [];

    return relateDamageDamageInfos;
  }, [photoSeries, annotations, photoSeriesId]);
};

export { useFindRelatedDamages };
