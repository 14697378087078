import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils';
import {
  add,
  startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { getRequest } from 'services';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { ReactComponent as Left } from 'assets/svgs/icn-left.svg';
import { ReactComponent as Right } from 'assets/svgs/icn-right.svg';
import { LabellingTimeLineChart, AnalysisCompletedBarChart } from './charts';
import { Skeleton } from './skeleton';

const StatisticsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7em;
`;

const NavigateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-weight: 500;
`;

const NavigateButton = styled(Button)`
  svg {
    width: 1em;
  }

  box-shadow: none !important;
`;

enum DateRangeOption {
  Week = 'Week',
  Month = 'Month'
}

interface DateRange {
  startDate: Date,
  endDate: Date
}

export type DailyLabellingStatistics = {
  date: Date,
  inReviewStatistic: Statistic,
  imagesCapturedToAnalysisCompletedStatistic: Statistic,
  waitingForAnalysisStatistic: Statistic
}

type Statistic = {
  min: number,
  max: number,
  mean: number,
  median: number,
  totalPhotoSeries: number
}

const calculateDateRange = (date: Date, dateRangeOption: DateRangeOption): DateRange => {
  if (dateRangeOption === DateRangeOption.Week) {
    return {
      startDate: startOfWeek(date, { weekStartsOn: 1 }),
      endDate: endOfWeek(date, { weekStartsOn: 1 })
    };
  }

  if (dateRangeOption === DateRangeOption.Month) {
    return {
      startDate: startOfMonth(date),
      endDate: endOfMonth(date)
    };
  }

  throw Error('Unknown option');
};

const Statistics = () => {
  const [dateRangeOption, setDateRangeOption] = useState<DateRangeOption>(DateRangeOption.Week);
  const [dateRange, setDateRange] = useState<DateRange>(calculateDateRange(new Date(), dateRangeOption));
  const [dailyLabellingStatistics, setDailyLabellingStatistics] = useState<DailyLabellingStatistics[] | null>(null);

  const handlePrevious = (): void => setDateRange(calculateDateRange(add(dateRange.startDate, { days: -1 }), dateRangeOption));
  const handleNext = (): void => setDateRange(calculateDateRange(add(dateRange.endDate, { days: 1 }), dateRangeOption));
  const handleSetOption = (dateRangeOption: DateRangeOption): void => {
    setDateRange(calculateDateRange(new Date(), dateRangeOption));
    setDateRangeOption(dateRangeOption);
  };

  useEffect(() => {
    setDailyLabellingStatistics(null);
    getRequest(`/dailyLabellingStatistics?startDate=${dateRange.startDate.toDateString()}&endDate=${dateRange.endDate.toDateString()}`)
      .then(({ data }) => setDailyLabellingStatistics(data));
  }, [dateRange]);

  if (dailyLabellingStatistics === null) return <Skeleton />;

  return (
    <div>
      <StatisticsHeaderWrapper>
        <Title>{formatDateTime(dateRange.startDate, true)} - {formatDateTime(dateRange.endDate, true)}</Title>
        <NavigateWrapper>
          <ButtonGroup aria-label="Navigation">
            <NavigateButton onClick={handlePrevious} variant="outline-primary" size="sm"><Left /></NavigateButton>
            <DropdownButton id="dropdown-item-button" title={dateRangeOption} size="sm" as={ButtonGroup}>
              <Dropdown.Item onClick={() => handleSetOption(DateRangeOption.Week)} as="button">Week</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSetOption(DateRangeOption.Month)} as="button">Month</Dropdown.Item>
            </DropdownButton>
            <NavigateButton onClick={handleNext} variant="outline-primary" size="sm"><Right /></NavigateButton>
          </ButtonGroup>
        </NavigateWrapper>
      </StatisticsHeaderWrapper>
      <LabellingTimeLineChart dailyLabellingStatistics={dailyLabellingStatistics} />
      <AnalysisCompletedBarChart dailyLabellingStatistics={dailyLabellingStatistics} />
    </div>
  );
};

export {
  Statistics
};
