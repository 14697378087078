import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './reportWebVitals';

import * as sW from './serviceWorker';

import { __dev__ } from 'utils';
import { store } from 'redux/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('appRoot')
);

if (__dev__) {
  reportWebVitals(console.log);
}

sW.unregister();
