const secondsToString = (seconds: number): string => {
  const minutes = Math.abs(Math.floor(seconds / 60) % 60);
  const hours = Math.abs(Math.floor(seconds / 60 / 60));

  const hoursPart = `${hours.toString().length < 2 ? 0 + hours : hours}`;
  const minutesPart = `${minutes.toString().length < 2 ? 0 + minutes : minutes}`;

  return `${hoursPart}h ${minutesPart}min`;
};

export {
  secondsToString
};
