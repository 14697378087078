import { differenceInSeconds } from 'date-fns';
import { ReactComponent as Clock } from 'assets/svgs/icn-clock.svg';
import { Badge } from 'components/shared';

type ElapsedIndicatorProps = {
  date: string
}

const elapsed = (start: Date, finish: Date): {
  elapsedMinutes: number,
  label: string
} => {
  const diffTime = differenceInSeconds(finish, start);
  if (!diffTime) {
    return {
      elapsedMinutes: 0,
      label: '00:00'
    };
  }

  const minutes = Math.abs(Math.floor(diffTime / 60) % 60);
  const hours = Math.abs(Math.floor(diffTime / 60 / 60));

  const hoursPart = `${hours.toString().length < 2 ? 0 + hours : hours}`;
  const minutesPart = `${minutes.toString().length < 2 ? 0 + minutes : minutes}`;

  return {
    elapsedMinutes: diffTime / 60,
    label: hours === 0
      ? `${minutesPart}m`
      : hours > 24 ? '>24h' : `${hoursPart}h ${minutesPart}m`
  };
};

const ElapsedIndicator = (props: ElapsedIndicatorProps) => {
  const { date } = props;
  const { elapsedMinutes, label } = elapsed(new Date(date), new Date());

  let variant: 'green' | 'yellow' | 'red';

  if (elapsedMinutes <= 10) variant = 'green';
  else if (elapsedMinutes > 10 && elapsedMinutes <= 20) variant = 'yellow';
  else variant = 'red';

  return <Badge variant={variant} txt={label} icon={<Clock />} />;
};

export { ElapsedIndicator };
