import styled from 'styled-components';

type FlexProps = {
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
};

const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'auto'};
  align-items: ${(props) => props.alignItems ?? 'auto'};
  align-content: ${(props) => props.alignContent ?? 'auto'};
`;

export { Flex };
