import styled from 'styled-components';

type ToggleProps = {
  on: boolean;
  disabled: boolean;
  onClick: () => void;
};

const ToggleWrapper = styled.div<{ toggleOn: boolean, disabled: boolean }>`
  border: 0;
  border-radius: 0.75rem;
  display: inline-flex;
  height: 1.5rem;
  width: 3rem;

  justify-content: ${(props) => props.toggleOn ? 'end' : 'start'};
  background-color: ${(props) => props.disabled || !props.toggleOn
    ? props.theme.colors.natural
    : props.theme.colors.blue.semi};

  font-size: 0.7rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: white;

  &:active,
  &:hover,
  &:focus {
    border: none;
    outline: none;
    opacity: 0.8;
    cursor: pointer;
  }

  & > .toggle__btn {
    background-color: #fff;
    border: 0;
    border-radius: 0.75rem;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0.125rem;

    &:active,
    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
  }
`;

const Toggle = ({ on, disabled, onClick }: ToggleProps) => (
  <ToggleWrapper
    toggleOn={on}
    disabled={disabled}
    onClick={onClick}
  >
    <span className="toggle__btn" />
  </ToggleWrapper>
);

export { Toggle };
