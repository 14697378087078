import { useState } from 'react';
import styled from 'styled-components';
import { BlueButton } from 'components/shared';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { DamageBodyPartSelection } from './damage-body-part-selection';
import { getSelectedAreas, isAreaDamageRegion } from 'utils';
import { LabellingMode, LabelStudioRegion } from 'redux/annotations/annotations.type';
import { useCanEditCurrentImage } from '../hooks/useCanEditCurrentImage';

const Button = styled(BlueButton)`
  min-height: 2.4rem;
  padding: 0rem 1rem;
`;

interface EditDamageProps {
  labelStudioRef: any,
  photoSeriesId: string | undefined
  labellingMode: LabellingMode,
  imageId: string | undefined,
}

const EditDamageButton = (props: EditDamageProps) => {
  const { labelStudioRef, photoSeriesId, labellingMode, imageId } = props;

  const [region, setRegion] = useState<LabelStudioRegion | null>(null);
  const canEditPhotoSeries = useCanEditCurrentImage(photoSeriesId, labellingMode);

  const handleEditDamage = () => {
    const areasInSelection = getSelectedAreas(labelStudioRef);
    if (areasInSelection.length !== 1) return;
    setRegion(areasInSelection[0]);
  };

  if (!canEditPhotoSeries()) return null;

  return (
    <>
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={
          <Tooltip>
            Select one damage region in order to edit damage
          </Tooltip>
        }
      >
        <Button onClick={handleEditDamage}>Edit damage</Button>
      </OverlayTrigger>
      {
        isAreaDamageRegion(region) && (
          <DamageBodyPartSelection
            photoSeriesId={photoSeriesId}
            labellingMode={labellingMode}
            imageId={imageId}
            region={region}
            setRegion={setRegion}
            autoSelectBodyPart={false}
          />
        )
      }
    </>
  );
};

export { EditDamageButton };
