import { useAuth } from 'hooks';
import { useRedux, useReduxSelector } from 'redux/store.hooks';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

import { formatDateTime } from 'utils';

import { AssignPhotoSeriesButton } from 'components/pages/annotations/components/assign-photo-series-button';

import { LabellingMode } from 'redux/annotations/annotations.type';

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type ImageDetailAlertsProps = {
  photoSeriesId: string | undefined
  labellingMode: LabellingMode,
}

const ImageDetailAlerts = (props: ImageDetailAlertsProps) => {
  const { photoSeriesId, labellingMode } = props;
  const { email } = useAuth();
  const {
    annotations: { selectors: annotationsSelectors },
    reviews: { selectors: labelReviewsSelectors }
  } = useRedux();

  const photoSeries = useReduxSelector((state) => annotationsSelectors.selectPhotoSeriesByPhotoSeriesId(state, photoSeriesId as string));
  const labelReview = useReduxSelector((state) => labelReviewsSelectors.selectLabelReviewByPhotoSeriesId(state, photoSeriesId as string));

  if (photoSeriesId === undefined || photoSeries === undefined) return null;

  if (labellingMode === LabellingMode.LabelReview && email === labelReview?.assignedToUser) {
    return (
      <Alert variant="warning">
        <AlertWrapper>
          You have been selected as the reviewer of this damage analysis.
          Changes you make to the labels will be saved as suggestions for the original author!
        </AlertWrapper>
      </Alert>
    );
  }

  if (labellingMode === LabellingMode.ModelTraining) {
    return (
      <Alert variant="warning">
        <AlertWrapper>
          Caution: annotations saved in training mode affect performance of model predictions!
        </AlertWrapper>
      </Alert>
    );
  }

  if (photoSeries.analysisAssignedToUser === email) return null;

  if (photoSeries.analysisAssignedToUser.length === 0) {
    return (
      <Alert variant="primary">
        <AlertWrapper>
          <span>This photo series is not assigned to anyone. To start labelling press &apos;Assign to me&apos;.</span>
          <AssignPhotoSeriesButton photoSeriesId={photoSeriesId} imageId={undefined} />
        </AlertWrapper>
      </Alert>
    );
  }

  return (
    <Alert variant="warning">
      <AlertWrapper>
        This photo series is assigned to {photoSeries.analysisAssignedToUser} at {formatDateTime(photoSeries.analysisAssignedAt)}
        <AssignPhotoSeriesButton photoSeriesId={photoSeriesId} imageId={undefined} />
      </AlertWrapper>
    </Alert>
  );
};

export { ImageDetailAlerts };
