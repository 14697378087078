import { createGlobalStyle } from 'styled-components';

import { theme } from 'utils';

const GlobalStyles = createGlobalStyle<{ theme: typeof theme }>`

    :root {
        --toastify-font-family: ${(props) => props.theme.font.fontFamily};
    }

    *,
    *::before,
    *::after {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    };

    html,body{
        background-color: #F3F3F3;
        font-family: ${(props) => props.theme.font.fontFamily};
        font-weight: 400;
        line-height: ${(props) => props.theme.space.lineHeights.copy};
        scroll-behavior: smooth;
        text-rendering: optimizeLegibility;
        
        .portal{
        position: relative;
        display: block;
    }
    };

    html {

    body {
    min-height: 100%;
    }

    button, 
    [role="button"],
    [type="submit"],
    [type="reset"]{
        cursor: pointer;
    };

    li{
        list-style: none;
    }

    a{
        text-decoration: none;
    }

    textarea,
    select:focus,
    input[type='tel'],
    input[type='url'],
    input[type='week'],
    input[type='text'],
    input[type='time'],
    input[type='date'],
    input[type='email'],
    input[type='month'],
    input[type='color'],
    input[type='number'],
    input[type='search'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local']{
        font-size: 16px !important;
    }

    .skip-content {
        position: absolute;
        left: 0;
        top: -45px;
        z-index: 100;
        transition: top 0.5s ease-out;
        
        &:focus {
        position: absolute;
        left: 0;
        top:0;
        transition: top 0.5s ease-in;
        };
    };

    kbd {
        background-color: #212529 !important;
        color: #fff !important;
    }

    .modal-open {
        overflow: scroll !important;
        padding-right: 0px !important;
    }
}
`;

export { GlobalStyles };
