import { useState } from 'react';
import { Form } from 'react-bootstrap';

type SearchPhraseProps = {
  searchPhrase: string,
  setSearchPhrase: (value: string) => void,
  className: string
};

const SearchPhrase = (props: SearchPhraseProps) => {
  const { searchPhrase, setSearchPhrase, className } = props;
  const [value, setValue] = useState<string>(searchPhrase);

  return (
    <Form.Control
      className={className}
      type="text"
      placeholder="Search photo series"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          setSearchPhrase(value);
        }
      }}
    />
  );
};

export { SearchPhrase };
