import { PagedQuery, SortDirection, SortColumn } from 'redux/annotations/annotations.type';
import { Form } from 'react-bootstrap';

export type SortItem = {
  label: string,
  sortColumn: SortColumn,
  sortDirection: SortDirection
}

type SortSelectionProps = {
  sortItems: SortItem[],
  pagedQuery: PagedQuery,
  setPagedQuery: (value: PagedQuery) => void
};

const SortSelection = (props: SortSelectionProps) => {
  const { sortItems, pagedQuery, setPagedQuery } = props;

  const pagedQueryValue = (pagedQuery: PagedQuery): string => sortItemValue({
    sortColumn: pagedQuery.sortColumn,
    sortDirection: pagedQuery.sortDirection
  } as SortItem);
  const sortItemValue = (sortItem: SortItem): string => `${sortItem.sortColumn}#${sortItem.sortDirection}`;

  const handleChange = (value: string) => {
    if (value.length === 0) return;
    const valueSplit = value.split('#');
    const sortColumn = valueSplit[0] as string as SortColumn;
    const sortDirection = parseInt(valueSplit[1], 10) as SortDirection;
    setPagedQuery({
      ...pagedQuery,
      sortColumn,
      sortDirection
    });
  };

  if (sortItems.length === 0) return null;

  return (
    <Form.Select
      aria-label="Sort selection"
      onChange={(event: any) => handleChange(event.target.value)}
      value={pagedQueryValue(pagedQuery)}
    >
      <option value="">Sort by</option>
      {
        sortItems.map((sortItem) => (
          <option
            key={sortItemValue(sortItem)}
            value={sortItemValue(sortItem)}
          >
            { sortItem.label }
          </option>
        ))
      }
    </Form.Select>
  );
};

export { SortSelection };
