import React from 'react';

import { VisuallyHidden } from 'components/shared';

interface AccessibleIconProps extends React.ComponentPropsWithRef<typeof VisuallyHidden> {
  label: React.ReactNode;
  children: React.ReactElement;
}

function AccessibleIcon(props: AccessibleIconProps) {
  const { ref, label, children, ...restProps } = props;
  const child = React.Children.only(children);

  return (
    <>
      {React.cloneElement(child, {
        'aria-hidden': true,
        focusable: false,
      })}
      <VisuallyHidden {...restProps} ref={ref}>
        {label}
      </VisuallyHidden>
    </>
  );
}

export { AccessibleIcon };
