import React from 'react';
import styled from 'styled-components';

import { TYPE_VARIANTS, __dev__ } from 'utils';

const DISPLAY_NAME = 'MessageUpdate';

type Type = 'success' | 'danger' | 'warning';

const Message = styled.p`
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  padding: 0.3125rem 0.9375rem;
  font-weight: normal;
  font-size: 0.75rem;

  &.message--success {
    background-color: ${TYPE_VARIANTS.success.bg};
    color: ${TYPE_VARIANTS.success.color};
    border-color: ${TYPE_VARIANTS.success.dashColor};
  }

  &.message--danger {
    background-color: ${TYPE_VARIANTS.danger.bg};
    color: ${TYPE_VARIANTS.danger.color};
    border-color: ${TYPE_VARIANTS.danger.dashColor};
  }

  &.message--warning {
    background-color: ${TYPE_VARIANTS.warning.bg};
    color: ${TYPE_VARIANTS.warning.color};
    border-color: ${TYPE_VARIANTS.warning.dashColor};
  }
`;

type PrimitiveParaProps = React.ComponentPropsWithoutRef<'p'>;
type ElementMessageUpdate = React.ElementRef<'p'>;

interface MessageUpdateProps extends PrimitiveParaProps {
  children: React.ReactNode;
  type?: Type;
}

const MessageUpdate = React.forwardRef<ElementMessageUpdate, MessageUpdateProps>(({ children, type, ...restProps }, forwardedRef) => (
  <Message {...restProps} ref={forwardedRef} className={`message--${type ?? 'success'}`}>
    {children}
  </Message>
));

if (__dev__) {
  MessageUpdate.displayName = DISPLAY_NAME;
}

export { MessageUpdate };
