import { useEffect, useState, useCallback } from 'react';
import { useRedux, useReduxSelector } from 'redux/store.hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { useParseLabellingModeFromParams } from './useParseLabellingModeFromParams';
import { ImageType, Image } from 'redux/annotations/annotations.type';

const useImageNavigation = () => {
  const [hasImages, setHasImages] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  const { photoSeriesId, imageId } = useParams();
  const labellingModeFromParams = useParseLabellingModeFromParams();
  const navigate = useNavigate();

  const {
    annotations: { selectors: annotationsSelectors },
  } = useRedux();

  const currentImage =
    useReduxSelector((state) => annotationsSelectors.selectImageByImageIdAndPhotoSeriesId(state, photoSeriesId as string, imageId as string));

  const allImages = useReduxSelector((state) => annotationsSelectors.selectImagesByPhotoSeriesId(state, photoSeriesId as string));
  const images = currentImage?.imageType == ImageType.Additional
    ? allImages?.filter((image: Image) => image.imageType === ImageType.Additional)
    : allImages?.filter((image: Image) => image.imageType !== ImageType.Additional);

  const isFirstImage = useCallback(() => imageIndex === 0, [imageIndex]);
  const isLastImage = useCallback(() => images && imageIndex === (images.length - 1), [imageIndex, images]);

  // Detect right image index in array
  useEffect(() => {
    if (!images) return;

    const imageIndex = images?.findIndex((image) => image.id === imageId);
    setImageIndex(imageIndex);
    setHasImages(true);
  }, [imageId, images]);

  const navigateToHome = () => navigate(PATHS.home);

  const navigateToImage = useCallback((imageIndex: number) => {
    const nextImageId = images[imageIndex].id;
    navigate(`/labelling/photoSeries/${photoSeriesId}/image/${nextImageId}/mode/${labellingModeFromParams}`);
  }, [images, navigate, photoSeriesId, labellingModeFromParams]);

  const navigateNext = useCallback(() => {
    if (isLastImage()) return;
    navigateToImage(imageIndex + 1);
  }, [navigateToImage, imageIndex, isLastImage]);

  const navigatePrev = useCallback(() => {
    if (isFirstImage()) return;
    navigateToImage(imageIndex - 1);
  }, [navigateToImage, imageIndex, isFirstImage]);

  return {
    navigateNext,
    navigatePrev,
    hasImages,
    isFirstImage,
    isLastImage,
    imageIndex,
    navigateToHome
  };
};

export { useImageNavigation };
