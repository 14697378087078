import React from 'react';
import { Formik, Form } from 'formik';
import styled, { css } from 'styled-components';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import * as Yup from 'yup';

import { PATHS } from 'routes';
import { IconLogo } from 'assets';
import { Main, Footer } from '../../layouts';
import { Input, Error } from 'components/shared';
import { useTitle, useUniqueIds, useAuth } from 'hooks';
import { LoginFormValues } from 'redux/session/session.type';
import { useRedux, useReduxDispatch, useReduxSelector } from 'redux/store.hooks';

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & main {
    width: 100%;
    margin: 0 auto;

    & form {
      width: 100%;
      max-width: 23.0625rem;
      margin: 0 auto;

      & > * {
        display: block;
        width: 90%;
        margin: 0.5rem auto;
      }

      & label {
        color: #222222;
        font-size: 0.875rem;
        margin-bottom: -5px;
      }

      & svg {
        width: 12.875rem;
      }

      & input:not([type='submit']) {
        height: 1.8125rem;
        padding: 1.125rem 0.625rem;
        border: 0.0625rem solid #e2e2e2;
        border-radius: 0.3125rem;
        transition: all 0.5s ease-out;

        &:focus {
          outline: none;
          border-color: #2a91ff;
          box-shadow: 0 0 0 0.2rem rgba(118, 182, 252, 0.4);
        }
      }
    }
  }

  & footer {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
  }
`;

const InputSubmit = styled.input`
  padding: 0.6875rem;
  background: #007bff;
  border: 0;
  appearance: none;
  border-radius: 0.3125rem;
  color: #fff;
  height: 2.5625rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: #0069d9;
  }
`;

const ForgotPassword = styled(Link)`
  color: #007bff;
  font-size: 0.875rem;
  text-align: center;
  text-decoration-line: underline;
`;

const FooterText = styled.div`
  color: #000;
`;

const ErrorFocusCss = css`
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.4) !important;
`;

const Fieldset = styled.fieldset<{ isEmailError?: boolean; isPasswordError?: boolean }>`
  border: 0;

  & > * {
    display: block;
    width: 100%;
    margin: 0.5rem auto;
  }

  & input {
    appearance: none;
    &[type='email'] {
      &:focus {
        ${(props) => props.isEmailError && ErrorFocusCss};
      }
    }

    &[type='password'] {
      &:focus {
        ${(props) => props.isPasswordError && ErrorFocusCss}
      }
    }
  }
`;

const initialFormValues: LoginFormValues = {
  email: '',
  password: '',
};

function Login() {
  useTitle('Login');
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [emailId, passwordId] = useUniqueIds(2);

  const {
    session: { actions, selectors },
  } = useRedux();
  const dispatch = useReduxDispatch();
  const error = useReduxSelector(selectors.selectSessionError);
  const isLoading = useReduxSelector(selectors.selectSessionIsLoading);

  if (isAuthenticated) {
    return <Navigate to={PATHS.home} replace />;
  }

  return (
    <Container>
      <Main>
        <Formik
          initialValues={initialFormValues}
          validationSchema={Yup.object().shape({
            email: Yup.string().required('Email is required').email('Please enter a correct email'),
            password: Yup.string().required('Password is required'),
          })}
          onSubmit={({ email, password }) => {
            dispatch(
              actions.login({
                loginData: { email, password },
                onSuccess() {
                  navigate(PATHS.home, { replace: true });
                },
              })
            );
          }}
          validateOnChange={false}
        >
          {({ values, errors }) => (
            <Form>
              <IconLogo />
              <Fieldset isEmailError={!!errors.email} isPasswordError={!!errors.password}>
                <Input
                  id={emailId}
                  type="email"
                  name="email"
                  label="E-mail"
                  inputMode="email"
                  placeholder="Insert e-mail"
                  value={values.email}
                  error={errors.email}
                  required
                  autoComplete="on"
                />
                <Input
                  id={passwordId}
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Insert password"
                  value={values.password}
                  error={errors.password}
                  required
                />
              </Fieldset>
              <InputSubmit type="submit" disabled={isLoading} value="Login" />
              <ForgotPassword to={PATHS.forgotPassword}>Forgot your password?</ForgotPassword>
              <Error shown={!!error} style={{ textAlign: 'center' }}>
                {error}
              </Error>
            </Form>
          )}
        </Formik>
      </Main>
      <Footer>
        <FooterText>All rights reserved {new Date().getFullYear()}</FooterText>
      </Footer>
    </Container>
  );
}

export default Login;
