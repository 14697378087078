import { useMemo } from 'react';
import styled from 'styled-components';

import { Flex } from 'components/layouts';
import { AnchorThumbnail } from 'components/shared/image-thumbnail/image-thumbnail';
import { LabellingMode } from '../../../../../../redux/annotations/annotations.type';

const Table = styled.div<{ highlight: boolean }>`
  padding: 1rem;
  ${(props) => props.highlight ? 'background-color: #e1ecf9' : ''}
`;

const Row1 = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
  font-weight: ${(props) => props.theme.font.fontWeights[4]};
  padding: 0.25rem 0;
  align-items: center;
`;

const Row1Col1 = styled.div`
  display: flex;
  align-items: center;

  & > span:nth-child(1) {
    margin-right: 0.5rem;
  }

  & > span:nth-child(2) {
    margin-right: 0.5rem;
  }
`;

const Row2 = styled.div`
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
`;

const ImageThumbnailWrapper = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.75rem;
`;

const Row3 = styled.div`

`;

type PhotoSeriesProps = {
  photoSeriesId: string;
  vin: string;
  registrationNo: string;
  suggestionsSubmittedAt: string | null;
  suggestionsConfirmedAt: string | null;
  images: {
    id: string;
    fileName: string;
    filePath: string;
  }[];
};

function PhotoSeries(props: PhotoSeriesProps) {
  const { photoSeriesId, vin, images, registrationNo, suggestionsSubmittedAt, suggestionsConfirmedAt } = props;

  const renderImages = useMemo(() => {
    const node = (
      <>
        {images.map((image) => {
          const { id, filePath, fileName } = image;

          return (
            <ImageThumbnailWrapper key={id}>
              <AnchorThumbnail
                filePath={filePath}
                fileName={fileName}
                width="small"
                href={
                  !suggestionsSubmittedAt
                    ? `/labelling/photoSeries/${photoSeriesId}/image/${id}/mode/${LabellingMode.LabelReview}`
                    : `/reviewDetail/photoSeries/${photoSeriesId}/image/${id}`
                }
                target="_blank"
              />
            </ImageThumbnailWrapper>
          );
        })}
      </>
    );

    return node;
  }, [images, photoSeriesId, suggestionsSubmittedAt]);

  return (
    <Table highlight={suggestionsConfirmedAt !== null}>
      <Row1>
        <Row1Col1>
          <span className="vin">{vin}</span>
          <span className="reg-nr">{registrationNo}</span>
        </Row1Col1>
      </Row1>
      <Row2>{renderImages}</Row2>
      {
        suggestionsConfirmedAt !== null && (
          <Row3><b>Confirmed At:</b> {new Date(suggestionsConfirmedAt).toLocaleString()}</Row3>
        )
      }
    </Table>
  );
}

export { PhotoSeries };
