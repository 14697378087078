import { createAsyncThunk } from '@reduxjs/toolkit';

import { GenericAjaxType } from 'redux/store';
import { postRequest, storeUserData, deleteUserData } from 'services';
import { getErrorMsg, onAjaxEnhancer } from '../store.util';
import type { LoginData, Session, SessionError } from './session.type';

const TYPE_PREFIX = '@drivex/session';

const login = createAsyncThunk<Session, LoginData, { rejectValue: SessionError }>(`${TYPE_PREFIX}/login`, async (payload, thunkApi) => {
  try {
    const response = await postRequest('/session', payload.loginData);

    storeUserData(response.data);

    onAjaxEnhancer(payload, ['onSuccess', 'onFinish']);
    return response.data;
  } catch (error: any) {
    onAjaxEnhancer(payload, ['onError', 'onFinish']);
    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
});

const logout = createAsyncThunk<void, GenericAjaxType, { rejectValue: SessionError }>(`${TYPE_PREFIX}/logout`, async (payload, thunkApi) => {
  deleteUserData();
  try {
    const response = await postRequest('/session/logout', {});
    onAjaxEnhancer(payload, ['onSuccess', 'onFinish']);
    return response.data as void;
  } catch (error: any) {
    onAjaxEnhancer(payload, ['onError', 'onFinish']);
    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
});

export { login, logout };
