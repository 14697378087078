import React, { useState, useEffect, useLayoutEffect, useCallback, MutableRefObject } from 'react';
import styled from 'styled-components';
import LabelStudio from '@heartexlabs/label-studio';
import '@heartexlabs/label-studio/build/static/css/main.css';

import { DamageBodyPartSelection } from './damage-body-part-selection';
import { LabelStudioAnnotation, LabelStudioRegion, LabellingMode } from 'redux/annotations/annotations.type';
import { ImageFile } from 'redux/images/images.type';
import { LabellingOption } from './labelling-option-selection';

import { isAreaDamageRegion } from 'utils';
import { useCanEditCurrentImage } from '../hooks/useCanEditCurrentImage';
import { DamageLabelShape } from '../types/damage-label-shape';

const Container = styled.div`
  min-height: 88vh;
`;

const LabelStudioContainer = styled.div`
  width: 100%;

  & .ant-result {
    display: none;
  }
`;

const useShowLabels = (labelStudioRef: any) => useCallback((labellingOption: LabellingOption) => {
  if (!labelStudioRef || !labelStudioRef.current) return;
  const annotation = labelStudioRef.current.store.annotationStore.selected;
  const regions = annotation.regionStore.regions;

  regions.forEach((region: LabelStudioRegion) => {
    const isRegion = regionHasLabel(region, labellingOption);
    if (!(isRegion && !region.hidden)) region.toggleHidden();
  });
}, [labelStudioRef]);

const regionHasLabel = (region: LabelStudioRegion, labellingOption: LabellingOption) => {
  if (!region || !region.tag) return false;

  const name = region.tag.name;

  if (labellingOption === LabellingOption.All ||
     (labellingOption === LabellingOption.Damage && name === 'damages') ||
     (labellingOption === LabellingOption.BodyPart && name === 'bodyParts')) {
    return true;
  }

  return false;
};

const getDamageLabels = (damageLabelShape: DamageLabelShape, damageLabels: string): string => {
  switch (damageLabelShape) {
    case DamageLabelShape.Rectangle:
      return `
        <RectangleLabels name="damages" toName="img">
          ${damageLabels}
        </RectangleLabels>
      `;
    case DamageLabelShape.Polygon:
      return `
        <PolygonLabels name="damages" toName="img" strokeWidth="1">
          ${damageLabels}
        </PolygonLabels>
      `;
    default:
      throw Error('Did not found suitable option to create LabelStudio damage labels');
  }
};

interface ImageDetailBodyProps {
  setLabelStudioRef: (value: MutableRefObject<any> | null) => void,
  imageIndex: number,
  damageLabels: string,
  bodyPartLabels: string,
  damageAnnotations: LabelStudioAnnotation[] | null,
  bodyPartAnnotations: LabelStudioAnnotation[] | null,
  imageFile: ImageFile,
  photoSeriesId: string | undefined,
  imageId: string | undefined,
  labellingOption: LabellingOption,
  labellingMode: LabellingMode,
  damageLabelShape: DamageLabelShape,
  subMaskLabels: string,
  subMaskAnnotations: LabelStudioAnnotation[] | null
}

const ImageDetailBody = (props: ImageDetailBodyProps) => {
  const {
    setLabelStudioRef, imageIndex, damageLabels, bodyPartLabels,
    damageAnnotations, bodyPartAnnotations, imageFile, photoSeriesId, imageId,
    labellingOption, labellingMode, damageLabelShape, subMaskAnnotations, subMaskLabels
  } = props;

  const [createdEntity, setCreatedEntity] = useState<LabelStudioRegion | null>(null);
  const [labelStudioIsReady, setLabelStudioIsReady] = useState<boolean>(true);

  const labelStudioRef = React.useRef<any>();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const showLabels = useShowLabels(labelStudioRef);
  const canEditPhotoSeries = useCanEditCurrentImage(photoSeriesId, labellingMode);

  const remapCustomProperties = useCallback(() => {
    const annotationStore = labelStudioRef.current.store.annotationStore;
    const areas: LabelStudioRegion[] = Object.values(annotationStore.selected.areas.toPOJO());

    damageAnnotations?.forEach((damage) => {
      const area = areas.find((area) => area.id.split('#')[0] === damage.id);
      if (area) {
        area.repairDecision = damage.repairDecision;
        area.parentDamageId = damage.parentDamageId;
      }
    });
  }, [labelStudioRef, damageAnnotations]);

  useEffect(() => setLabelStudioRef(labelStudioRef), [labelStudioRef, setLabelStudioRef]);
  useEffect(() => {
    const canEdit = canEditPhotoSeries();
    if (!canEdit && createdEntity) createdEntity.deleteRegion();
  }, [createdEntity, canEditPhotoSeries]);

  useEffect(() => {
    if (!imageFile) return;
    if (!labelStudioRef.current || !labelStudioRef.current.store) return;
    if (!labelStudioIsReady) return;

    let task: any = {
      annotations: [
        {
          result: [],
        }
      ],
      predictions: [],
      id: imageIndex,
      data: { image: imageFile.localUrl },
    };

    const canEdit = canEditPhotoSeries();

    if (bodyPartAnnotations) {
      task.annotations[0].result = [...task.annotations[0].result, ...bodyPartAnnotations]
        .map((annotation) => ({ ...annotation, readonly: !canEdit }));
    }

    if (damageAnnotations) {
      task.annotations[0].result = [...task.annotations[0].result, ...damageAnnotations]
        .map((annotation) => ({ ...annotation, readonly: !canEdit }));
    }

    if (subMaskAnnotations) {
      task.annotations[0].result = [...task.annotations[0].result, ...subMaskAnnotations]
        .map((annotation) => ({ ...annotation, readonly: true }));
    }

    labelStudioRef.current.store.assignTask(task);
    labelStudioRef.current.store.initializeStore(task);

    showLabels(labellingOption);
    remapCustomProperties();
  }, [
    imageFile,
    damageAnnotations,
    bodyPartAnnotations,
    imageIndex,
    labelStudioRef,
    labelStudioIsReady,
    labellingOption,
    showLabels,
    labellingMode,
    canEditPhotoSeries,
    remapCustomProperties,
    subMaskAnnotations
  ]);

  useLayoutEffect(() => {
    if (labelStudioRef.current) return;

    labelStudioRef.current = new LabelStudio(rootRef.current, {
      config: `
        <View>
          <Image
            name="img"
            value="$image"
            width="100%"
            maxWidth="100%"
            brightnessControl="true"
            contrastControl="true"
          ></Image>
          <Header value="Damages" />
            ${getDamageLabels(damageLabelShape, damageLabels)}
          <Header value="Body parts" />
          <PolygonLabels name="bodyParts" toName="img" strokeWidth="1">
            ${bodyPartLabels}
          </PolygonLabels>
          <Header value="Sub masks" />
          <PolygonLabels name="subMasks" toName="img" strokeWidth="1">
            ${subMaskLabels}
          </PolygonLabels>
        </View>
      `,

      interfaces: [
        'panel',
        'controls',
        'topbar',
        'side-column',
        'edit-history',
      ],

      task: {
        annotation: [],
        predictions: []
      },

      onEntityCreate: (region: LabelStudioRegion) => {
        labelStudioRef.current.dirty = true;
        setCreatedEntity(region);
      },
      onLabelStudioLoad: () => setLabelStudioIsReady(true),
      onEntityDelete: () => {
        labelStudioRef.current.dirty = true;
      }
    });
  }, [
    rootRef,
    bodyPartLabels,
    damageLabels,
    labelStudioRef,
    setLabelStudioIsReady,
    setCreatedEntity,
    labellingMode,
    damageLabelShape,
    subMaskLabels
  ]);

  return (
    <div className="border">
      <Container {...props}>
        <LabelStudioContainer id="label-studio" ref={rootRef} />
      </Container>
      {
        isAreaDamageRegion(createdEntity) && (
          <DamageBodyPartSelection
            photoSeriesId={photoSeriesId}
            imageId={imageId}
            region={createdEntity}
            setRegion={setCreatedEntity}
            labellingMode={labellingMode}
            autoSelectBodyPart
          />
        )
      }
    </div>
  );
};

export { ImageDetailBody };
