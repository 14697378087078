import { createSelector } from 'reselect';

import type { RootState } from '../store';

function selectSessionState(state: RootState) {
  return state.session;
}

const selectSessionIsLoading = createSelector(selectSessionState, (session) => session.isLoading);
const selectSessionError = createSelector(selectSessionState, (session) => session.error);

export { selectSessionIsLoading, selectSessionError };
