import { Form } from 'react-bootstrap';

type NotConfirmedForRetrainingProps = {
  notConfirmedForRetraining: boolean,
  setNotConfirmedForRetraining: (value: boolean) => void
}

const NotConfirmedForRetraining = (props: NotConfirmedForRetrainingProps) => {
  const { notConfirmedForRetraining, setNotConfirmedForRetraining } = props;
  return (
    <Form>
      <Form.Check
        type="checkbox"
        label="Not confirmed for retraining"
        onChange={(event: any) => setNotConfirmedForRetraining(event.target.checked)}
        checked={notConfirmedForRetraining}
      />
    </Form>
  );
};

export { NotConfirmedForRetraining };
