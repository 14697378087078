import { useState, useEffect } from 'react';
import { ChangeType, Suggestion, LabelReview } from '../../../../../../redux/reviews/reviews.type';
import { BodyPart, DamageType } from '../../../../../../redux/annotations/annotations.type';

export type LabellingReviewSuggestion = {
  name: string;
  type: SuggestionType;
  active: boolean;
  imageId: string;
} & Suggestion;

enum SuggestionType {
  Damage,
  BodyPart
}

type SuggestionColor = {
  stroke: string;
  fill: string;
};

const getSuggestionColor = (highLight: boolean, changeType: ChangeType): SuggestionColor => {
  if (!highLight) {
    return { fill: '#D3D3D3', stroke: 'gray' };
  }

  switch (changeType) {
    case ChangeType.Modified:
      return { fill: '#ffffe0', stroke: 'yellow' };
    case ChangeType.Created:
      return { fill: '#90EE90', stroke: 'green' };
    case ChangeType.Deleted:
      return { fill: '#ffcccb', stroke: 'red' };
    case ChangeType.Unchanged:
      throw Error('Not supported!');
    default:
      throw Error('Not implemented!');
  }
};

const useSuggestions = (labelReview: LabelReview | undefined, imageId: string) => {
  const [suggestions, setSuggestions] = useState<LabellingReviewSuggestion[]>([]);

  useEffect(() => {
    if (!labelReview) return;
    const labellingReviewSuggestions: LabellingReviewSuggestion[] = [];

    labelReview.suggestedAnnotations
      .filter((sa) => sa.imageId === imageId)
      .forEach((sa) => {
        if (sa.changeType !== ChangeType.Unchanged) {
          const isSuggestedAnnotationActive = !labellingReviewSuggestions.some((s) => s.active === true)
            && suggestionNotReviewed(sa);

          labellingReviewSuggestions.push({
            ...sa,
            name: BodyPart[sa.bodyPart],
            type: SuggestionType.BodyPart,
            active: isSuggestedAnnotationActive,
            imageId
          });
        }

        sa.suggestedDamages.filter((sd) => sd.changeType !== ChangeType.Unchanged).forEach((sd) => {
          const isSuggestedDamageActive = !labellingReviewSuggestions.some((s) => s.active === true)
            && suggestionNotReviewed(sd);

          labellingReviewSuggestions.push({
            ...sd,
            name: DamageType[sd.damageType],
            type: SuggestionType.Damage,
            active: isSuggestedDamageActive,
            imageId
          });
        });
      });

    setSuggestions(labellingReviewSuggestions);
  }, [labelReview, imageId]);

  const suggestionNotReviewed = (suggestion: Suggestion) => suggestion.suggestionAccepted === null
    || suggestion.suggestionAccepted === undefined;

  return {
    suggestions,
    suggestionsReviewed: !suggestions.some((suggestion) => suggestionNotReviewed(suggestion))
  };
};

export { useSuggestions, getSuggestionColor };
