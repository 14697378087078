import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Fallback } from './components/fallback';
import { ErrorHandler } from './components/error-handler';
import { ErrorBoundary } from './components/error-boundary';
import { InternetNotifier } from './components/internet-notifier';

type RouterProps = {
  children: React.ReactNode;
};

function Router(props: RouterProps) {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ErrorHandler>
          <InternetNotifier />
          <Routes>
            {props.children}
            <Route path="*" element={<Fallback />} />
          </Routes>
        </ErrorHandler>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export { Router };
