import React from 'react';
import styled from 'styled-components';

import { __dev__ } from 'utils';

const DISPLAY_NAME = 'Error';

const Message = styled.p`
  color: #dc3545;
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.font.fontWeights[1]};
`;

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'p'>;
type ElementError = React.ElementRef<'p'>;

interface ErrorProps extends PrimitiveDivProps {
  children: React.ReactNode;
  shown: boolean;
}

const Error = React.forwardRef<ElementError, ErrorProps>(({ shown, children, ...restProps }, forwardedRef) => (
  <>
    {shown && (
      <Message {...restProps} ref={forwardedRef} aria-atomic="true">
        {children}
      </Message>
    )}
  </>
));

if (__dev__) {
  Error.displayName = DISPLAY_NAME;
}

export { Error };
