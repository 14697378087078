import React, { useRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'routes';
import { useAuth } from 'hooks';
import { AccessibleIcon } from 'components/shared';
import { useRedux, useReduxDispatch } from 'redux/store.hooks';
import { IconArrowDown, IconArrowUp, IconLock, IconLogout } from 'assets';

const Container = styled.div`
  position: relative;

  & .user-action-comp__row-1 {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 12.5rem;

    & > address {
      padding-right: 0.3125rem;
      font-style: normal !important;
      margin-bottom: 0;
    }
  }
`;

const ActionList = styled.div`
  background-color: #fff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #333;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-width: 12.5rem;

  & > [role='button'] {
    padding: 0.5rem 1rem;
    border-bottom: 0.0625rem solid #d3d3d3;

    &:hover {
      border-radius: 0.25rem;
      background-color: #f3f8ff;
    }
  }
`;

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ElementUserAction = React.ElementRef<'div'>;

interface UserActionProps extends PrimitiveDivProps {
  fill?: string;
}

const UserAction = React.forwardRef<ElementUserAction, UserActionProps>(({ fill, ...restProps }, forwardedRef) => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const [isDropped, setIsDropped] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    session: { actions },
  } = useRedux();
  const dispatch = useReduxDispatch();

  const onMouseDownEventHandler = useCallback(
    (ev: React.SyntheticEvent | Event) => {
      if (!isDropped) return;

      if (ref.current && !ref.current.contains(ev.target as Node)) {
        setIsDropped(false);
      }
    },
    [isDropped]
  );

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDownEventHandler);

    return () => {
      document.removeEventListener('mousedown', onMouseDownEventHandler);
    };
  }, [onMouseDownEventHandler]);

  function onLogout() {
    dispatch(
      actions.logout({
        onSuccess() {
          navigate(PATHS.login, { replace: true });
        },
      })
    );
  }

  return (
    <Container
      {...restProps}
      ref={(element) => {
        if (!element) return ref;

        (ref as React.MutableRefObject<HTMLDivElement>).current = element;

        if (typeof forwardedRef === 'function') {
          forwardedRef(element);
        } else if (forwardedRef?.current) {
          forwardedRef!.current = element;
        }
      }}
      className="user-action-comp"
    >
      <div
        role="button"
        tabIndex={0}
        className="user-action-comp__row-1"
        onClick={() => {
          setIsDropped((prevState) => !prevState);
        }}
      >
        <address title={`Logged in with ${email}`}>{email}</address>
        <AccessibleIcon label={`Click to ${isDropped ? 'close list' : 'open list'}`}>
          {isDropped ? <IconArrowUp fill={fill} /> : <IconArrowDown fill={fill} />}
        </AccessibleIcon>
      </div>
      {isDropped && (
        <ActionList className="user-action-comp__row-2">
          <div role="button" tabIndex={0}>
            <IconLock /> Change password
          </div>
          <div role="button" tabIndex={0} onClick={onLogout}>
            <IconLogout /> Log out
          </div>
        </ActionList>
      )}
    </Container>
  );
});

export { UserAction };
