import type { GenericAjaxType } from './store';

const SERVER_BAD_REQUEST_MSG = 'Sorry, there was an error trying to connect to the server. Try again.';

function getErrorMsg(error: any) {
  return error.status === 500 ? SERVER_BAD_REQUEST_MSG : error.data.error;
}

function onAjaxEnhancer(cb: GenericAjaxType, cbKeys: ['onSuccess' | 'onError', 'onFinish']) {
  cbKeys.forEach((key) => {
    if (cb[key]) cb[key]!();
  });
}

export { getErrorMsg, onAjaxEnhancer };
