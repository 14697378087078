import { Alert, Button, Modal } from 'react-bootstrap';
import { ImageFile } from 'redux/images/images.type';
import { getLabelStudioAreas } from 'utils';

type UnsavedChangesProps = {
  imageFile: ImageFile,
  labelStudioRef: any,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void, onSave: () => void, labelStudioRef: any
  ) => void,
  handleSaveAndContinue: () => void,
  handleContinue: () => void,
  showUnsavedChangesPopup: boolean,
  setShowUnsavedChangesPopup: (value: boolean) => void
}

const UnsavedChanges = (props: UnsavedChangesProps) => {
  const {
    labelStudioRef, handleSubmitAnnotation, handleContinue,
    handleSaveAndContinue, imageFile, showUnsavedChangesPopup, setShowUnsavedChangesPopup
  } = props;

  if (!showUnsavedChangesPopup) return null;

  return (
    <Modal
      show
      onHide={() => setShowUnsavedChangesPopup(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Unsaved changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          You have unsaved changes. Do you wish to save and continue?
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            const areas = getLabelStudioAreas(labelStudioRef);
            handleSubmitAnnotation(areas, imageFile, false, () => {}, () => handleSaveAndContinue(), labelStudioRef);
          }}
          data-testid="unsavedChangesSaveAndContinueBtn"
        >
          Save and continue
        </Button>
        <Button
          variant="primary"
          onClick={() => handleContinue()}
          data-testid="unsavedChangesContinueWithourSavingBtn"
        >
          Continue without saving
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnsavedChanges;
