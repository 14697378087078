import React from 'react';

import { __dev__ } from 'utils';
import { useTabContext } from './tab.component';

const DISPLAY_NAME = 'TabPanel';

type PrimitiveDivProps = React.ComponentPropsWithoutRef<any>;
type TabPanelElement = React.ElementRef<any>;

interface TabPanelProps extends PrimitiveDivProps {
  children: React.ReactElement;
}

const TabPanel = React.forwardRef<TabPanelElement, TabPanelProps>((props, forwardedRef) => {
  const { children, ...restProps } = props;
  const { panelId, controlId } = useTabContext();

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ...restProps,
    id: panelId,
    ref: forwardedRef,
    tabIndex: 0,
    role: 'tabpanel',
    'aria-labelledby': controlId,
  });
});

if (__dev__) {
  TabPanel.displayName = DISPLAY_NAME;
}

export default TabPanel;
