import styled from 'styled-components';

const StyledSelectElement = styled.select`
  cursor: pointer;
  border-radius: 6px;
  padding: 5px 9px;
  border: 0;
`;

const SELECT_ELEMENT_STYLES = {
  greyBoxShadow: '0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06)',
  greyBorder: '0.0625rem solid #e2e2e2;',
};

export { StyledSelectElement, SELECT_ELEMENT_STYLES };
