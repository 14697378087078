import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PATHS } from 'routes';
import { IconLogo } from 'assets';
import { AccessibleIcon } from 'components/shared';
import { UserAction } from './components/user-action';

const Container = styled.div`
  height: 100%;
  padding: 0.625rem;
  position: relative;

  @media (min-width: 48.3125rem) {
    display: none;
  }

  & .container__row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & .link-home {
      height: 100%;

      & svg {
        width: 6.25rem;
        height: inherit;
      }
    }

    & .toggle-bar {
      cursor: pointer;

      & > div {
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 0.1875rem;
        cursor: pointer;
        width: 1.875rem;
        height: 0.1875rem;
        margin: 0.25rem 0;
      }
    }
  }

  & .container__row-2 {
    display: none;

    &.container__row-2--is-dropped {
      background-color: ${(props) => props.theme.colors.white};
      border-right: 0.125rem solid #eee;
      display: block;
      position: fixed;
      z-index: 10;
      left: 0;
      top: 3.5625rem;
      max-height: 90vh;
      min-height: 90vh;
      width: 80%;
      max-width: 18.75rem;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      & .list-wrapper__nav-wrapper {
        & nav ul {
          & li {
            padding: 0.625rem;
            border-bottom: solid 0.0625rem #eee;
            & a {
              color: #333;

              &:hover {
                color: #000;
              }
            }
          }
        }
      }
      & .list-wrapper__user-action {
        padding: 0.625rem;

        & .user-action-comp {
          font-size: 0.9rem;
          & .user-action-comp__row-1 {
            border: solid 0.0625rem #eee;
            border-radius: 0.3125rem;
            padding: 0.3125rem;
          }
        }
      }
    }
  }
`;

type MobileHeaderProps = {
  children: React.ReactElement;
};

function MobileHeader(props: MobileHeaderProps) {
  const [isNavDropped, setIsNavDropped] = React.useState(false);
  return (
    <Container>
      <div className="container__row-1">
        <Link to={PATHS.home} className="link-home">
          <AccessibleIcon label="Click to go to home">
            <IconLogo fill="#fff" />
          </AccessibleIcon>
        </Link>
        <div
          role="button"
          tabIndex={0}
          className="toggle-bar"
          onClick={() => {
            setIsNavDropped((prevState) => !prevState);
          }}
        >
          <div className="toggle-bar__top" />
          <div className="toggle-bar__center" />
          <div className="toggle-bar__bottom" />
        </div>
      </div>
      <div className={`container__row-2 container__row-2--${isNavDropped ? 'is-dropped' : 'is-closed'}`}>
        <div className="list-wrapper__nav-wrapper">{React.cloneElement(React.Children.only(props.children))}</div>
        <div className="list-wrapper__user-action">
          <UserAction fill="#000" />
        </div>
      </div>
    </Container>
  );
}

export { MobileHeader };
