/* eslint-disable max-len */
import React from 'react';

import { __dev__ } from 'utils';

const DISPLAY_NAME = 'IconLock';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'svg'>;
type ElementIconLock = React.ElementRef<'svg'>;

interface IconLockProps extends PrimitiveSvgProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const IconLock = React.forwardRef<ElementIconLock, IconLockProps>(({ width, height, fill, ...restProps }, forwardedRef) => (
  <svg {...restProps} ref={forwardedRef} width={width} height={height} viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.80469 4.67578V3.5C6.80469 3.00781 6.6224 2.58854 6.25781 2.24219C5.91146 1.8776 5.49219 1.69531 5 1.69531C4.50781 1.69531 4.07943 1.8776 3.71484 2.24219C3.36849 2.58854 3.19531 3.00781 3.19531 3.5V4.67578H6.80469ZM4.17969 9.57031C4.41667 9.80729 4.6901 9.92578 5 9.92578C5.3099 9.92578 5.58333 9.80729 5.82031 9.57031C6.05729 9.33333 6.17578 9.0599 6.17578 8.75C6.17578 8.4401 6.05729 8.16667 5.82031 7.92969C5.58333 7.69271 5.3099 7.57422 5 7.57422C4.6901 7.57422 4.41667 7.69271 4.17969 7.92969C3.94271 8.16667 3.82422 8.4401 3.82422 8.75C3.82422 9.0599 3.94271 9.33333 4.17969 9.57031ZM8.5 4.67578C8.8099 4.67578 9.08333 4.79427 9.32031 5.03125C9.55729 5.25 9.67578 5.51432 9.67578 5.82422V11.6758C9.67578 11.9857 9.55729 12.2591 9.32031 12.4961C9.08333 12.7148 8.8099 12.8242 8.5 12.8242H1.5C1.1901 12.8242 0.916667 12.7148 0.679688 12.4961C0.442708 12.2591 0.324219 11.9857 0.324219 11.6758V5.82422C0.324219 5.51432 0.442708 5.25 0.679688 5.03125C0.916667 4.79427 1.1901 4.67578 1.5 4.67578H2.07422V3.5C2.07422 2.69792 2.35677 2.01432 2.92188 1.44922C3.50521 0.865885 4.19792 0.574219 5 0.574219C5.80208 0.574219 6.48568 0.865885 7.05078 1.44922C7.63411 2.01432 7.92578 2.69792 7.92578 3.5V4.67578H8.5Z"
      fill={fill}
    />
  </svg>
));

IconLock.defaultProps = {
  width: '10',
  height: '13',
  fill: '#007BFF',
};

if (__dev__) {
  IconLock.displayName = DISPLAY_NAME;
}

export { IconLock };
