/* eslint-disable max-len */
import React from 'react';

import { __dev__ } from 'utils';

const DISPLAY_NAME = 'IconAnnotationCard';

type PrimitiveSvgProps = React.ComponentPropsWithoutRef<'svg'>;
type ElementIconAnnotationCard = React.ElementRef<'svg'>;

interface IconAnnotationCardProps extends PrimitiveSvgProps {
  width?: string | number;
  height?: string | number;
}

const IconAnnotationCard = React.forwardRef<ElementIconAnnotationCard, IconAnnotationCardProps>(({ width, height, ...restProps }, forwardedRef) => (
  <svg {...restProps} ref={forwardedRef} width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2.13333C0 0.955124 0.955126 0 2.13333 0H77.8667C79.0449 0 80 0.955126 80 2.13333V77.8667C80 79.0449 79.0449 80 77.8667 80H2.13333C0.955124 80 0 79.0449 0 77.8667V2.13333Z"
      fill="#F3F3F3"
    />
    <g clipPath="url(#clip0_8069_24830)">
      <path
        d="M29.8591 39.349L34.9369 40.0434L33.4693 42.1844L29.8591 41.3743V39.349Z"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8591 45.367V46.9294C29.8591 47.3345 30.182 47.6527 30.5636 47.6817L33.3813 47.8553C33.8215 47.8842 34.2031 47.537 34.2031 47.103V46.4375"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1408 39.349L45.063 40.0434L46.5012 42.1844L50.1408 41.3743V39.349Z"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0629 40.0435L40.6603 40.3617C40.22 40.3907 39.7797 40.3907 39.3395 40.3617L34.9368 40.0435"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5012 42.1844L45.7968 42.9077C41.9517 43.4285 38.048 43.4285 34.203 42.9077L33.4692 42.1844"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1409 39.349L49.4071 37.9313L41.1594 38.3653C40.3963 38.3942 39.6038 38.3942 38.8407 38.3653L30.5636 37.9023L29.8298 39.3201"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.407 37.9023L47.0882 33.302C46.736 32.5786 46.0022 32.1447 45.1804 32.1447H34.7901C33.9976 32.1447 33.2638 32.6076 32.8822 33.302L30.5635 37.9023"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1408 41.3743L50.4637 41.7794C50.6691 42.0398 50.7865 42.3581 50.7865 42.6763V44.528C50.7865 44.9042 50.5517 45.2224 50.1702 45.3092L46.5013 46.3219L43.8303 46.6112C41.2767 46.9005 38.6938 46.9005 36.1403 46.6112L33.4693 46.3219L29.8298 45.3382C29.4775 45.2514 29.2134 44.9042 29.2134 44.557V42.7053C29.2134 42.387 29.3308 42.0687 29.5362 41.8083L29.8591 41.4033"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1409 45.367V46.9294C50.1409 47.3345 49.818 47.6527 49.4364 47.6817L46.6187 47.8553C46.1784 47.8842 45.7969 47.537 45.7969 47.103V46.4375"
        stroke="#D0D0D0"
        strokeWidth="0.533333"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8069_24830">
        <rect width="21.8667" height="16" fill="white" transform="translate(29.0667 32)" />
      </clipPath>
    </defs>
  </svg>
));

IconAnnotationCard.defaultProps = {
  width: '80',
  height: '80',
};

if (__dev__) {
  IconAnnotationCard.displayName = DISPLAY_NAME;
}

export { IconAnnotationCard };
