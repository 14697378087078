import styled from 'styled-components';

const LoadMoreNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const LoadMoreButton = styled.button`
  border: 0;
  background-color: #e1ecf9;
  color: #007bff;
  border-radius: 0.3125rem;
  height: 2.0625rem;
  padding: 0.375rem 2rem;
  transition: all 0.5s ease-out;
  width: 12rem;

  &:hover {
    border-color: #c7e0fc;
    box-shadow: 0 0 0 0.25rem #c5dffc;
    border-radius: 0.3125rem;
  }
`;

type ListViewLoadMoreProps = {
  results: number;
  showLoadMoreButton: boolean;
  loadMoreButtonDisabled: boolean;
  handleLoadMoreOnClick: () => void;
};

const ListViewLoadMore = ({ results, showLoadMoreButton, loadMoreButtonDisabled, handleLoadMoreOnClick }: ListViewLoadMoreProps) => (
  <LoadMoreNode>
    <span>{results} results found</span>
    {showLoadMoreButton && (
      <LoadMoreButton disabled={loadMoreButtonDisabled} onClick={handleLoadMoreOnClick}>
        Load more results
      </LoadMoreButton>
    )}
  </LoadMoreNode>
);

export { ListViewLoadMore };
