import React from 'react';
import styled from 'styled-components';

import { Container, Header, Main, Footer } from 'components/layouts';

const Wrapper = styled.div`
  background-color: #fff;
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  min-height: 88vh;
  padding: 2rem 1.5rem;
`;

const TitleWrapper = styled.div`
  margin-bottom: 1rem;

  & > h1 {
    font-style: medium;
    font-size: 1.25rem;
    font-weight: ${(props) => props.theme.font.fontWeights[4]};
    line-height: 100%;
    margin-bottom: 0;
    width: 50%;
    display: inline-block;
  }
`;

const TitleActions = styled.div`
  text-align: right;
  width: 50%;
  display: inline-block;
`;

type PageWrapperProps = {
  pageTitle: string | React.ReactNode;
  titleActions?: React.ReactNode,
  children: React.ReactNode;
  full?: boolean;
};

function PageWrapper(props: PageWrapperProps) {
  return (
    <>
      <Header full={props.full} />
      <Main>
        <Container full={props.full}>
          <Wrapper>
            <TitleWrapper>
              {
                typeof props.pageTitle === 'string' || props.pageTitle instanceof String
                  ? <h1>{props.pageTitle}</h1>
                  : <>{props.pageTitle}</>
              }
              <TitleActions>
                {props.titleActions}
              </TitleActions>
            </TitleWrapper>
            {props.children}
          </Wrapper>
        </Container>
      </Main>
      <Footer />
    </>
  );
}

export { PageWrapper };
