import React from 'react';
import { Form } from 'react-bootstrap';

type AssignedToCurrentUserFilterProps = {
  assignedToCurrentUser: boolean | undefined,
  setAssignedToCurrentUser: (value: boolean) => void
}

const AssignedToCurrentUserFilter = (props: AssignedToCurrentUserFilterProps) => {
  const { assignedToCurrentUser, setAssignedToCurrentUser } = props;
  return (
    <Form>
      <Form.Check
        type="checkbox"
        label="Only assigned to me"
        onChange={(event: any) => setAssignedToCurrentUser(event.target.checked)}
        checked={assignedToCurrentUser}
      />
    </Form>
  );
};

export { AssignedToCurrentUserFilter };
