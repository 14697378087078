import { useMemo } from 'react';
import { useTitle, useAuth } from 'hooks';
import styled from 'styled-components';

import Tab from 'components/shared/tab';

import { PageWrapper } from 'components/containers';
import { ButtonsGroup } from 'components/layouts';

import { useListView } from './hooks/use-list-view';
import { LabelReviewQueryType, PagedLabelReviews } from 'redux/reviews/reviews.type';
import { ListViewLoadMore, PhotoSeriesListViewSkeleton } from 'components/shared';
import { MessageUpdate } from 'components/shared/message-update/message-update.component';
import { PhotoSeries } from './components/photo-series.component';
import { Permissions } from 'redux/session/session.type';

import { LabelReviewCounters } from '../../../../../redux/reviews/reviews.type';
import { useRedux, useReduxSelector } from '../../../../../redux/store.hooks';

import Badge from 'react-bootstrap/Badge';

const PhotoSeriesContainer = styled.div`
  border: 0.0625rem solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
  padding: 1.8125rem 1.9375rem;
  margin: 0.625rem auto;
`;

const LabellingReviewListView = () => {
  useTitle('Reviews');

  const { isLoading, error, pagedLabelReviews, pagedQuery, setPagedQuery, queryType, setQueryType } = useListView();

  const currentPage = (pagedLabelReviews as PagedLabelReviews).page;
  const totalPage = (pagedLabelReviews as PagedLabelReviews).pageCount;
  const pageSize = (pagedLabelReviews as PagedLabelReviews).pageSize;
  const rowCount = (pagedLabelReviews as PagedLabelReviews).rowCount;

  const shouldLoadMore = totalPage > currentPage;
  const isDisabled = currentPage >= totalPage;

  const { isAuthorized: canViewAllLabelReviews } = useAuth([Permissions.ViewAllLabelReviews]);

  const {
    reviews: { selectors },
  } = useRedux();
  const labelReviewCounters: LabelReviewCounters | null = useReduxSelector(selectors.selectLabelReviewCounters);

  const renderPhotoSeries = useMemo(() => {
    if (!(pagedLabelReviews as PagedLabelReviews)?.results?.length) return null;

    const photoSeries = (pagedLabelReviews as PagedLabelReviews).results.map((labelReview) => <PhotoSeries
      photoSeriesId={labelReview.photoSeriesId}
      vin={labelReview.vehicle.vin}
      registrationNo={labelReview.vehicle.registrationNo}
      images={labelReview.images}
      suggestionsSubmittedAt={labelReview.suggestionsSubmittedAt}
      key={labelReview.photoSeriesId}
      suggestionsConfirmedAt={labelReview.suggestionsConfirmedAt}
    />);

    return photoSeries;
  }, [pagedLabelReviews]);

  const renderLoader = useMemo(() => {
    if (!isLoading) return;

    let nextLoadCount = 5;

    if (rowCount) {
      const remainingRows = rowCount - currentPage * pageSize;
      if (remainingRows > 0) {
        nextLoadCount = Math.min(remainingRows, pageSize);
      }
    }

    return <PhotoSeriesListViewSkeleton quantity={nextLoadCount} />;
  }, [isLoading, currentPage, rowCount, pageSize]);

  return (
    <PageWrapper pageTitle="Reviews">
      <Tab.Root>
        <ButtonsGroup>
          <Tab.TabControl
            shouldSelectOnMount
            disabled={queryType == LabelReviewQueryType.RequestedFromMe}
            onControl={() => {
              setPagedQuery({
                ...pagedQuery,
                page: 1,
              });
              setQueryType(LabelReviewQueryType.RequestedFromMe);
            }}
          >
            Requested from me
            {
              labelReviewCounters
                && labelReviewCounters.currentUserReviewsRequested > 0
                && (<Badge style={{ marginLeft: '0.188rem' }} bg="danger">{labelReviewCounters.currentUserReviewsRequested}</Badge>)
            }
          </Tab.TabControl>
          <Tab.TabControl
            disabled={queryType == LabelReviewQueryType.MyReviews}
            onControl={() => {
              setPagedQuery({
                ...pagedQuery,
                page: 1,
              });
              setQueryType(LabelReviewQueryType.MyReviews);
            }}
          >
            My reviews
            {
              labelReviewCounters
                && labelReviewCounters.currentUserReviewFeedbackRequested > 0
                && (<Badge style={{ marginLeft: '0.188rem' }} bg="danger">{labelReviewCounters.currentUserReviewFeedbackRequested}</Badge>)
            }
          </Tab.TabControl>
          {
            canViewAllLabelReviews && (
              <Tab.TabControl
                disabled={queryType == LabelReviewQueryType.All}
                onControl={() => {
                  setPagedQuery({
                    ...pagedQuery,
                    page: 1,
                  });
                  setQueryType(LabelReviewQueryType.All);
                }}
              >
                All
              </Tab.TabControl>
            )
          }
        </ButtonsGroup>
        <Tab.TabPanel>
          <PhotoSeriesContainer>
            {error && <MessageUpdate type="danger">{error}</MessageUpdate>}
            {renderPhotoSeries}
            {renderLoader}
          </PhotoSeriesContainer>
        </Tab.TabPanel>
      </Tab.Root>
      <ListViewLoadMore
        results={rowCount}
        loadMoreButtonDisabled={isDisabled}
        showLoadMoreButton={shouldLoadMore}
        handleLoadMoreOnClick={() => {
          setPagedQuery({
            ...pagedQuery,
            page: (pagedQuery.page ?? 1) + 1,
          });
        }}
      />
    </PageWrapper>
  );
};

export default LabellingReviewListView;
