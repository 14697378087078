import { useEffect, useRef } from 'react';

function useTitle(title: string) {
  const prevTitle = useRef<string | null>(null);

  useEffect(() => {
    prevTitle.current = document.title;

    document.title = `${title} | ${prevTitle.current}`;

    return () => {
      document.title = prevTitle.current!;
    };
  }, [title]);
}

export { useTitle };
