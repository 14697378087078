import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useParseLabellingModeFromParams } from './useParseLabellingModeFromParams';
import { useRedux, useReduxDispatch, useReduxSelector } from 'redux/store.hooks';

import type {
  Annotation,
  DamageInfo,
  LabelStudioAnnotation,
  Point,
} from 'redux/annotations/annotations.type';

import {
  BodyPart,
  DamageType,
  LabellingMode,
  ImageType,
  SubMaskType
} from 'redux/annotations/annotations.type';

import type {
  ImageFile,
} from 'redux/images/images.type';
import { DamageLabelShape } from '../types/damage-label-shape';

// Damage types
const ExteriorDamageTypes = [
  DamageType.Dent,
  DamageType.LampBroken,
  DamageType.PaintDamage,
  DamageType.Rust,
  DamageType.Scrape,
  DamageType.Scratch,
  DamageType.Chip,
  DamageType.Crack,
  DamageType.OtherDamage,
  DamageType.Misaligned,
  DamageType.MissingPart,
  DamageType.FalsePositive
];

const WindshieldDamageTypes = [
  DamageType.Crack,
  DamageType.Chip,
  DamageType.FalsePositive
];

// Body part types
const ExteriorBodyParts = [
  BodyPart.Windshield,
  BodyPart.TailgateGlass,
  BodyPart.Fender,
  BodyPart.BackDoor,
  BodyPart.FrontDoor,
  BodyPart.Mirror,
  BodyPart.DoorGlass,
  BodyPart.Hood,
  BodyPart.Trunk,
  BodyPart.LicensePlate,
  BodyPart.FrontBumper,
  BodyPart.RearBumper,
  BodyPart.Light,
  BodyPart.Grille,
  BodyPart.Wheel,
  BodyPart.Body,
  BodyPart.SideSkirt
];

const WindshieldBodyParts = [
  BodyPart.Windshield,
  BodyPart.Mirror,
  BodyPart.Hood,
  BodyPart.Body
];

function useAnnotations() {
  const dispatch = useReduxDispatch();
  const { photoSeriesId, imageId } = useParams();
  const labellingModeFromParams = useParseLabellingModeFromParams();

  const [labellingMode, setLabellingMode] = useState<LabellingMode>(labellingModeFromParams);
  const [damageLabelShape] = useState<DamageLabelShape>(DamageLabelShape.Rectangle);

  const {
    annotations: { actions: annotationsActions, selectors: annotationsSelectors },
    images: { selectors: imagesSelectors },
    reviews: { actions: reviewActions }
  } = useRedux();

  const imageAnnotations = useReduxSelector((state) =>
    annotationsSelectors.selectAnnotationsByImageId(state, photoSeriesId as string, imageId as string, labellingMode));

  const image = useReduxSelector((state) =>
    annotationsSelectors.selectImageByImageIdAndPhotoSeriesId(state, photoSeriesId as string, imageId as string));

  const imageFile = useReduxSelector((state) => imagesSelectors.selectImageFile(state, imageId as string));

  const [damageLabels, setDamageLabels] = useState<string>('');
  const [bodyPartLabels, setBodyPartLabels] = useState<string>('');
  const [subMaskLabels, setSubMaskLabels] = useState<string>('');

  const [damageAnnotations, setDamageAnnotations] = useState<LabelStudioAnnotation[] | null>(null);
  const [bodyPartAnnotations, setBodyPartAnnotations] = useState<LabelStudioAnnotation[] | null>(null);
  const [subMaskAnnotations, setSubMaskAnnotations] = useState<LabelStudioAnnotation[] | null>(null);

  const getDamageTypes = useCallback(() => {
    switch (image?.imageType) {
      case ImageType.Exterior: return ExteriorDamageTypes;
      case ImageType.Windshield: return WindshieldDamageTypes;
      case ImageType.Additional: return WindshieldDamageTypes;
      default: return [];
    }
  }, [image]);

  const getBodyPartTypes = useCallback(() => {
    switch (image?.imageType) {
      case ImageType.Exterior: return ExteriorBodyParts;
      case ImageType.Windshield: return WindshieldBodyParts;
      case ImageType.Additional: return WindshieldBodyParts;
      default: return [];
    }
  }, [image]);

  const getDamageLabels = useCallback(() => {
    let damageLabelString = getDamageTypes()
      .map((damageType) => (`<Label value="${DamageType[damageType]}" background="red" />`))
      .join('');

    return damageLabelString;
  }, [getDamageTypes]);

  const getBodyPartLabels = useCallback(() => {
    let bodyPartLabelsString = getBodyPartTypes()
      .map((bodyPart) => (`<Label value="${BodyPart[bodyPart]}" background="green" />`))
      .join('');

    return bodyPartLabelsString;
  }, [getBodyPartTypes]);

  const getSubMaskLabels = useCallback(() => {
    let subMaskLabelsString = [SubMaskType.NotRepairableArea, SubMaskType.RepairableArea]
      .map((subMask) => (`<Label value="${SubMaskType[subMask]}" background="yellow" />`))
      .join('');

    return subMaskLabelsString;
  }, []);

  useEffect(() => {
    if (image !== undefined) {
      setDamageLabels(getDamageLabels());
      setBodyPartLabels(getBodyPartLabels());
      setSubMaskLabels(getSubMaskLabels());
    }
  }, [image, getDamageLabels, getBodyPartLabels, getSubMaskLabels]);

  /* -------------------------------------------------------------- *\
    Select and transform annotations data
  \* -------------------------------------------------------------- */
  useEffect(() => {
    if (!imageFile || !imageAnnotations || !image) {
      setDamageAnnotations(null);
      setBodyPartAnnotations(null);
      return;
    }

    const getPercentage = (part: number, whole: number) => (part / whole) * 100;

    const getAnnotationBounds = (points: number[][]) => {
      const xCoords = points.map((point) => point[0]);
      const yCoords = points.map((point) => point[1]);

      const xMax = Math.max(...xCoords);
      const xMin = Math.min(...xCoords);
      const yMax = Math.max(...yCoords);
      const yMin = Math.min(...yCoords);

      return { xMax, xMin, yMax, yMin, width: xMax - xMin, height: yMax - yMin };
    };

    const getDamageBounds = (damage: DamageInfo, annotation: Annotation, imageFile: ImageFile) => {
      let points: Array<Point>;

      if (damage.location) points = damage.location.mask;
      else points = annotation.mask;

      const pointsPercent = points
        .map(((point) => [getPercentage(point.x, imageFile.width), getPercentage(point.y, imageFile.height)]));

      const { xMin, xMax, yMin, yMax } = getAnnotationBounds(pointsPercent);

      return { xMin, xMax, yMin, yMax, width: xMax - xMin, height: yMax - yMin };
    };

    const bodyPartsTypes = getBodyPartTypes();
    const damageTypes = getDamageTypes();

    const getDamageAnnotations = (annotations: Annotation[], imageFile: ImageFile): LabelStudioAnnotation[] => annotations
      ?.filter((annotation) => bodyPartsTypes.some((bodyPartType) => bodyPartType === annotation.bodyPart))
      ?.map((annotation) => (
        annotation.damages
          .filter((damage) => damageTypes.some((damageType) => damageType === damage.damageType))
          .map((damage) => {
            if (damageLabelShape === DamageLabelShape.Polygon) {
              const pointsPercent = damage.location.mask
                .map(((point) => [getPercentage(point.x, imageFile.width), getPercentage(point.y, imageFile.height)]));
              const { height, width } = getAnnotationBounds(pointsPercent);

              return {
                from_name: 'damages',
                to_name: 'img',
                id: `${annotation.id}.${damage.id}`,
                parentID: annotation.id,
                source: '$image',
                type: 'polygonlabels',
                parentDamageId: damage.parentId,
                repairDecision: damage.repairDecision,
                value: {
                  polygonlabels: [DamageType[damage.damageType as number]],
                  rotation: 0,
                  height,
                  width,
                  points: pointsPercent,
                }
              } as LabelStudioAnnotation;
            }

            if (damageLabelShape === DamageLabelShape.Rectangle) {
              const { xMin, yMin, width, height } = getDamageBounds(damage, annotation, imageFile);

              return {
                from_name: 'damages',
                to_name: 'img',
                id: `${annotation.id}.${damage.id}`,
                parentID: annotation.id,
                source: '$image',
                type: 'rectanglelabels',
                parentDamageId: damage.parentId,
                repairDecision: damage.repairDecision,
                value: {
                  rectanglelabels: [DamageType[damage.damageType as number]],
                  rotation: 0,
                  width,
                  height,
                  x: xMin,
                  y: yMin,
                }
              } as LabelStudioAnnotation;
            }

            throw Error('Did not found suitable option to create LabelStudio damage annotations');
          })
      ))
      .flat();

    const getBodyPartAnnotations = (annotations: Annotation[], imageFile: ImageFile): LabelStudioAnnotation[] => annotations
      ?.filter((annotation) => bodyPartsTypes.some((bodyPart) => bodyPart === annotation.bodyPart))
      ?.map((annotation) => {
        const pointsPercent = annotation.mask
          .map(((point) => [getPercentage(point.x, imageFile.width), getPercentage(point.y, imageFile.height)]));

        const { height, width } = getAnnotationBounds(pointsPercent);

        const bodyPartAnnotation: LabelStudioAnnotation = {
          id: annotation.id,
          source: '$image',
          from_name: 'bodyParts',
          to_name: 'img',
          type: 'brushlabels',
          repairDecision: null,
          value: {
            height,
            width,
            brushlabels: [BodyPart[annotation.bodyPart as number]],
            rotation: 0,
            points: pointsPercent,
          },
        };

        return bodyPartAnnotation;
      });

    const getSubMaskAnnotations = (annotations: Annotation[], imageFile: ImageFile) : LabelStudioAnnotation[] => {
      const subMaskAnnotations: LabelStudioAnnotation[] = [];
      for (const annotation of annotations) {
        if (annotation.subMasks && annotation.subMasks.length > 0) {
          for (let x = 0; x < annotation.subMasks.length; x++) {
            const subMask = annotation.subMasks[x];
            const pointsPercent = subMask.mask
              .map(((point) => [getPercentage(point.x, imageFile.width), getPercentage(point.y, imageFile.height)]));
            const { height, width } = getAnnotationBounds(pointsPercent);

            subMaskAnnotations.push({
              from_name: 'subMasks',
              to_name: 'img',
              id: `${annotation.id}.subMask.${x}`,
              parentID: annotation.id,
              source: '$image',
              type: 'polygonlabels',
              repairDecision: null,
              value: {
                polygonlabels: [SubMaskType[subMask.subMaskType as number]],
                rotation: 0,
                height,
                width,
                points: pointsPercent,
              }
            } as LabelStudioAnnotation);
          }
        }
      }
      return subMaskAnnotations;
    };

    setDamageAnnotations(getDamageAnnotations(imageAnnotations, imageFile));
    setBodyPartAnnotations(getBodyPartAnnotations(imageAnnotations, imageFile));
    setSubMaskAnnotations(getSubMaskAnnotations(imageAnnotations, imageFile));
  }, [image, imageFile, imageAnnotations, labellingMode, damageLabelShape, getBodyPartTypes, getDamageTypes]);

  /* -------------------------------------------------------------- *\
    Send updated annotations to API
  \* -------------------------------------------------------------- */

  const getPixel = (percentage: number, whole: number) => Math.round((whole / 100) * percentage);

  const handleSubmitAnnotation = useCallback(
    (areas:any, imageFile: ImageFile, confirm: boolean | undefined, onConfirm: () => void, onSave: () => void, labelStudioRef: any) => {
      const annotations = Object.values(areas.toPOJO());
      const bodyPartAnnotations = annotations
        .filter((area: any) => !area.parentID)
        .map((bodyPartArea: any) => {
          const isCopy = bodyPartArea.id && bodyPartArea.id?.includes('copy');
          const drivexAnnotationId = !isCopy && bodyPartArea.id?.includes('#')
            ? bodyPartArea.id.split('#')[0]
            : null;

          const points = bodyPartArea.type === 'brushregion'
            ? bodyPartArea.touches[0].relativePoints
            : bodyPartArea.points;

          return {
            id: drivexAnnotationId as string,
            bodyPart: BodyPart[bodyPartArea.labelName as keyof typeof BodyPart],
            // Map percentages to exact coordinates
            mask: points
              .map((point: any) => ({
                x: getPixel(point.relativeX, imageFile.width),
                y: getPixel(point.relativeY, imageFile.height),
              })) as Point[],
            damages: annotations
              .filter((damageArea: any) => isCopy
                ? damageArea.parentID === bodyPartArea.id.split('#')[0]
                : damageArea.parentID === (drivexAnnotationId || bodyPartArea.id))
              .map((damageAnnotation: any) => {
                let mask = [];

                // Map rectangle mask
                if (damageAnnotation.type === 'rectangleregion') {
                  const minX = getPixel(damageAnnotation.relativeX, imageFile.width);
                  const minY = getPixel(damageAnnotation.relativeY, imageFile.height);

                  const maxX = minX + getPixel(damageAnnotation.relativeWidth, imageFile.width);
                  const maxY = minY + getPixel(damageAnnotation.relativeHeight, imageFile.height);

                  mask = [
                    { x: minX, y: minY },
                    { x: minX, y: maxY },
                    { x: maxX, y: maxY },
                    { x: maxX, y: minY }
                  ];
                // Map polygon mask
                } else {
                  mask = damageAnnotation.points.map((point: any) => ({
                    x: getPixel(point.relativeX, imageFile.width),
                    y: getPixel(point.relativeY, imageFile.height),
                  }));
                }

                const damageId = damageAnnotation.id.includes('#')
                  ? damageAnnotation.id.split('#')[0].split('.')[1]
                  : '';

                return ({
                  id: damageId,
                  parentId: damageAnnotation.parentDamageId,
                  damageType: DamageType[damageAnnotation.labelName as keyof typeof DamageType],
                  location: { mask },
                  repairDecision: damageAnnotation.repairDecision != null
                    ? parseInt(damageAnnotation.repairDecision, 2)
                    : null
                });
              }),
          };
        });

      const saveAnnotationsActionParams = {
        photoSeriesId: photoSeriesId as string,
        imageId: imageId as string,
        annotations: bodyPartAnnotations,
        labellingMode
      };
      const saveAnnotationsAction = labellingMode === LabellingMode.LabelReview
        ? reviewActions.saveLabelReviewAnnotations(saveAnnotationsActionParams)
        : annotationsActions.saveAnnotations(saveAnnotationsActionParams);

      dispatch(saveAnnotationsAction).then((data) => {
        if (confirm === true && data.meta.requestStatus === 'fulfilled') {
          const confirmAnnotationsAction = labellingMode === LabellingMode.LabelReview
            ? reviewActions.submitLabelReview({ photoSeriesId: photoSeriesId as string })
            : annotationsActions.confirmAnnotations({ photoSeriesId: photoSeriesId as string });

          dispatch(confirmAnnotationsAction).then((data: any) => {
            if (!data.meta.error) onConfirm();
          });
        }

        if (labelStudioRef) labelStudioRef.current.dirty = false;

        onSave();
      });
    },
    [annotationsActions, dispatch, imageId, photoSeriesId, labellingMode, reviewActions],
  );

  return {
    imageFile,
    damageLabels,
    bodyPartLabels,
    damageAnnotations,
    bodyPartAnnotations,
    handleSubmitAnnotation,
    labellingMode,
    setLabellingMode,
    damageLabelShape,
    subMaskLabels,
    subMaskAnnotations
  };
}

export { useAnnotations };
