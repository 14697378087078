import axios from 'axios';

import { shouldRenewToken, storeUserData, retrieveUserData } from './authorization';
import { onAjaxEnhancer } from 'redux/store.util';

type AxiosRequestHeaders = Record<string, string>;
type DataType<ValueType> = Record<string, ValueType>;

const defaultHeaders: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders,
});

// eslint-disable-next-line no-unused-vars
function composeHeaders(headersRequester?: (headers: AxiosRequestHeaders) => any) {
  let newHeaders: AxiosRequestHeaders = {};

  delete newHeaders.Authorization;

  if (headersRequester) headersRequester({ ...defaultHeaders, ...newHeaders });

  return (theirHeaders?: AxiosRequestHeaders) => {
    const augmentedHeaders = {
      ...defaultHeaders,
      ...newHeaders,
      ...theirHeaders,
    };

    return augmentedHeaders;
  };
}

let renewRequest: Promise<any>;
let renewRequestIsPending: boolean = false;

function refreshToken() {
  const composedHeaders = composeHeaders();
  const currentUser = retrieveUserData();

  if (currentUser && shouldRenewToken(currentUser.sessionExpiresAt)) {
    // renew request should be dispatched only the first time token expiration is detected
    // following requests will wait for the renewed token
    if (!renewRequestIsPending) {
      renewRequest = client({
        url: 'session/renew',
        method: 'POST',
        withCredentials: true,
        headers: composedHeaders(),
        data: {},
      });

      renewRequest.then((res) => {
        storeUserData(res.data);
        renewRequestIsPending = false;
      });

      renewRequest.catch((err) => {
        renewRequestIsPending = false;
        return Promise.reject(err);
      });

      renewRequestIsPending = true;
    }

    return renewRequest;
  }

  return Promise.resolve(null);
}

async function getRequest(urlParam: string) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'GET',
    headers: composedHeaders(),
    withCredentials: true,
  });

  return response;
}

async function getFileRequest(urlParam: string) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'GET',
    headers: composedHeaders(),
    withCredentials: true,
    responseType: 'blob',
  });

  return response;
}

async function postRequest(urlParam: string, data: any) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'POST',
    headers: composedHeaders(),
    data,
    withCredentials: true,
  });

  return response;
}

async function putRequest<T>(urlParam: string, data: DataType<T>) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'PUT',
    headers: composedHeaders(),
    data,
    withCredentials: true,
  });

  return response;
}

async function patchRequest<T>(urlParam: string, data: DataType<T>) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'PATCH',
    headers: composedHeaders(),
    data,
    withCredentials: true,
  });

  return response;
}

async function deleteRequest(urlParam: string) {
  const composedHeaders = composeHeaders();

  await refreshToken();

  const response = await client({
    url: urlParam,
    method: 'DELETE',
    headers: composedHeaders(),
    withCredentials: true,
  });

  return response;
}

type VoidFunction = () => void;

async function promisify<Success, Error>(
  promise: Promise<any>,
  payload?: {
    onSuccess?: VoidFunction;
    onError?: VoidFunction;
    onFinish?: VoidFunction;
    [key: string]: any;
  }
): Promise<[Success, Error]> {
  try {
    const success = await promise;
    if (payload) {
      onAjaxEnhancer(payload, ['onSuccess', 'onFinish']);
    }
    return [success, null as unknown as Error];
  } catch (error) {
    if (payload) {
      onAjaxEnhancer(payload, ['onError', 'onFinish']);
    }

    window.dispatchEvent(new ErrorEvent('error', {
      error
    }));

    return [null as unknown as Success, error as Error];
  }
}

export { getRequest, getFileRequest, postRequest, putRequest, patchRequest, deleteRequest, promisify };
