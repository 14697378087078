import { useEffect, useState } from 'react';
import { PagedQuery } from '../../../../../../redux/store.type';
import { LabelReviewQueryType, PagedLabelReviews } from '../../../../../../redux/reviews/reviews.type';
import { useRedux, useReduxDispatch, useReduxSelector } from '../../../../../../redux/store.hooks';
import { useSearchParams } from 'react-router-dom';

const useListView = () => {
  const [searchParams] = useSearchParams();
  const photoSeriesIdQueryParam: string | null = searchParams.get('photoSeriesId');
  const queryTypeQueryParam: string | null = searchParams.get('queryType');

  const defaultQueryType = queryTypeQueryParam === null
    ? LabelReviewQueryType.RequestedFromMe
    : parseInt(queryTypeQueryParam, 2);

  const [queryType, setQueryType] = useState<LabelReviewQueryType>(defaultQueryType);
  const [pagedLabelReviews, setPagedLabelReviews] = useState<PagedLabelReviews | {}>({});
  const [pagedQuery, setPagedQuery] = useState<PagedQuery>({
    page: 1,
    pageSize: 5,
  });

  const dispatch = useReduxDispatch();
  const {
    reviews: { actions, selectors },
  } = useRedux();
  const isLoading = useReduxSelector(selectors.selectReviewsIsLoading);
  const error = useReduxSelector(selectors.selectLabelReviewsError);
  const selectPagedLabelReviews: PagedLabelReviews = useReduxSelector(selectors.selectPagedLabelReviews);

  useEffect(() => setPagedLabelReviews(selectPagedLabelReviews), [selectPagedLabelReviews]);
  useEffect(() => setPagedLabelReviews({}), [queryType]);

  useEffect(() => {
    const queryParams = queryType === LabelReviewQueryType.All
      ? { pagedQuery }
      : { pagedQuery, queryType, photoSeriesId: photoSeriesIdQueryParam };

    dispatch(actions.getPagedLabelReviews(queryParams));
  }, [queryType, pagedQuery, dispatch, actions, photoSeriesIdQueryParam]);

  return {
    queryType,
    setQueryType,
    pagedQuery,
    setPagedQuery,
    isLoading,
    error,
    pagedLabelReviews,
  };
};

export { useListView };
