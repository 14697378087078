/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PagedQuery, SortDirection, SortColumn, QueryType, PagedPhotoseries } from 'redux/annotations/annotations.type';
import { useRedux, useReduxDispatch, useReduxSelector } from 'redux/store.hooks';

function useListView() {
  const [searchParams] = useSearchParams();
  const photoSeriesIdsQueryParam = searchParams.get('photoSeriesIds');

  const [queryType, setQueryType] = useState<QueryType>(QueryType.ReviewNeeded);
  const [pagedQuery, setPagedQuery] = useState<PagedQuery>({
    page: 1,
    pageSize: 5,
    sortDirection: SortDirection.Asc,
    sortColumn: SortColumn.CompletedAt
  });
  const [assignedToCurrentUser, setAssignedToCurrentUser] = useState<boolean>(false);
  const [pagedPhotoSeries, setPagedPhotoSeries] = useState<PagedPhotoseries | {}>({});
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [notConfirmedForRetraining, setNotConfirmedForRetraining] = useState<boolean>(false);

  const dispatch = useReduxDispatch();
  const {
    annotations: { actions, selectors },
  } = useRedux();
  const isLoading = useReduxSelector(selectors.selectAnnotationsIsLoading);
  const error = useReduxSelector(selectors.selectAnnotationsError);
  const selectPagedPhotoSeries = useReduxSelector(selectors.selectPagedPhotoSeries);

  useEffect(() => setPagedPhotoSeries(selectPagedPhotoSeries), [selectPagedPhotoSeries]);
  useEffect(() => setPagedPhotoSeries({}), [queryType, assignedToCurrentUser]);

  useEffect(() => {
    if (queryType === QueryType.ReviewNeeded || queryType === QueryType.Reviewed) {
      setAssignedToCurrentUser(false);
    }
    if (queryType !== QueryType.Reviewed) {
      setNotConfirmedForRetraining(false);
    }
  }, [queryType]);

  useEffect(() => {
    const photoSeriesIds: string[] = photoSeriesIdsQueryParam !== null
      ? photoSeriesIdsQueryParam.split(',')
      : [];

    dispatch(actions.getPagedPhotoSeries({
      pagedQuery, queryType, photoSeriesIds, assignedToCurrentUser, searchPhrase, notConfirmedForRetraining
    }));
  }, [queryType, dispatch, pagedQuery, photoSeriesIdsQueryParam, assignedToCurrentUser, searchPhrase, notConfirmedForRetraining]);

  return {
    isLoading,
    error,
    pagedPhotoSeries,
    queryType,
    pagedQuery,
    setPagedQuery,
    setQueryType,
    assignedToCurrentUser,
    setAssignedToCurrentUser,
    searchPhrase,
    setSearchPhrase,
    notConfirmedForRetraining,
    setNotConfirmedForRetraining
  };
}

export { useListView };
