import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRequest, patchRequest, postRequest, promisify } from 'services';
import { getErrorMsg } from 'redux/store.util';
import { toast } from 'react-toastify';

import {
  PagedPhotoseries,
  AnnotationsError,
  GetPhotoSeriesArg,
  SaveAnnotationsArg,
  ConfirmAnnotationsArg,
  GetPhotoSeriesAnnotationsArg,
  AssignPhotoSeriesArg,
  AssignedPhotoSeriesAnalysis,
  LabellingMode
} from './annotations.type';

const TYPE_PREFIX = '@drivex/annotations';
const INITIAL_PAGE = 1;
const INITIAL_PAGE_SIZE = 5;

const getPagedPhotoSeries = createAsyncThunk<PagedPhotoseries, GetPhotoSeriesArg, { rejectValue: AnnotationsError }>(
  `${TYPE_PREFIX}/getPagedPhotoSeries`,
  async (payload, thunkApi) => {
    const queryParams = [];

    if (payload.notConfirmedForRetraining == true) queryParams.push('photoSeriesConfirmedForReTraining=false');
    if (payload.searchPhrase && payload.searchPhrase.length > 0) queryParams.push(`searchPhrase=${payload.searchPhrase}`);
    if (payload.queryType !== undefined) queryParams.push(`queryType=${payload.queryType}`);
    if (payload.photoSeriesIds && payload.photoSeriesIds.length > 0) {
      const photoSeriesIdsParamName = 'photoSeriesIds=';
      queryParams.push(photoSeriesIdsParamName + payload.photoSeriesIds.join(`&${photoSeriesIdsParamName}`));
    }
    if (payload.imageId && payload.imageId.length > 0) queryParams.push(`imageId=${payload.imageId}`);
    if (payload.assignedToCurrentUser) queryParams.push('assignedToCurrentUser=true');

    queryParams.push(`page=${payload.pagedQuery.page ?? INITIAL_PAGE}`);
    queryParams.push(`pageSize=${payload.pagedQuery.pageSize ?? INITIAL_PAGE_SIZE}`);

    if (payload.pagedQuery.sortColumn) queryParams.push(`sortColumn=${payload.pagedQuery.sortColumn}`);
    if (payload.pagedQuery.sortDirection !== undefined) queryParams.push(`sortDirection=${payload.pagedQuery.sortDirection}`);

    const [success, error] = await promisify<any, any>(
      getRequest(`/annotations?${queryParams.join('&')}`)
    );

    if (success) {
      return success.data!;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const getPhotoSeriesAnnotations = createAsyncThunk<PagedPhotoseries, GetPhotoSeriesAnnotationsArg, { rejectValue: AnnotationsError }>(
  `${TYPE_PREFIX}/getPhotoSeriesAnnotations`,
  async (payload, thunkApi) => {
    const queryParams = [];

    const { photoSeriesId, labellingMode } = payload;

    queryParams.push(`photoSeriesIds=${photoSeriesId}`);
    queryParams.push(`confirmedForReTraining=${labellingMode === LabellingMode.ModelTraining}`);
    queryParams.push('detailed=true');
    queryParams.push(`page=${INITIAL_PAGE}&pageSize=1`);

    if (payload.imageId && payload.imageId.length > 0) queryParams.push(`imageId=${payload.imageId}`);

    const [success, error] = await promisify<any, any>(
      getRequest(`/annotations?${queryParams.join('&')}`)
    );

    if (success) {
      return success.data!;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const confirmAnnotations = createAsyncThunk<any, ConfirmAnnotationsArg, { rejectValue: AnnotationsError }>(
  `${TYPE_PREFIX}/confirmAnnotations`,
  async (payload, thunkApi) => {
    const { photoSeriesId } = payload;

    const [success, error] = await promisify<any, any>(
      toast.promise(patchRequest(`/annotations/${photoSeriesId}/confirm`, {}), {
        pending: 'Confirming analysis',
        success: 'Analysis confirmed',
        error: 'Error confirming analysis'
      })
    );

    if (success) {
      return {
        onSuccess: payload.onSuccess
      };
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response)
    });
  }
);

const saveAnnotations = createAsyncThunk<any, SaveAnnotationsArg, { rejectValue: AnnotationsError }>(
  `${TYPE_PREFIX}/saveAnnotations`,
  async (payload, thunkApi) => {
    const { photoSeriesId, imageId, annotations, labellingMode } = payload;

    const requestUrl = labellingMode === LabellingMode.ModelTraining
      ? `/annotations/${payload.imageId}/train`
      : `/annotations/${payload.imageId}`;

    const [success, error] = await promisify<any, any>(
      toast.promise(postRequest(requestUrl, annotations), {
        pending: 'Saving annotations',
        success: 'Annotations saved',
        error: 'Error saving annotations'
      })
    );

    if (success) {
      return {
        photoSeriesId,
        imageId,
        annotations: success.data,
        onSuccess: payload.onSuccess,
        labellingMode
      };
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

const assignPhotoSeries = createAsyncThunk<AssignedPhotoSeriesAnalysis, AssignPhotoSeriesArg, { rejectValue: AnnotationsError }>(
  `${TYPE_PREFIX}/assignPhotoSeries`,
  async (payload, thunkApi) => {
    const { photoSeriesId } = payload;

    const [success, error] = await promisify<any, any>(
      toast.promise(patchRequest(`/photoSeries/${photoSeriesId}/assign`, {}), {
        pending: 'Assigning photo series',
        success: 'Photo series assigned',
        error: 'Error assigning photo series'
      })
    );

    if (success) {
      return success.data;
    }

    return thunkApi.rejectWithValue({
      message: getErrorMsg(error.response),
    });
  }
);

export { getPagedPhotoSeries, saveAnnotations, confirmAnnotations, getPhotoSeriesAnnotations, assignPhotoSeries };
