import { createAsyncThunk } from '@reduxjs/toolkit';

import { getFileRequest } from 'services';
import { getErrorMsg, onAjaxEnhancer } from 'redux/store.util';

import type {
  DownloadImageFileArg,
  DownloadImageFile,
  ImagesError,
  ImageFile,
} from './images.type';

const TYPE_PREFIX = '@drivex/images';

const downloadImageFile = createAsyncThunk<DownloadImageFile, DownloadImageFileArg, { rejectValue: ImagesError }>(
  `${TYPE_PREFIX}/downloadImageFile`,
  async (payload, thunkApi) => {
    const { imageId } = payload;

    try {
      const response = await getFileRequest(`images/${imageId}/download`);

      onAjaxEnhancer(payload, ['onSuccess', 'onFinish']);

      const loadImage = new Promise<ImageFile>((resolve) => {
        const localImageUrl = window.URL.createObjectURL(response.data);
        let img = document.createElement('img');
        img.src = localImageUrl;
        img.onload = () => {
          resolve({ localUrl: localImageUrl, width: img.width, height: img.height });
        };
      });

      const imageFile = await loadImage;

      return {
        imageId,
        fileLocalUrl: imageFile.localUrl,
        width: imageFile.width,
        height: imageFile.height,
      };
    } catch (error: any) {
      onAjaxEnhancer(payload, ['onError', 'onFinish']);
      return thunkApi.rejectWithValue({
        message: getErrorMsg(error.response),
      });
    }
  }
);

export { downloadImageFile };
