import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState, RootDispatch } from './store';
import * as sessionActions from './session/session.actions';
import * as sessionSelectors from './session/session.selectors';

import * as annotationsActions from './annotations/annotations.actions';
import * as annotationsSelectors from './annotations/annotations.selectors';

import * as imagesActions from './images/images.actions';
import * as imagesSelectors from './images/images.selectors';

import * as reviewsActions from './reviews/reviews.actions';
import * as reviewsSelectors from './reviews/reviews.selectors';

const api = Object.freeze({
  session: {
    actions: { ...sessionActions },
    selectors: { ...sessionSelectors },
  },
  annotations: {
    actions: { ...annotationsActions },
    selectors: { ...annotationsSelectors },
  },
  images: {
    actions: { ...imagesActions },
    selectors: { ...imagesSelectors },
  },
  reviews: {
    actions: { ...reviewsActions },
    selectors: { ...reviewsSelectors }
  }
});

const useRedux = () => api;

function useReduxDispatch() {
  return useDispatch<RootDispatch>();
}

const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useReduxDispatch, useReduxSelector, useRedux };
