/* eslint-disable max-len */
import { useState } from 'react';
import { Image, ImageSubType, ImageType, LabellingMode, DamageType, ANNOTATIONS_PROPERTY_NAME } from 'redux/annotations/annotations.type';
import { useNavigate } from 'react-router-dom';
import { ImageFile } from 'redux/images/images.type';
import { isLabelStudioDirty } from 'utils';
import UnsavedChanges from './unsaved-changes';
import { useReduxSelector, useRedux } from 'redux/store.hooks';
import styled from 'styled-components';
import { IconArrowDown } from 'assets';
import { ReactComponent as PlusIcn } from 'assets/svgs/icn-plus.svg';

type ImageSelectionProps = {
  imageIndex: number,
  images: Image[] | undefined,
  photoSeriesId: string | undefined,
  labelStudioRef: any,
  imageFile: ImageFile,
  labellingMode: LabellingMode,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void, onSave: () => void,
    labelStudioRef: any
  ) => void
}

const Selection = styled.div`
  width: 14.375rem;
`;

const SelectionHeader = styled.div`
  padding: .375rem .75rem .375rem .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectionItems = styled.div`
  position: absolute;
  z-index: 1000;
  width: inherit;
  border: 1px solid #ced4da;
  border-radius: .25rem;

  div:not(:last-child) {
    border-bottom: 1px solid #ced4da;
  }
`;

const SelectionItem = styled.div<{ selected: boolean}>`
  padding: .188rem 2.25rem .188rem .75rem;
  cursor: pointer;
  background-color: ${(props) => props.selected ? '#007BFF' : 'white'};
  color: ${(props) => props.selected ? 'white' : 'black'};
`;

const SubSelectionItem = styled.div<{ showBorderBottom: boolean, selected: boolean }>`
  display: flex;
  align-items: center;
  padding: .188rem 2.25rem .188rem .75rem;
  cursor: pointer;
  background-color: ${(props) => props.selected ? '#007BFF' : 'white'};
  color: ${(props) => props.selected ? 'white' : 'black'};
  ${(props) => !props.showBorderBottom ? 'border-bottom: 0px !important;' : ''}
`;

const Plus = styled(PlusIcn)<{ selected: boolean }>`
  width: .75rem;
  margin-right: .5rem;
  fill: ${(props) => props.selected ? 'white' : '#007BFF'}
`;

const ImageSelection = (props: ImageSelectionProps) => {
  const { imageIndex, images, photoSeriesId, labelStudioRef, imageFile, handleSubmitAnnotation, labellingMode } = props;

  const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState<boolean>(false);
  const [imageId, setImageId] = useState<string | undefined>(undefined);
  const [showItems, setShowItems] = useState<boolean>();

  const navigate = useNavigate();
  const {
    annotations: { selectors: annotationsSelectors },
  } = useRedux();

  const annotations = useReduxSelector((state) => annotationsSelectors.selectAnnotationsByPhotoSeriesId(state, photoSeriesId as string));
  const annotationsParamName = ANNOTATIONS_PROPERTY_NAME[labellingMode];

  const relatedDamageIds: string[] = [];

  if (images) {
    images.forEach((image) => {
      if (image.relatedDamageId !== null && !relatedDamageIds.includes(image.relatedDamageId)) {
        relatedDamageIds.push(image.relatedDamageId);
      }
    });
  }

  if (images === undefined) return null;

  const label = (image: Image) => {
    const parts = [];

    if (image.relatedDamageId === null) {
      parts.push(ImageType[image.imageType]);
      if (image.imageSubType !== null) parts.push(ImageSubType[image.imageSubType]);
    } else if (image.relatedImageId !== null && image.relatedDamageId !== null) {
      const imageAnnotations = annotations[annotationsParamName][image.relatedImageId];
      const damages = imageAnnotations.map((annotation) => annotation.damages).reduce((x, y) => x.concat(y), []);
      const relatedDamage = damages.find((damage) => damage.id === image.relatedDamageId);
      if (relatedDamage) {
        const relatedImages = images.filter((i: Image) => i.relatedDamageId === image.relatedDamageId).map((i: Image) => i.id);
        parts.push(
          `${DamageType[relatedDamage.damageType]} ${relatedDamageIds.indexOf(image.relatedDamageId) + 1} (${relatedImages.indexOf(image.id) + 1})`
        );
      } else {
        parts.push('Damage');
      }
    } else {
      parts.push('-');
    }

    return parts.join(', ');
  };

  const navigateToImage = (imageId: string) => {
    navigate(`/labelling/photoSeries/${photoSeriesId}/image/${imageId}/mode/${labellingMode}`);
    setShowUnsavedChangesPopup(false);
    setImageId(undefined);
  };

  const handleContinue = () => {
    if (imageId !== undefined) navigateToImage(imageId);
  };

  const handleSelectedItemClick = (image: Image) => {
    if (isLabelStudioDirty(labelStudioRef)) {
      setShowUnsavedChangesPopup(true);
      setImageId(image.id);
    } else {
      navigateToImage(image.id);
    }
  };

  const subImages = (mainImage: Image) => images
    .filter((image: Image) => image.relatedImageId === mainImage.id);

  const parentImages = () => images.filter((image: Image) => image.relatedImageId === null);

  const onParentImageClick = (image: Image) => {
    if (subImages(image).length === 0) {
      handleSelectedItemClick(image);
    }
  };

  const isSelected = (image: Image) => image.id === images[imageIndex].id;

  return (
    <div>
      <Selection onMouseLeave={() => setShowItems(false)}>
        <SelectionHeader onClick={() => setShowItems(!showItems)}>
          {label(images[imageIndex])}
          <IconArrowDown />
        </SelectionHeader>
        {
          showItems && (
            <SelectionItems>
              {
                parentImages().map((image) => (
                  <>
                    <SelectionItem
                      key={`parent-${image.id}`}
                      onClick={() => onParentImageClick(image)}
                      selected={isSelected(image)}
                    >
                      {label(image)}
                    </SelectionItem>
                    {
                      subImages(image).map((subImage: Image) => (
                        <SubSelectionItem
                          showBorderBottom={false}
                          key={`sub-${subImage.id}`}
                          onClick={() => handleSelectedItemClick(subImage)}
                          selected={isSelected(subImage)}
                        >
                          <Plus selected={isSelected(subImage)} />
                          {label(subImage)}
                        </SubSelectionItem>
                      ))
                    }
                    {
                      subImages(image).length > 0 && (
                        <SubSelectionItem
                          showBorderBottom
                          key={`main-${image.id}`}
                          onClick={() => handleSelectedItemClick(image)}
                          selected={isSelected(image)}
                        >
                          <Plus selected={isSelected(image)} />
                          Main image
                        </SubSelectionItem>
                      )
                    }
                  </>
                ))
              }
            </SelectionItems>
          )
        }
      </Selection>
      <UnsavedChanges
        labelStudioRef={labelStudioRef}
        imageFile={imageFile}
        handleSubmitAnnotation={handleSubmitAnnotation}
        handleSaveAndContinue={handleContinue}
        handleContinue={handleContinue}
        setShowUnsavedChangesPopup={setShowUnsavedChangesPopup}
        showUnsavedChangesPopup={showUnsavedChangesPopup}
      />
    </div>
  );
};

export default ImageSelection;
