import { createSelector } from 'reselect';

import type { RootState } from '../store';
import {
  LabelReviewsState
} from './reviews.type';

const selectReviewsState = (state: RootState) => state.reviews;

const selectReviewsIsLoading = createSelector(selectReviewsState, (reviews) => reviews.isLoading);
const selectLabelReviewsError = createSelector(selectReviewsState, (reviews) => reviews.error);
const selectPagedLabelReviews = createSelector(selectReviewsState, (reviews) => reviews.pagedLabelReviews);

const getPhotoSeriesIdParam = (_: RootState, photoSeriesId: string) => photoSeriesId;

const selectLabelReviewByPhotoSeriesId = createSelector(
  selectReviewsState,
  getPhotoSeriesIdParam,
  (lrs: LabelReviewsState, photoSeriesId: string) => lrs.pagedLabelReviews.results?.find((lr) => lr.photoSeriesId === photoSeriesId)
);

const selectLabelReviewCounters = createSelector(selectReviewsState, (reviews) => reviews.counters);

export {
  selectReviewsIsLoading,
  selectLabelReviewsError,
  selectPagedLabelReviews,
  selectLabelReviewByPhotoSeriesId,
  selectLabelReviewCounters
};
