import { checkIsPageAccessAuthorized, retrieveUserData } from 'services';

const defaultReturnValue = {
  isAuthorized: false,
  isAuthenticated: false,
  userPermissions: [],
  token: null,
  email: null,
};

function useAuth(pagePermissions?: number[]) {
  const session = retrieveUserData();

  if (!session) return defaultReturnValue;

  const userPermissions = session.permissions;
  const email = session.email;
  const id = session.id;

  const isAuthorized = checkIsPageAccessAuthorized(pagePermissions ?? [], userPermissions);

  return { isAuthorized, userPermissions, email, isAuthenticated: !!id };
}

export { useAuth };
