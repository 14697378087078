import { useEffect } from 'react';
import { useRedux, useReduxDispatch, useReduxSelector } from '../../../../../../redux/store.hooks';
import { useNavigate } from 'react-router-dom';

const useLabelReview = (photoSeriesId: string) => {
  const dispatch = useReduxDispatch();
  const navigate = useNavigate();

  const {
    reviews: { actions: reviewsActions, selectors: reviewsSelector }
  } = useRedux();

  const labelReview = useReduxSelector((state) => reviewsSelector.selectLabelReviewByPhotoSeriesId(state, photoSeriesId));

  useEffect(() => {
    dispatch(reviewsActions.getPagedLabelReviews({ photoSeriesId }));
  }, [photoSeriesId, dispatch, reviewsActions]);

  const handleAcceptSuggestion = (suggestionId: string, accepted: boolean): void => {
    dispatch(reviewsActions.updateLabelReview({
      photoSeriesId,
      suggestionId,
      suggestionAccepted: accepted
    }));
  };

  const handleConfirmSuggestions = () => {
    dispatch(reviewsActions.confirmLabelReview({
      photoSeriesId
    })).then(() => navigate('/reviews'));
  };

  return {
    labelReview,
    handleAcceptSuggestion,
    handleConfirmSuggestions
  };
};

export { useLabelReview };
