import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { __dev__ } from 'utils';
import { Flex } from 'components/layouts';
import { BlueButton as Button } from 'components/shared';

import { useImageNavigation } from '../hooks/useImageNavigation';
import { ImageFile } from 'redux/images/images.type';
import { LabellingMode } from 'redux/annotations/annotations.type';
import { useCanEditCurrentImage } from '../hooks/useCanEditCurrentImage';

const DISPLAY_NAME = 'ImageDetailFooter';

const Container = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`;

const nextAndPreviousCss = css`
  min-width: 200px;
  max-width: 200px;
`;
const Next = styled(Button)`
  ${nextAndPreviousCss}
`;

const Previous = styled(Button)`
  ${nextAndPreviousCss}
`;

const Finish = styled(Button)`
  background: #23a942;
  ${nextAndPreviousCss}
`;

const Save = styled(Button)`
  background: #23a942;
  ${nextAndPreviousCss}
`;

type PrimitiveDivProps = React.ComponentPropsWithoutRef<'div'>;
type ImageDetailFooterElement = React.ElementRef<'div'>;

interface ImageDetailFooterProps extends PrimitiveDivProps {
  photoSeriesId: string | undefined,
  labelStudioRef: React.MutableRefObject<any> | null,
  imageFile: ImageFile,
  handleSubmitAnnotation: any,
  labellingMode: LabellingMode,
}

const useSaveImage = (
  labelStudioRef: any,
  imageFile: ImageFile,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void,
    onSave: () => void,
    labelStudioRef: any
  ) => void
) => {
  const { navigateToHome } = useImageNavigation();

  return useCallback((confirm: boolean = false, onSave: () => void = () => {}) => {
    if (labelStudioRef == null) return;

    const areas = labelStudioRef.current.store.annotationStore.selected.areas;
    handleSubmitAnnotation(areas, imageFile, confirm, navigateToHome, onSave, labelStudioRef);
  }, [handleSubmitAnnotation, imageFile, labelStudioRef, navigateToHome]);
};

const ImageDetailFooter = React.forwardRef<ImageDetailFooterElement, ImageDetailFooterProps>((
  { labelStudioRef, imageFile, handleSubmitAnnotation, photoSeriesId, labellingMode, ...props },
  forwardedRef
) => {
  const { hasImages, isFirstImage, isLastImage, navigateNext, navigatePrev } = useImageNavigation();
  const saveImage = useSaveImage(labelStudioRef, imageFile, handleSubmitAnnotation);
  const canEditPhotoSeries = useCanEditCurrentImage(photoSeriesId, labellingMode);

  if (photoSeriesId === undefined) return null;

  const canSavePhotoSeries = canEditPhotoSeries();
  const canFinish = isLastImage() &&
    (labellingMode === LabellingMode.DamageDetection || labellingMode === LabellingMode.LabelReview || labellingMode === LabellingMode.ModelTraining);

  return (
    <Container {...props} ref={forwardedRef}>
      {
        hasImages && (
          <Previous
            style={{ visibility: isFirstImage() ? 'hidden' : 'visible' }}
            onClick={() => {
              if (canSavePhotoSeries) saveImage(false, () => navigatePrev());
              else navigatePrev();
            }}
            data-testid="previousBtn"
          >
            Previous
          </Previous>
        )
      }
      { (canSavePhotoSeries) && <Save onClick={() => saveImage()} data-testid="saveBtn">Save</Save> }
      {
        hasImages && (
          canFinish ? (
            <>
              { canSavePhotoSeries && (
                <Finish
                  onClick={() => saveImage(true)}
                  data-testid="finishBtn"
                >
                  Finish
                </Finish>
              )}
            </>
          ) : !isLastImage() ? (
            <Next
              onClick={() => {
                if (canSavePhotoSeries) saveImage(false, () => navigateNext());
                else navigateNext();
              }}
              data-testid="nextBtn"
            >
              Next
            </Next>
          ) : null
        )
      }
    </Container>
  );
});

if (__dev__) {
  ImageDetailFooter.displayName = DISPLAY_NAME;
}

export { ImageDetailFooter };
