import { PageWrapper } from 'components/containers';
import { useTitle } from 'hooks';
import { Statistics } from './compnents/statistics/statistics';

const Dashboard = () => {
  useTitle('Dashboard');
  return (
    <PageWrapper pageTitle="Dashboard">
      <Statistics />
    </PageWrapper>
  );
};

export default Dashboard;
