import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getRequest } from 'services';
import { useTitle } from 'hooks';
import { SortDirection, SortColumn } from 'redux/annotations/annotations.type';
import { ReactComponent as Bell } from 'assets/svgs/icn-bell.svg';
import { Badge } from 'components/shared';

const NeedReview = styled.div`
  display: flex;
  align-items: center;
`;

const NeedReviewTitle = styled.h1`
  font-style: medium;
  font-size: 1.25rem;
  font-weight: ${(props) => props.theme.font.fontWeights[4]};
  line-height: 100%;
  margin-bottom: 0rem;
  margin-right: 0.5rem;
`;

const BadgeWrapper = styled.div`
  cursor: pointer;
`;

type NeedReviewPollingProps = {
  title: string,
  setPagedQuery: (query: any) => void
};

const POLLING_INTERVAL_IN_SECONDS = 45;

const NeedReviewPolling = (props: NeedReviewPollingProps) => {
  const { setPagedQuery } = props;

  const [title, setTitle] = useState('Labelling');
  const [needReview, setNeedsReview] = useState<number>(0);
  const [notification, setNotification] = useState<{
    notification: Notification | null,
    needReview: number
  } | null>(null);
  const [pollDate, setPollDate] = useState<Date>(new Date());

  useTitle(title);

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPollDate(new Date());
      getRequest(`/photoSeries/needsReview?updatesFrom=${pollDate.toISOString()}`)
        .then((response) => {
          if (response.data > 0) {
            const total = needReview + response.data;

            setNeedsReview(total);
            setTitle(`Labelling (${total} new photo series need review)`);
          }
        });
    }, POLLING_INTERVAL_IN_SECONDS * 1000);
    return () => clearInterval(interval);
  }, [needReview, pollDate]);

  const handleNeedReviewRefresh = useCallback(() => {
    setPagedQuery({
      page: 1,
      pageSize: 5,
      sortDirection: SortDirection.Asc,
      sortColumn: SortColumn.CompletedAt
    });
    setNeedsReview(0);
    setTitle('Labelling');
    notification?.notification?.close();
    setNotification(null);
  }, [setPagedQuery, notification]);

  useEffect(() => {
    const createNotification = () : Notification | null => {
      if (Notification?.permission !== 'granted') return null;
      const browserNotification = new Notification('New photo series need review!', {
        requireInteraction: true,
        body: 'Click to refresh need review tab'
      });

      browserNotification.onclick = (event: any) => {
        event.preventDefault();
        window.focus();
        handleNeedReviewRefresh();
        browserNotification.close();
      };

      browserNotification.onclose = () => {
        setNotification({
          notification: null,
          needReview
        });
      };

      return browserNotification;
    };

    if (needReview === 0) return;
    if (!notification?.notification && notification?.needReview !== needReview) {
      setNotification({
        notification: createNotification(),
        needReview
      });
    }
  }, [needReview, notification, setNotification, handleNeedReviewRefresh]);

  return (
    <NeedReview>
      <NeedReviewTitle>{props.title}</NeedReviewTitle>
      {
        needReview > 0 && (
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={
              <Tooltip>
                New photo series need review! Click on badge to refresh page
              </Tooltip>
            }
          >
            <BadgeWrapper onClick={handleNeedReviewRefresh}>
              <Badge variant="green" txt={needReview} icon={<Bell />} pulse />
            </BadgeWrapper>
          </OverlayTrigger>
        )
    }
    </NeedReview>
  );
};

export { NeedReviewPolling };
