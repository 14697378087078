import { useState } from 'react';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { ImageFile } from 'redux/images/images.type';
import { isLabelStudioDirty } from 'utils';
import _ from 'lodash';
import { LabellingMode } from 'redux/annotations/annotations.type';
import { useCanEditCurrentImage } from '../hooks/useCanEditCurrentImage';
import UnsavedChanges from './unsaved-changes';

export enum LabellingOption {
  All = 1,
  Damage,
  BodyPart
}

interface LabellingOptionSelectionProps {
  labellingOption: LabellingOption,
  setLabellingOption: (value: LabellingOption) => void,
  labelStudioRef: any,
  imageFile: ImageFile,
  handleSubmitAnnotation: (
    areas:any,
    imageFile: ImageFile,
    confirm: boolean | undefined,
    onConfirm: () => void, onSave: () => void,
    labelStudioRef: any
  ) => void,
  photoSeriesId: string | undefined
  labellingMode: LabellingMode,
}

const LabellingOptionSelection = (props: LabellingOptionSelectionProps) => {
  const {
    labellingOption, setLabellingOption, imageFile, handleSubmitAnnotation, labelStudioRef,
    photoSeriesId, labellingMode
  } = props;

  const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState<boolean>(false);
  const [option, setOption] = useState<LabellingOption>();

  const canEditPhotoSeries = useCanEditCurrentImage(photoSeriesId, labellingMode);
  const labellingOptions = [
    { name: 'Show all labels', value: LabellingOption.All },
    { name: 'Damage labelling', value: LabellingOption.Damage },
    { name: 'Body part labelling', value: LabellingOption.BodyPart },
  ];

  const toggleButtonVariant = (labellingOption: LabellingOption) => {
    switch (labellingOption) {
      case LabellingOption.All: return 'outline-primary';
      case LabellingOption.Damage: return 'outline-danger';
      case LabellingOption.BodyPart: return 'outline-success';
      default: return 'outline-primary';
    }
  };

  if (!canEditPhotoSeries()) return null;

  const handleContinue = () => {
    if (option === undefined) return;

    setLabellingOption(option);
    setShowUnsavedChangesPopup(false);
  };

  return (
    <>
      <ButtonGroup>
        {labellingOptions.map((option) => (
          <ToggleButton
            key={option.value}
            type="radio"
            name="radio"
            value={option.value}
            variant={labellingOption === option.value ? toggleButtonVariant(option.value) : 'outline-secondary'}
            checked={labellingOption === option.value}
            onClick={(e: any) => {
              const value = labellingOptions.find((option) => option.name === e.target.textContent)?.value;
              if (!value) return;

              if (isLabelStudioDirty(labelStudioRef)) {
                setOption(value);
                setShowUnsavedChangesPopup(true);
              } else {
                setLabellingOption(value);
              }
            }}
            data-testid={`${_.camelCase(option.name)}Btn`}
          >
            {option.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <UnsavedChanges
        labelStudioRef={labelStudioRef}
        imageFile={imageFile}
        handleSubmitAnnotation={handleSubmitAnnotation}
        handleSaveAndContinue={handleContinue}
        handleContinue={handleContinue}
        setShowUnsavedChangesPopup={setShowUnsavedChangesPopup}
        showUnsavedChangesPopup={showUnsavedChangesPopup}
      />
    </>
  );
};

export default LabellingOptionSelection;
