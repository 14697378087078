import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPagedPhotoSeries, saveAnnotations, getPhotoSeriesAnnotations, assignPhotoSeries } from './annotations.actions';
import { getPagedLabelReviews, getLabelReviewAnnotations, saveLabelReviewAnnotations } from '../reviews/reviews.actions';

import type {
  AnnotationsState, NormalizedAnnotations, Annotation, UpdateDamageRepairDecisionArg, NormalizedImages, NormalizedPhotoSeries, LabellingMode
} from './annotations.type';
import {
  RepairDecision,
  ANNOTATIONS_PROPERTY_NAME, MODEL_TRAINING_ANNOTATIONS, DAMAGE_DETECTION_ANNOTATIONS
} from './annotations.type';

const initialState: AnnotationsState = {
  isLoading: false,
  error: null,
  pagedPhotoSeries: Object.create(null),
  annotations: Object.create(null),
  images: {
    byPhotoSeriesId: {}
  },
  photoSeries: {
    byPhotoSeriesId: {}
  }
};

const annotationsSlice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    updateDamageRepairDecision: (state: AnnotationsState, action: PayloadAction<UpdateDamageRepairDecisionArg>) => {
      const { damageId, parentDamageId, repairDecision, photoSeriesId } = action.payload;

      if (damageId === null && parentDamageId === null) return;

      const annotations = state.annotations.byPhotoSeriesId[photoSeriesId][DAMAGE_DETECTION_ANNOTATIONS];
      const damages: {
        id: string;
        parentId: string | null;
        imageId: string;
        annotationId: string;
        repairDecision: RepairDecision | null;
        update: boolean;
      }[] = [];

      for (const imageId of Object.keys(annotations)) {
        for (const annotation of annotations[imageId]) {
          for (const damage of annotation.damages) {
            const update = damage.id === damageId && damage.repairDecision != repairDecision;
            damages.push({
              id: damage.id,
              parentId: damage.parentId,
              imageId,
              annotationId: annotation.id,
              repairDecision: update ? repairDecision : damage.repairDecision,
              update,
            });
          }
        }
      }

      const parent = damages.find((d) => d.id === parentDamageId);
      if (parent) {
        const children = damages.filter((child) => child.parentId === parentDamageId);
        const parentRepairDecision = children.some((child) => child.repairDecision === RepairDecision.Replace)
          ? RepairDecision.Replace
          : RepairDecision.Repair;

        if (parent.repairDecision != parentRepairDecision) {
          parent.repairDecision = parentRepairDecision;
          parent.update = true;
        }
      }

      const updatedDamages = damages.filter((d) => d.update);
      for (const updatedDamage of updatedDamages) {
        const annotations = state.annotations.byPhotoSeriesId[photoSeriesId][DAMAGE_DETECTION_ANNOTATIONS][updatedDamage.imageId];
        const annotation = annotations.find((a) => a.id === updatedDamage.annotationId);
        const damage = annotation?.damages.find((d) => d.id === updatedDamage.id);

        if (damage) {
          damage.repairDecision = updatedDamage.repairDecision;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPagedPhotoSeries.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPagedPhotoSeries.fulfilled, (state, { payload }) => {
      const { results, page, pageCount, pageSize, rowCount, sortColumn, sortDirection } = payload;
      const copiedPagedPhotoSeries = state.pagedPhotoSeries;
      const shouldAppend = sortColumn !== null && copiedPagedPhotoSeries.sortColumn === sortColumn && page != 1;

      const pagedPhotoSeriesResults =
        copiedPagedPhotoSeries.results?.length > 0 && shouldAppend ? [...copiedPagedPhotoSeries.results, ...results] : results;

      state.isLoading = false;

      // TODO Remove images collection from paged photo series and use separate images state
      state.pagedPhotoSeries = {
        results: pagedPhotoSeriesResults,
        page,
        pageCount,
        pageSize,
        rowCount,
        sortColumn,
        sortDirection,
      };

      // Extract images & photoSeries
      const previousImages = state.images as NormalizedImages;
      const imagesState = {
        byPhotoSeriesId: {
          ...previousImages.byPhotoSeriesId
        }
      };

      const previousPhotoSeries = state.photoSeries as NormalizedPhotoSeries;
      const photoSeriesState = {
        byPhotoSeriesId: {
          ...previousPhotoSeries.byPhotoSeriesId
        }
      };

      if (pagedPhotoSeriesResults != null) {
        pagedPhotoSeriesResults.forEach((pagedPhotoSeries) => {
          imagesState.byPhotoSeriesId[pagedPhotoSeries.photoSeriesId] = pagedPhotoSeries.images.map((image) => ({
            ...image,
            annotations: []
          }));

          photoSeriesState.byPhotoSeriesId[pagedPhotoSeries.photoSeriesId] = {
            analysisAssignedToUser: pagedPhotoSeries.analysisAssignedToUser,
            analysisAssignedAt: pagedPhotoSeries.analysisAssignedAt,
            windshieldRepairReplaceDecisionEnabled: pagedPhotoSeries.windshieldRepairReplaceDecisionEnabled
          };
        });
      }

      state.images = imagesState;
      state.photoSeries = photoSeriesState;
    });
    builder.addCase(getPagedPhotoSeries.rejected, (state, { payload }) => {
      state.isLoading = false;
      if (payload) state.error = payload.message;
    });
    builder.addCase(
      getPhotoSeriesAnnotations.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { labellingMode, photoSeriesId, imageId },
          },
        }
      ) => {
        const { results } = payload;

        const annotationsPropName = ANNOTATIONS_PROPERTY_NAME[labellingMode as LabellingMode];
        const prevAnnotations = state.annotations as NormalizedAnnotations;
        let annotationImageState: { [id: string]: Annotation[] } = {};

        // NOTE:  Keep image(s) annotations
        if (prevAnnotations.byPhotoSeriesId) {
          annotationImageState = { ...prevAnnotations.byPhotoSeriesId[photoSeriesId]?.[annotationsPropName] };
        }

        results[0].images.forEach((image) => {
          let annotations = image.annotations;

          // NOTE:  Create copy of annotations from Damage detection for Training mode if
          //        that mode does not have user created annotations
          if (annotationsPropName === MODEL_TRAINING_ANNOTATIONS && image.annotations.length === 0) {
            const ddAnnotations = prevAnnotations.byPhotoSeriesId[photoSeriesId]?.[DAMAGE_DETECTION_ANNOTATIONS]?.[image.id];
            if (annotations && ddAnnotations) {
              annotations = ddAnnotations.map((annotation) => ({
                ...annotation,
                id: `${annotation.id}-copy`,
                damages: annotation.damages.map((damage) => ({
                  ...damage,
                  id: `${damage.id}-copy`,
                  parentId: null,
                })),
              }));
            }
          }

          annotationImageState[image.id] = annotations;
        });

        // NOTE: Update pagedPhotoSeries image analysedAt date and time. Currently updating if imageId is provided
        if (imageId) {
          state.pagedPhotoSeries = {
            ...state.pagedPhotoSeries,
            results: state.pagedPhotoSeries.results.map((photoSeries) => {
              if (photoSeries.photoSeriesId !== photoSeriesId) return photoSeries;
              return {
                ...photoSeries,
                images: photoSeries.images.map((image) => {
                  if (image.id !== imageId) return image;
                  const resultImage = results[0].images.find((resultImage) => resultImage.id === image.id);
                  if (!resultImage) return image;
                  return {
                    ...image,
                    analysedAt: resultImage.analysedAt,
                  };
                }),
              };
            }),
          };
        }

        state.annotations = {
          byPhotoSeriesId: {
            ...prevAnnotations.byPhotoSeriesId,
            [photoSeriesId]: {
              ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId],
              [annotationsPropName]: annotationImageState,
            },
          },
        };
      }
    );
    builder.addCase(saveAnnotations.fulfilled, (state, { payload }) => {
      const { photoSeriesId, imageId, annotations, labellingMode } = payload;

      const annotationsPropName = ANNOTATIONS_PROPERTY_NAME[labellingMode as LabellingMode];
      const prevAnnotations = state.annotations as NormalizedAnnotations;

      state.annotations = {
        byPhotoSeriesId: {
          ...prevAnnotations.byPhotoSeriesId,
          [photoSeriesId]: {
            ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId],
            [annotationsPropName]: {
              ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId][annotationsPropName],
              [imageId]: annotations,
            },
          },
        },
      };
    });
    builder.addCase(assignPhotoSeries.fulfilled, (state, { payload }) => {
      const { photoSeriesId, user, assignedAt } = payload;

      const photoSeries = state.pagedPhotoSeries.results.find((ps) => ps.photoSeriesId === photoSeriesId);
      if (photoSeries !== undefined) {
        photoSeries.analysisAssignedToUser = user;
        photoSeries.analysisAssignedAt = assignedAt;

        state.photoSeries.byPhotoSeriesId[photoSeriesId].analysisAssignedAt = assignedAt;
        state.photoSeries.byPhotoSeriesId[photoSeriesId].analysisAssignedToUser = user;
      }

      state.pagedPhotoSeries = {
        ...state.pagedPhotoSeries,
      };
    });
    builder.addCase(getPagedLabelReviews.fulfilled, (state, { payload }) => {
      const pagedLabelReviews = payload.results;

      // Extract images and photo series
      const previousImages = state.images as NormalizedImages;
      const imagesState = {
        byPhotoSeriesId: {
          ...previousImages.byPhotoSeriesId
        }
      };

      const previousPhotoSeries = state.photoSeries as NormalizedPhotoSeries;
      const photoSeriesState = {
        byPhotoSeriesId: {
          ...previousPhotoSeries.byPhotoSeriesId
        }
      };

      if (pagedLabelReviews != null) {
        pagedLabelReviews.forEach((pagedLabelReview) => {
          imagesState.byPhotoSeriesId[pagedLabelReview.photoSeriesId] = pagedLabelReview.images.map((image) => ({
            ...image,
            annotations: []
          }));

          photoSeriesState.byPhotoSeriesId[pagedLabelReview.photoSeriesId] = {
            analysisAssignedToUser: pagedLabelReview.suggestionsSubmittedAt == null
              ? pagedLabelReview.assignedToUser
              : pagedLabelReview.originalAnalysisUser,
            analysisAssignedAt: pagedLabelReview.suggestionsSubmittedAt == null
              ? pagedLabelReview.createdAt
              : pagedLabelReview.suggestionsSubmittedAt,
            windshieldRepairReplaceDecisionEnabled: pagedLabelReview.windshieldRepairReplaceDecisionEnabled
          };
        });
      }

      state.images = imagesState;
    });
    builder.addCase(getLabelReviewAnnotations.fulfilled, (state, { payload }) => {
      const pagedLabelReview = payload.results[0];
      const photoSeriesId = pagedLabelReview.photoSeriesId;

      // TODO FIX error "Unhandled Rejection (ReferenceError): LabellingMode is not defined"
      const annotationsPropName = ANNOTATIONS_PROPERTY_NAME['2'];
      const prevAnnotations = state.annotations as NormalizedAnnotations;
      let annotationImageState: { [id: string]: Annotation[] } = {};

      // NOTE:  Keep image(s) annotations
      if (prevAnnotations.byPhotoSeriesId) {
        annotationImageState = { ...prevAnnotations.byPhotoSeriesId[photoSeriesId]?.[annotationsPropName] };
      }

      pagedLabelReview.images.forEach((image) => {
        let annotations = image.annotations;
        annotationImageState[image.id] = annotations;
      });

      state.annotations = {
        byPhotoSeriesId: {
          ...prevAnnotations.byPhotoSeriesId,
          [photoSeriesId]: {
            ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId],
            [annotationsPropName]: annotationImageState,
          },
        },
      };
    });
    builder.addCase(saveLabelReviewAnnotations.fulfilled, (state, { payload }) => {
      const { photoSeriesId, imageId, annotations } = payload;

      // TODO Fix Unhandled Rejection (ReferenceError): LabellingMode is not defined
      const annotationsPropName = ANNOTATIONS_PROPERTY_NAME['2'];
      const prevAnnotations = state.annotations as NormalizedAnnotations;

      state.annotations = {
        byPhotoSeriesId: {
          ...prevAnnotations.byPhotoSeriesId,
          [photoSeriesId]: {
            ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId],
            [annotationsPropName]: {
              ...prevAnnotations.byPhotoSeriesId?.[photoSeriesId][annotationsPropName],
              [imageId]: annotations,
            },
          },
        },
      };
    });
  },
});

export const { updateDamageRepairDecision } = annotationsSlice.actions;

export default annotationsSlice.reducer;
