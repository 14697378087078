import { BlueButton } from 'components/shared/button/button.component';
import styled from 'styled-components';
import { useRedux, useReduxDispatch } from 'redux/store.hooks';
import { useNavigate } from 'react-router-dom';
import { LabellingMode } from 'redux/annotations/annotations.type';

const AssignToMeButton = styled(BlueButton)`
  min-height: 2.0rem;
  padding: 0rem 1rem;
`;

type AssignPhotoSeriesButtonProps = {
  photoSeriesId: string,
  imageId: string | undefined
}

const AssignPhotoSeriesButton = (props: AssignPhotoSeriesButtonProps) => {
  const { photoSeriesId, imageId } = props;
  const dispatch = useReduxDispatch();
  const {
    annotations: { actions: annotationsActions },
  } = useRedux();
  const navigate = useNavigate();

  const handleAssignToMe = () => {
    dispatch(
      annotationsActions.assignPhotoSeries({
        photoSeriesId: photoSeriesId as string
      })
    ).then(() => {
      if (imageId !== undefined) {
        navigate(`/labelling/photoSeries/${photoSeriesId}/image/${imageId}/mode/${LabellingMode.DamageDetection}`);
      }
    });
  };
  return (
    <AssignToMeButton onClick={handleAssignToMe} level="light" data-testid="assignToMeBtn">Assign to me</AssignToMeButton>
  );
};

export { AssignPhotoSeriesButton };
