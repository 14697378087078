import styled from 'styled-components';
import { THUMBNAIL_DEAFULT_SIZE } from 'components/shared/image-thumbnail/image-thumbnail';
import { ReactComponent as RobotIcn } from 'assets/svgs/icn-robot.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { transition } from 'components/shared/skeleton-ui/skeleton-ui.component';

const Wrapper = styled.div`
  animation: ${transition} 1.5s infinite;
  background-color: #f3f3f3;
  width: ${THUMBNAIL_DEAFULT_SIZE};
  height: ${THUMBNAIL_DEAFULT_SIZE};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Robot = styled(RobotIcn)<{ width: string }>`
  width: ${(props) => props.width};
  fill: #918e8e;
`;

const AnnotationInAnalysis = () => (
  <Wrapper>
    <InAnalysisRobot width="2rem" />
  </Wrapper>
);

interface InAnalysisRobotProps {
  width: string
}

const InAnalysisRobot = (props: InAnalysisRobotProps) => {
  const { width } = props;
  return (
    <OverlayTrigger
      key="right"
      placement="right"
      overlay={
        <Tooltip>
          Please wait. Analyzing image ...
        </Tooltip>
      }
    >
      <Robot width={width} />
    </OverlayTrigger>
  );
};

export { AnnotationInAnalysis, InAnalysisRobot };
